import {
  BreadcrumbDynamic,
  Card,
  Detail,
  Main,
  PageHeader,
  StateHandler,
} from '@app/components'
import { ItemBreadCrumb } from '@app/components/breadcrumb-dynamic/types'
import { useClinicDetail } from '@app/hooks/clinics/useClinicDetail'
import { Clinic, DaysOfWeek } from '@app/services/clinics/types'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsChevronLeft } from 'react-icons/bs'
import { CiEdit } from 'react-icons/ci'
import { Link, useParams } from 'react-router-dom'

export const ClinicDetailPage = () => {
  const { clinicId } = useParams()

  const {
    state: { loaded, error, clinic },
  } = useClinicDetail(clinicId!)

  return (
    <Main ariaLabelledby="clinic-detail-page" className="ClinicDetailPage">
      <StateHandler loaded={loaded} error={error}>
        <ClinicContent clinic={clinic!} />
      </StateHandler>
    </Main>
  )
}

const ClinicContent: FC<{ clinic: Clinic }> = ({ clinic }) => {
  const { t } = useTranslation()

  const daysOfWeekLabels = useMemo(() => {
    return clinic.openingHours.days
      ? Object.entries(clinic.openingHours.days!)
          .filter(([, value]) => value)
          .map(([, value]) => {
            const day = value as DaysOfWeek
            if (!DaysOfWeek[day]) {
              return null
            }
            return t(`days.${DaysOfWeek[day]}`)
          })
          .filter(v => v)
      : []
  }, [clinic.openingHours.days, t])

  const breadcrumbItems: ItemBreadCrumb[] = [
    { label: t('menu.clinics'), href: '/clinics' },
    { label: clinic.name },
  ]

  return (
    <>
      <PageHeader id="clinic-detail-page">
        <BreadcrumbDynamic items={breadcrumbItems} activeItem={clinic.name} />
        {clinic.name}
      </PageHeader>
      <Card>
        <Card.Body size={'sm'}>
          <Detail>
            <Detail.Side>
              <Detail.Image src={clinic.logo} />
            </Detail.Side>
            <Detail.Side>
              <Detail.Section>
                <>
                  <Detail.Cell label={t('clinics.name')} value={clinic.name} />
                  <Detail.Cell
                    label={t('clinics.address')}
                    value={clinic.address}
                  />

                  <Detail.Cell
                    label={t('clinics.phone')}
                    value={clinic.phone}
                  />
                  <Detail.Cell
                    label={t('clinics.email')}
                    value={clinic.email}
                  />

                  <Detail.Cell
                    label={t('clinics.address-number')}
                    value={clinic.addressNumber}
                  />
                  <Detail.Cell
                    label={t('clinics.address-floor')}
                    value={clinic.addressExtra}
                  />

                  <Detail.Cell
                    label={t('clinics.postal-code')}
                    value={clinic.postalCode}
                  />
                  <Detail.Cell
                    label={t('clinics.country')}
                    value={clinic.city.province.country.name}
                  />

                  <Detail.Cell
                    label={t('clinics.province')}
                    value={clinic.city.province.name}
                  />
                  <Detail.Cell
                    label={t('clinics.city')}
                    value={clinic.city.name}
                  />
                </>
              </Detail.Section>

              <Detail.Section>
                <Detail.SubTitle>{t('clinics.opening-hours')}</Detail.SubTitle>

                <Detail.Cell
                  className="col-md-12"
                  label={t('clinics.days')}
                  value={daysOfWeekLabels?.join(', ')}
                />

                <Detail.Cell
                  label={t('clinics.morning-opening')}
                  value={clinic.openingHours.startTimeMorning}
                />
                <Detail.Cell
                  label={t('clinics.morning-close')}
                  value={clinic.openingHours.endTimeMorning}
                />
                <Detail.Cell
                  label={t('clinics.afternoon-opening')}
                  value={clinic.openingHours.startTimeEvening}
                />
                <Detail.Cell
                  label={t('clinics.afternoon-close')}
                  value={clinic.openingHours.endTimeEvening}
                />
              </Detail.Section>
            </Detail.Side>
          </Detail>
        </Card.Body>
        <Card.Footer>
          <Link className="btn btn-secondary" to="/clinics">
            <BsChevronLeft />
            {t('common.back')}
          </Link>
          <Link className="btn btn-primary" to="./edit">
            <CiEdit />
            {t('common.edit')}
          </Link>
        </Card.Footer>
      </Card>
    </>
  )
}
