import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { IHttpClient } from './types'
import configService from '@app/services/config/config.service'
import download from 'downloadjs'

class HttpClient implements IHttpClient {
  private provider: AxiosInstance

  constructor() {
    this.provider = axios.create()

    this.provider.interceptors.request.use(config => {
      const accessToken = configService.token
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      const accessLanguage = configService.language
      if (accessLanguage) {
        config.headers['Accept-Language'] = accessLanguage
      }

      return config
    })

    this.provider.interceptors.response.use(
      ({ data }: AxiosResponse) => {
        return data
      },
      (error: AxiosError) => Promise.reject(error),
    )
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.provider.get(url, config)
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.provider.delete(url, config)
  }

  post<R, T>(url: string, data?: R, config?: AxiosRequestConfig): Promise<T> {
    return this.provider.post(url, data, config)
  }

  put<R, T>(url: string, data?: R, config?: AxiosRequestConfig): Promise<T> {
    return this.provider.put(url, data, config)
  }

  patch<R, T>(url: string, data?: R, config?: AxiosRequestConfig): Promise<T> {
    return this.provider.patch(url, data, config)
  }

  async download(url: string, config?: AxiosRequestConfig): Promise<void> {
    try {
      const response: Blob = await this.provider.get(url, {
        ...config,
        responseType: 'blob',
      })
      const filename = url.split('/').pop() || 'download'
      download(response, filename, 'application/pdf')
    } catch (error) {}
  }
}

const httpClient = new HttpClient()
export default httpClient
