import { SelectOption } from '@app/components/forms'
import { FC } from 'react'
import { CiSearch } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import {
  ControlProps,
  GroupBase,
  MenuProps,
  OptionProps,
  components,
} from 'react-select'

export const CustomOption: FC<OptionProps<SelectOption>> = ({
  children,
  ...props
}) => {
  return (
    <components.Option {...props}>
      <Link
        className="SelectControl__option btn btn-link text-dark SelectControl__option-link"
        to={`/patients/${props.data.value}`}
      >
        {children}
      </Link>
    </components.Option>
  )
}

export const CustomControl: FC<ControlProps<SelectOption, false>> = ({
  children,
  ...props
}) => {
  return (
    <components.Control {...props} className="SearchControl-input">
      <span className="SearchControl-icon">
        <CiSearch />
      </span>
      {children}
    </components.Control>
  )
}

export const CustomMenu: FC<
  MenuProps<SelectOption, false, GroupBase<SelectOption>>
> = ({ children, ...props }) => {
  return (
    <components.Menu {...props} className="bg-white">
      {children}
    </components.Menu>
  )
}
