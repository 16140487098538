import clsx from 'clsx'
import { FC } from 'react'
import Image from 'react-bootstrap/esm/Image'
import { useTranslation } from 'react-i18next'
import { CiFileOn, CiImageOn } from 'react-icons/ci'
import { MediaMenuActions } from './media-menu-actions/MediaMenuActions'
import { getDefaultImage } from '@app/utils/images/getDefaultImage'

import './MediaPreview.scss'

interface MediaPreviewProps {
  id: string
  identifier?: string
  handleClickImage?: (id: string) => void
  handleClear?: () => void
  handleUpload?: () => void
  title?: string
  url?: string
}

const previewExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']

export const MediaPreview: FC<MediaPreviewProps> = ({
  id,
  identifier,
  url,
  title,
  handleClear,
  handleUpload,
  handleClickImage,
}) => {
  const { t } = useTranslation()
  const label = title ?? t('common.no-file')

  const handleEmptyImageClick = () => {
    if (handleUpload) {
      handleUpload()
    }
  }

  const defaultImage = getDefaultImage(identifier)

  function shouldPreviewImage() {
    if (!url) {
      return false
    }

    const extension = url.split('.').pop()?.toLowerCase()

    return (
      url.startsWith('data:image') || previewExtensions.includes(extension!)
    )
  }

  return (
    <div className="MediaPreview">
      {url ? (
        <>
          {shouldPreviewImage() ? (
            <Image
              src={url}
              rounded
              className={clsx('MediaPreview-image', {
                'no-photo': !url,
              })}
              onClick={
                url && handleClickImage ? () => handleClickImage(id) : undefined
              }
            />
          ) : (
            <div className="MediaPreview-image">
              <CiFileOn className="icon-md" />
            </div>
          )}
        </>
      ) : (
        <div className="MediaPreview-image" onClick={handleEmptyImageClick}>
          {defaultImage ? (
            <Image src={defaultImage} className="icon-md" />
          ) : (
            <CiImageOn className="icon-md" />
          )}
        </div>
      )}

      <div className="MediaPreview-menu">
        <p className="text-truncate m-0">{label}</p>
        <MediaMenuActions
          id={id}
          url={url}
          handleUpload={handleUpload}
          handleClear={handleClear}
        />
      </div>
    </div>
  )
}
