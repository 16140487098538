import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { PaginatedResult, ResponseData } from '@app/core/types'
import {
  BillingAddress,
  BillingAddressDetail,
  BillingAddressPaginationQuery,
  CreateBillingAddressRequest,
  UpdateBillingAddressRequest,
} from './types'
import { IHttpClient } from '@app/core/http'
import { mapFiltersToRequest } from '@app/utils'
import { errorFactory } from '@app/core/api-errors/error-factory'

const getBaseUrl = (config: IConfigService) =>
  `${config.baseUrl}/billing-addresses`

const getBillingAddressList =
  (config: IConfigService, http: IHttpClient) =>
  async (
    query: BillingAddressPaginationQuery,
  ): Promise<PaginatedResult<BillingAddress>> => {
    const params = mapFiltersToRequest(query)
    try {
      return await http.get(`${getBaseUrl(config)}?${params}`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const getBillingAddress =
  (config: IConfigService, http: IHttpClient) =>
  async (id: string): Promise<BillingAddressDetail> => {
    try {
      const { data } = await http.get<ResponseData<BillingAddressDetail>>(
        `${getBaseUrl(config)}/${id}`,
      )

      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const createBillingAddress =
  (config: IConfigService, http: IHttpClient) =>
  async (billingAddress: CreateBillingAddressRequest): Promise<string> => {
    try {
      const { data } = await http.post<
        CreateBillingAddressRequest,
        ResponseData<BillingAddressDetail>
      >(`${getBaseUrl(config)}`, billingAddress)

      return data.id
    } catch (e) {
      throw errorFactory(e)
    }
  }

const updateBillingAddress =
  (config: IConfigService, http: IHttpClient) =>
  async (billingAddress: UpdateBillingAddressRequest): Promise<void> => {
    return await http.put(
      `${getBaseUrl(config)}/${billingAddress.id}`,
      billingAddress,
    )
  }

const factory = (config: IConfigService, http: IHttpClient) => {
  return {
    getBillingAddressList: getBillingAddressList(config, http),
    getBillingAddress: getBillingAddress(config, http),
    createBillingAddress: createBillingAddress(config, http),
    updateBillingAddress: updateBillingAddress(config, http),
  }
}

export default factory(config, http)
