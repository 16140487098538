import { TFunction } from 'i18next'

export function enumKeys<
  O extends Record<string, unknown>,
  K extends keyof O = keyof O,
>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
}

export function mapEnumIntoIterableTypeValue<
  T extends Record<string, number | string>,
  LocalePrefix extends string,
>(
  enumValue: T,
  localePrefix: LocalePrefix,
): {
  type: `${LocalePrefix}.${string & keyof T}`
  value: T[keyof T]
}[] {
  return enumKeys(enumValue).map(key => ({
    type: `${localePrefix}.${(key as string).toLowerCase()}`,
    value: enumValue[key],
  }))
}

export const getEnumKeyByValue = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enumObj: any,
  value: string,
): string | undefined => {
  return Object.keys(enumObj).find(key => enumObj[key] === value)
}

export const getLabelByEnumValue = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enumObj: any,
  value = '',
  trnsKey: string,
  t: TFunction,
): string => {
  const enumValue = getEnumKeyByValue(enumObj, value)?.toLowerCase()
  return enumValue ? t(`${trnsKey}.${enumValue}`) : ''
}
