import { State } from '@app/core/types'
import { useToast } from '@app/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import chatService from '@app/services/chat/chat.service'
import {
  CommentChat,
  RequestAsRead,
  RequestMessage,
} from '@app/services/chat/types'

type ChatState = State<CommentChat[], 'comments'> & {
  error?: Error
}

const initialState: ChatState = {
  loaded: false,
  comments: null,
  error: undefined,
}

type UseCaseChatType = {
  state: ChatState
  sendMessage: (text: string) => void
  changeAsRead: (lastMessageId: string) => void
  lastCommentId: number | null
  hasUnreadComments: boolean
}

export const useCaseChat = (
  caseId: string,
  userId?: number,
): UseCaseChatType => {
  const [state, setState] = useState<ChatState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()

  const loadComments = useCallback(
    async (id: string) => {
      try {
        const res = await chatService.getListComments(id)
        setState(() => ({
          loaded: true,
          comments: res,
          error: undefined,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          comments: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!caseId) {
      return
    }

    setState(prevState => ({
      ...prevState,
      loaded: false,
    }))

    loadComments(caseId)
  }, [loadComments, caseId])

  const sendMessage = useCallback(
    async (text: string) => {
      const message: RequestMessage = {
        caseId,
        body: text,
        requestChanges: false,
      }
      try {
        await chatService.sendComment(message)
        loadComments(caseId)
      } catch (e) {
        toastError(t('errors.load-data'))
      }
    },
    [caseId, loadComments, t, toastError],
  )

  const changeAsRead = useCallback(
    async (lastMessageId: string) => {
      const message: RequestAsRead = {
        caseId,
        lastMessageId,
      }
      try {
        setTimeout(async () => {
          await chatService.changeAsRead(message)
          loadComments(caseId)
        }, 2000)
      } catch (error) {
        toastError(t('errors.load-data'))
      }
    },
    [caseId, loadComments, t, toastError],
  )

  const lastCommentId = useMemo(() => {
    if (state.comments && state.comments.length > 0) {
      return state.comments[state.comments.length - 1].id
    }
    return null
  }, [state.comments])

  const hasUnreadComments = useMemo(() => {
    if (!state.comments || !userId) {
      return false
    }

    return state.comments.some(
      comment => !comment.isRead && comment.userId !== userId,
    )
  }, [state.comments, userId])

  return {
    state,
    sendMessage,
    changeAsRead,
    lastCommentId,
    hasUnreadComments,
  }
}
