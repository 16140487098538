import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import {
  showAdditionalAlignersAction,
  showRefinementAction,
} from '@app/store/slices'
import Modal from 'react-bootstrap/esm/Modal'
import Button from 'react-bootstrap/esm/Button'
import { useTranslation } from 'react-i18next'
import { ButtonAsync } from '@app/components'
import { usePhase } from '@app/hooks'
import { useParams } from 'react-router-dom'
import { reloadCaseAction } from '@app/store/slices/case/caseSlice'

export interface AdditionalAlignersModalProps {
  nextPhaseAvailable: boolean
}

export const AdditionalAlignersModal = ({
  nextPhaseAvailable,
}: AdditionalAlignersModalProps) => {
  const { t } = useTranslation()
  const { caseId } = useParams()
  const dispatch = useAppDispatch()
  const { showAdditionalAligners } = useAppSelector(state => state.ui)

  const {
    handleNextPhase,
    state: { loaded },
  } = usePhase()

  const onNextPhase = async () => {
    try {
      await handleNextPhase(caseId!)
      dispatch(showAdditionalAlignersAction(false))
      dispatch(reloadCaseAction(true))
    } catch (error) {}
  }
  return (
    <Modal
      show={showAdditionalAligners}
      onHide={() => dispatch(showAdditionalAlignersAction(false))}
      className="modal-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('cases.actions.additional-aligners')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="d-flex flex-column justify-content-between gap-3">
          <Button
            variant="primary"
            onClick={() => dispatch(showRefinementAction(true))}
          >
            {t('cases.actions.refine')}
          </Button>
          <ButtonAsync
            className="btn btn-primary"
            disabled={!nextPhaseAvailable}
            isSubmitting={!loaded}
            onClick={onNextPhase}
          >
            {t('cases.actions.next')}
          </ButtonAsync>
        </div>
      </Modal.Body>
    </Modal>
  )
}
