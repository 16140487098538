import { Route, Routes } from 'react-router-dom'
import { NotFound } from '../errors'
import { InvoicesPage } from './InvoicesPage'

export function InvoicesRoutes() {
  return (
    <Routes>
      <Route index element={<InvoicesPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
