import { ClinicsPage } from '@app/pages/clinics/ClinicsPage'
import {
  BillingAddressPage,
  CasesPage,
  DashboardPage,
  InvoicesPage,
} from '../pages'
import {
  CiCirclePlus,
  CiCreditCard1,
  CiFileOn,
  CiHome,
  CiMedicalClipboard,
  CiPercent,
} from 'react-icons/ci'
import { CaseNewPage } from '@app/pages/cases/new/CaseNewPage'

export interface OptionNav {
  to: string
  icon: JSX.Element
  title: string
  component: JSX.Element
}

export const newPatientOption: OptionNav = {
  to: '/patients/new',
  icon: <CiCirclePlus />,
  title: 'new-patient',
  component: <CaseNewPage />,
}

export const menuRoutes: OptionNav[] = [
  {
    to: '/dashboard',
    icon: <CiHome />,
    title: 'dashboard',
    component: <DashboardPage />,
  },
  {
    to: '/patients',
    icon: <CiFileOn />,
    title: 'cases',
    component: <CasesPage />,
  },
  {
    to: '/clinics',
    icon: <CiMedicalClipboard />,
    title: 'clinics',
    component: <ClinicsPage />,
  },
  {
    to: '/billing-address',
    icon: <CiCreditCard1 />,
    title: 'billing',
    component: <BillingAddressPage />,
  },
  {
    to: '/invoices',
    icon: <CiPercent />,
    title: 'invoices',
    component: <InvoicesPage />,
  },
]
