import { DetailUser } from '@app/services/auth/types'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import * as Sentry from "@sentry/browser";

export interface UserState {
  token: string | null
  user: DetailUser | null
  isLoaded: boolean
}

const initialState: UserState = {
  token: localStorage.getItem('user-token'),
  user: JSON.parse(localStorage.getItem('user-data') ?? 'null'),
  isLoaded: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    showLoader: state => {
      state.isLoaded = false
    },
    hideLoader: state => {
      state.isLoaded = true
    },
    setUserToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
    },
    setUserDetail: (state, action: PayloadAction<DetailUser | null>) => {
      state.user = action.payload

      if (action.payload) {
        Sentry.setContext("user", {
          id: action.payload.id,
          email: action.payload.email,
        });
      } else {
        Sentry.setContext("user", null);
      }
    },
    setUserLocation: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.locale = action.payload
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setUserToken,
  setUserDetail,
  showLoader,
  hideLoader,
  setUserLocation,
} = userSlice.actions

export default userSlice.reducer
