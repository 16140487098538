import { FormEvent, useState } from 'react'
import { CiPaperplane } from 'react-icons/ci'
import './TextMessageBox.scss'

interface TextMessageBoxProps {
  onSendMessage: (message: string) => void
  placeholder?: string
}

export const TextMessageBox = ({
  onSendMessage,
  placeholder,
}: TextMessageBoxProps) => {
  const [message, setMessage] = useState('')

  const handleSendMessage = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (message.trim().length === 0) return

    onSendMessage(message)
    setMessage('')
  }

  return (
    <form onSubmit={handleSendMessage} className="TextMessageBox">
      <div className="TextMessageBox-message">
        <input
          type="text"
          autoFocus
          name="message"
          className="form-control"
          placeholder={placeholder}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </div>

      <div>
        <button type="submit" className="btn btn-secondary">
          <CiPaperplane />
        </button>
      </div>
    </form>
  )
}
