import {
  CheckControl,
  SelectControl,
  TextareaControl,
} from '@app/components/forms'
import { Prescription } from '@app/services/prescription/types'
import { FC, useCallback } from 'react'
import { CaseFormFields, CaseEditFormFields } from '../../form-steps/types'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { OTHER_SOLUTION_ID } from '@app/pages/cases/utils'

import './PrescriptionForm.scss'

type PrescriptionFormProps = {
  problems: Prescription[]
  solutions: Prescription[]
}

export const PrescriptionForm: FC<PrescriptionFormProps> = ({
  problems,
  solutions,
}) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<CaseEditFormFields>()

  const selectedPatientProblems = watch('specific.patientProblems')?.filter(
    problem => problem.value,
  )

  const getProblemName = useCallback(
    (problemId: string) => {
      const problem = problems.find(
        problem => problem.id.toString() === problemId,
      )
      return problem ? t(`problems.${problem.name}`) : ''
    },
    [problems, t],
  )

  const getIndexProblemById = useCallback(
    (problemId: string) =>
      problems.indexOf(
        problems.find(problem => problem.id.toString() === problemId)!,
      ),
    [problems],
  )

  return (
    <div className="row PrescriptionForm" data-testid={'prescription-form'}>
      <div className="col-12 col-xl-3">
        <div className="PrescriptionForm-checks">
          {problems?.map((problem, i) => (
            <CheckControl<CaseFormFields>
              key={problem.id}
              name={`specific.patientProblems.${i}.value`}
              label={t(`problems.${problem.name}`)}
            />
          ))}
        </div>
      </div>
      <div className="col-12 col-xl-7">
        <h3 className="form-subtitle mt-xl-0">
          {t('cases.prescription.title')}
        </h3>
        <p>{t('cases.prescription.body')}</p>
        {selectedPatientProblems?.length ? (
          <div className="PrescriptionForm-list">
            {selectedPatientProblems.map(problem => {
              const solutionWatch = watch(
                `specific.patientProblems.${getIndexProblemById(problem.problemId)}.solutionId`,
              )

              return (
                <div className="PrescriptionForm-item" key={problem.problemId}>
                  <div className="lead fs-6 text-truncate">
                    {getProblemName(problem.problemId)}
                  </div>

                  <SelectControl
                    name={`specific.patientProblems.${getIndexProblemById(problem.problemId)}.solutionId`}
                    label={t('cases.solution')}
                    options={solutions?.map(d => ({
                      label: t(`solutions.${d.name}`),
                      value: d.id.toString(),
                    }))}
                    required
                  />
                  {solutionWatch === OTHER_SOLUTION_ID && (
                    <TextareaControl<CaseFormFields>
                      rows={3}
                      name={`specific.patientProblems.${getIndexProblemById(problem.problemId)}.otherSolution`}
                      label={t('cases.comments')}
                    />
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          <div className="lead fs-6 py-3">
            {t('cases.prescription.no-selected')}
          </div>
        )}
      </div>
    </div>
  )
}
