import {
  BreadcrumbDynamic,
  Card,
  Detail,
  Main,
  PageHeader,
  StateHandler,
} from '@app/components'
import { ItemBreadCrumb } from '@app/components/breadcrumb-dynamic/types'
import { useBillingAddressDetail } from '@app/hooks/billing-address/useBillingAddressDetail'
import {
  BillingAddressDetail,
  PaymentMethods,
} from '@app/services/billing-address/types'
import { getEnumKeyByValue } from '@app/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BsChevronLeft } from 'react-icons/bs'
import { CiEdit } from 'react-icons/ci'
import { Link, useParams } from 'react-router-dom'

export const BillingAddressDetailPage = () => {
  const { billingAddressId } = useParams()
  const {
    state: { loaded, error, billingAddress },
  } = useBillingAddressDetail(billingAddressId!)
  return (
    <Main
      ariaLabelledby="billing-address-detail-page"
      className="BillingAddressDetailPage"
    >
      <StateHandler loaded={loaded} error={error}>
        <BillingAddressContent billing={billingAddress!} />
      </StateHandler>
    </Main>
  )
}

const BillingAddressContent: FC<{ billing: BillingAddressDetail }> = ({
  billing,
}) => {
  const { t } = useTranslation()
  const breadcrumbItems: ItemBreadCrumb[] = [
    { label: t('menu.billing'), href: '/billing-address' },
    { label: billing.name },
  ]
  return (
    <>
      <PageHeader id="billing-address-detail-page">
        <BreadcrumbDynamic items={breadcrumbItems} activeItem={billing.name} />
        {billing.name}
      </PageHeader>
      <Card>
        <Card.Body size={'sm'}>
          <Detail.Section>
            <>
              <Detail.Cell
                label={t('billing-address.name')}
                value={billing.name}
              />
              <Detail.Cell
                label={t('billing-address.cif')}
                value={billing.vatNumber}
              />
              <Detail.Cell
                label={t('billing-address.phone')}
                value={billing.phone}
              />
              <Detail.Cell
                label={t('billing-address.email')}
                value={billing.email}
              />
              <Detail.Cell
                label={t('billing-address.address')}
                value={billing.address}
              />
              <Detail.Cell
                label={t('billing-address.address-number')}
                value={billing.addressNumber}
              />
              <Detail.Cell
                label={t('billing-address.address-floor')}
                value={billing.addressExtra}
              />
              <Detail.Cell
                label={t('billing-address.postal-code')}
                value={billing.postalCode}
              />
              <Detail.Cell
                label={t('billing-address.country')}
                value={billing.city.province.country.name}
              />

              <Detail.Cell
                label={t('billing-address.province')}
                value={billing.city.province.name}
              />
              <Detail.Cell
                label={t('billing-address.city')}
                value={billing.city.name}
              />
              <div className="col-12 col-md-6 Detail-cell d-none d-md-flex"></div>
              <Detail.SubTitle>{t('billing-address.pays')}</Detail.SubTitle>
              <Detail.Cell
                label={t('billing-address.pay-type')}
                value={t(
                  `billing-address.payment-methods.${getEnumKeyByValue(PaymentMethods, billing.paymentMethod)}`,
                )}
              />
              <Detail.Cell
                label={t('billing-address.iban')}
                value={billing.accountNumber}
              />
            </>
          </Detail.Section>
        </Card.Body>
        <Card.Footer>
          <Link className="btn btn-secondary" to="/billing-address">
            <BsChevronLeft />
            {t('common.back')}
          </Link>
          <Link className="btn btn-primary" to="./edit">
            <CiEdit />
            {t('common.edit')}
          </Link>
        </Card.Footer>
      </Card>
    </>
  )
}
