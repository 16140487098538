import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import {
  Tab as TabReact,
  Tabs as TabsReact,
  TabProps,
  TabsProps,
} from 'react-bootstrap'

import './Tabs.scss'

type TabsContainerType = PropsWithChildren & TabsProps

const TabsContainer: FC<TabsContainerType> = ({
  children,
  className,
  ...props
}) => {
  return (
    <TabsReact variant={'tabs'} className={clsx('Tabs', className)} {...props}>
      {children}
    </TabsReact>
  )
}

export const TabsItem: FC<PropsWithChildren & TabProps> = ({
  children,
  ...props
}) => (
  <TabReact className="Tabs-item" {...props}>
    {children}
  </TabReact>
)

export const Tabs = Object.assign(TabsContainer, {
  Item: TabsItem,
})
