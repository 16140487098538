import React, { useEffect } from 'react'
import { useAuth } from '@app/hooks'
import Spinner from '@app/components/spinner/Spinner'

const LogoutPage: React.FC = () => {
  const { logout } = useAuth()

  useEffect(() => {
    const performLogout = async () => {
      await logout()
    }

    performLogout()
  }, [logout])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Spinner />
    </div>
  )
}

export default LogoutPage
