import { State } from '@app/core/types'
import { useToast } from '@app/hooks'
import { Case } from '@app/services/cases/types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import casesService from '@app/services/cases/cases.service'

type CaseState = State<Case, 'case'> & { error?: Error }

const initialState: CaseState = {
  loaded: false,
  case: null,
  error: undefined,
}

type UseCaseDetailType = {
  state: CaseState
  loadCase: (id: string) => void
  handleSelectKey: (eventKey: string | null) => void
  selectedKey: string
}

export const useCaseDetail = (caseId: string): UseCaseDetailType => {
  const [selectedKey, setSelectedKey] = useState(() => {
    return localStorage.getItem('selectedKey-detailCase') || 'information'
  })

  const handleSelectKey = (eventKey: string | null) => {
    setSelectedKey(eventKey as string)
  }

  useEffect(() => {
    localStorage.setItem('selectedKey-detailCase', selectedKey)
  }, [selectedKey])

  const [state, setState] = useState<CaseState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()

  const loadCase = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const res = await casesService.getCase(id)
        setState(() => ({
          loaded: true,
          case: res,
          error: undefined,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          case: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!caseId) {
      return
    }
    loadCase(caseId)
  }, [loadCase, caseId])

  return {
    handleSelectKey,
    loadCase,
    selectedKey,
    state,
  }
}
