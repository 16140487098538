import { Card, Detail, EmptyList, StateHandler } from '@app/components'
import { useTranslation } from 'react-i18next'

import { useCallback, useState } from 'react'
import { ShowImage } from '@app/components/modal/show-image/ShowImage'
import { MediaPreview } from '@app/components/media-preview/MediaPreview'
import { useCaseFiles } from '@app/hooks/files'
import { CaseFile, CaseFileType } from '@app/services/files/types'
import { CiFileOn, CiSaveDown2 } from 'react-icons/ci'
import { downloadDocument } from '@app/utils'

import './CaseDetailMultimedia.scss'

interface MultimediaPageProps {
  idCase: string
}

export const CaseDetailMultimedia = ({ idCase }: MultimediaPageProps) => {
  const {
    state: { loaded, error },
    filteredImages,
  } = useCaseFiles(idCase)
  return (
    <StateHandler loaded={loaded} error={error}>
      <MultimediaContent photographs={filteredImages} />
    </StateHandler>
  )
}

interface MultimediaContentProps {
  photographs: {
    photo: CaseFile[]
    radio: CaseFile[]
    other: CaseFile[]
    stl: CaseFile[]
  }
}

const MultimediaContent = ({ photographs }: MultimediaContentProps) => {
  const { t } = useTranslation()
  const [showModalImage, setShowModalImage] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState<CaseFile>()

  const getPhotoById = useCallback(
    (id: CaseFileType) => {
      const photo = photographs.photo.find(photo => id === photo.identifier)
      const radio = photographs.radio.find(radio => id === radio.identifier)

      return photo || radio
    },
    [photographs.photo, photographs.radio],
  )

  const handleShowModal = (id: string) => {
    setSelectedPhoto(getPhotoById(id as CaseFileType))
    setShowModalImage(true)
  }

  if (!photographs.photo?.length && !photographs.radio?.length) {
    return <EmptyList title="empty-list.title" />
  }

  return (
    <>
      <Card.Body size={'sm'} className="CaseDetailMultimedia">
        <Detail.Section className="CaseDetailMultimedia-section">
          <>
            {!!photographs.photo?.length && (
              <>
                <Detail.SubTitle>
                  {t('cases.multimedia.photos')}
                </Detail.SubTitle>
                <div className="CaseDetailMultimedia-grid">
                  {photographs.photo.map(item => (
                    <MediaPreview
                      key={item.identifier}
                      id={item.identifier}
                      url={item.url}
                      title={t(`cases.multimedia.${item.identifier}`)}
                      handleClickImage={handleShowModal}
                    />
                  ))}
                </div>
              </>
            )}
            {!!photographs.radio?.length && (
              <div className="mt-3">
                <Detail.SubTitle>{t('cases.multimedia.radio')}</Detail.SubTitle>
                <div className="CaseDetailMultimedia-grid">
                  {photographs.radio.map(item => (
                    <MediaPreview
                      key={item.identifier}
                      id={item.identifier}
                      url={item.url}
                      title={t(`cases.multimedia.${item.identifier}`)}
                      handleClickImage={handleShowModal}
                    />
                  ))}
                </div>
              </div>
            )}
            {!!photographs.stl?.length && (
              <div className="mt-4">
                <Detail.SubTitle>{t('cases.multimedia.files')}</Detail.SubTitle>

                {photographs.stl.map((item, index) => (
                  <ItemList
                    item={item}
                    index={index}
                    title={t(`cases.multimedia.${item.identifier}`)}
                  />
                ))}
              </div>
            )}
            {!!photographs.other?.length && (
              <div className="mt-4">
                <Detail.SubTitle>{t('cases.multimedia.other')}</Detail.SubTitle>

                {photographs.other.map((item, index) => (
                  <ItemList item={item} index={index} title={item.fileName!} />
                ))}
              </div>
            )}
          </>
        </Detail.Section>
      </Card.Body>
      {showModalImage && (
        <ShowImage setShowModal={setShowModalImage} photo={selectedPhoto!} />
      )}
    </>
  )
}

interface ItemListProps {
  item: CaseFile
  index: number
  title: string
}

const ItemList = ({ item, index, title }: ItemListProps) => {
  return (
    <div
      className="CaseDetailMultimedia-list"
      key={`${item.identifier}-${index}`}
    >
      <div>
        <CiFileOn />
        <span>{title}</span>
      </div>
      <button
        className="btn btn-secondary btn-sm"
        onClick={() => downloadDocument(item.url, item.fileOriginalName)}
        disabled={!item.url}
      >
        <CiSaveDown2 />
      </button>
    </div>
  )
}
