import { StatePagination } from '@app/core/types'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import billingAddressService from '@app/services/billing-address/billing-address.service'
import {
  BillingAddress,
  BillingAddressPaginationQuery,
} from '@app/services/billing-address/types'
import { useQueryFiltersPagination } from '../query-filters-pagination/useQueryFiltersPagination'
import { INIT_QUERY_PAGE } from '@app/utils'

const PAGE_SIZE = 12

type BillingAddressState = StatePagination<
  BillingAddress[],
  'billingAddressList'
>

const initialState: BillingAddressState = {
  loaded: false,
  billingAddressList: null,
  lastPage: 0,
  page: INIT_QUERY_PAGE,
  perPage: PAGE_SIZE,
}

const initialQuery: BillingAddressPaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: PAGE_SIZE,
}

type UseBillingAddressType = {
  state: BillingAddressState
  setPage: (page: number) => void
}

export const useBillingAddress = (): UseBillingAddressType => {
  const [state, setState] = useState<BillingAddressState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()
  const { query, setPage } = useQueryFiltersPagination({
    initialQuery,
  })

  const loadListBilling = useCallback(
    async (queryBillingAddress: BillingAddressPaginationQuery) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const { data: billingAddressList, meta } =
          await billingAddressService.getBillingAddressList(queryBillingAddress)
        setState(() => ({
          loaded: true,
          billingAddressList,
          ...meta,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          billingAddressList: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    loadListBilling(query)
  }, [loadListBilling, query])

  return {
    state,
    setPage,
  }
}
