import { useCallback } from 'react'
import { useTableContext } from '../context'
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs'

import './SortButtons.scss'
import clsx from 'clsx'

export type SortButtonsProps = {
  sortId: string
}

export type SortType = 'up' | 'down'

export const SortButtons = ({ sortId }: SortButtonsProps) => {
  const { sortedBy, handleSortBy } = useTableContext()

  const isActive = useCallback(
    (type: SortType) => sortedBy?.id === sortId && sortedBy?.type === type,
    [sortId, sortedBy],
  )

  return (
    <div className="SortButtons">
      <button
        type="button"
        className={clsx('btn btn-icon SortButtons-btn up', {
          active: isActive('up'),
        })}
        onClick={() => handleSortBy(sortId, 'up')}
      >
        <BsCaretUpFill />
      </button>
      <button
        type="button"
        className={clsx('btn btn-icon SortButtons-btn down', {
          active: isActive('down'),
        })}
        onClick={() => handleSortBy(sortId, 'down')}
      >
        <BsCaretDownFill />
      </button>
    </div>
  )
}
