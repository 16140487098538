import Image from 'react-bootstrap/esm/Image'
import appAvatar from '@images/placeholder-avatar.png'
import { ProfileSetting } from './profile-setting/ProfileSetting'
import { useAppSelector } from '@app/store/hooks'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import './ProfileInfo.scss'

export const ProfileInfo: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector(state => state.user)
  return (
    <div className="ProfileInfo">
      <div className="ProfileInfo-avatar mb-lg-2">
        <Image
          className={clsx('ProfileInfo-avatarIcon', 'd-none', 'd-lg-block', {
            'p-0': user?.avatar,
          })}
          src={user && user.avatar ? user.avatar : appAvatar}
          roundedCircle
        />
        <div className="d-block d-lg-none">
          <ProfileSetting isMobile={true} />
        </div>
      </div>
      <div className="d-none d-lg-flex ProfileInfo-details">
        {`${user?.firstName} ${user?.lastNames}`}
        {user?.roles.includes('ROLE_USER') && (
          <small className="fs-sm">{t('menu.dentist')}</small>
        )}

        <span className="ProfileInfo-settings badge">
          <ProfileSetting />
          <span className="visually-hidden">${t('menu.menu')}</span>
        </span>
      </div>
    </div>
  )
}
