import { PaginationFiltersQuery } from '@app/core/types'
import { DEBOUNCE_DELAY, INIT_QUERY_PAGE } from '@app/utils'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'

type UseQueryFiltersPagination<T, U> = {
  initialQuery: T
  filter?: U
  onSearch?: () => void
}

export function useQueryFiltersPagination<
  T extends PaginationFiltersQuery<QueryFilters>,
  QueryFilters,
  U,
>({
  initialQuery,
  filter = {} as U,
  onSearch,
}: UseQueryFiltersPagination<T, U>) {
  const [query, setQuery] = useState<T>(initialQuery)

  const setPage = useCallback(async (page: number) => {
    setQuery(prevState => ({
      ...prevState,
      page,
    }))
  }, [])

  const setQueryProperty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (prop: any) => {
      onSearch?.()

      setQuery(prevState => ({
        ...prevState,
        ...prop,
        page: INIT_QUERY_PAGE,
      }))
    },
    [onSearch],
  )

  const resetQuery = useCallback(() => {
    setQuery(initialQuery)
  }, [initialQuery])

  const search = useCallback(
    (term: string) => setQueryProperty({ search: term }),
    [setQueryProperty],
  )
  const applyFilters = useCallback(
    (queryFilters: U) => setQueryProperty({ filter: queryFilters }),
    [setQueryProperty],
  )

  const setSortBy = useCallback(
    (querySort: string) => {
      setQueryProperty({ sort: querySort })
    },
    [setQueryProperty],
  )

  const debouncedSearch = useMemo(
    () => debounce(search, DEBOUNCE_DELAY),
    [search],
  )

  useEffect(() => {
    if (JSON.stringify(query.filter) === JSON.stringify(filter)) {
      return
    }

    applyFilters(filter)
  }, [filter, applyFilters, query.filter])

  return {
    resetQuery,
    debouncedSearch,
    setPage,
    setSortBy,
    query,
  }
}
