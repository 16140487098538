import { FC } from 'react'
import { Card, StateHandler } from '@app/components'
import { useTreatmentPlans } from '@app/hooks'
import { TreatmentHistory } from './treatment-history/TreatmentHistory'
import { TreatmentContent } from './treatment-content/TreatmentContent'
import { ActionsNotAccepted } from './actions-not-accepted/ActionsNotAccepted'
import { CaseStatus } from '@app/services/cases/types'
import { ChatWidget } from './chat-widget/ChatWidget'
import { ActionsAccepted } from './actions-accepted/ActionsAccepted'

export const CaseDetailAssessments: FC<{
  caseId: string
  nextPhaseAvailable: boolean
  status: CaseStatus
}> = ({ caseId, nextPhaseAvailable, status }) => {
  const {
    state: { treatmentPlans, loaded, error },
    selectedPlan,
    withVisorUrl,
    setSelectedPlan,
    changeTreatmentAcceptance,
  } = useTreatmentPlans(caseId)

  return (
    <div className="row DetailAssessments">
      <div className="col-md-4 mb-3">
        <div className="d-flex gap-4 flex-column">
          <Card>
            <Card.Body>
              <StateHandler loaded={loaded} error={error}>
                <TreatmentHistory
                  treatmentPlans={treatmentPlans!}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                />
              </StateHandler>
            </Card.Body>
          </Card>
          {status === CaseStatus.Sent && (
            <ActionsAccepted nextPhaseAvailable={nextPhaseAvailable} />
          )}
          <ChatWidget caseId={caseId} />
        </div>
      </div>

      <div className="col-md-8">
        <StateHandler loaded={loaded} error={error}>
          <TreatmentContent
            selectedPlan={selectedPlan!}
            withVisor={withVisorUrl}
          />
          <ActionsNotAccepted
            selectedPlan={selectedPlan!}
            onChange={changeTreatmentAcceptance}
          />
        </StateHandler>
      </div>
    </div>
  )
}
