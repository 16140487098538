import {
  createContext,
  useContext,
  FC,
  useState,
  useRef,
  useCallback,
  PropsWithChildren,
} from 'react'
import { ConfirmModal } from './confirm-modal/ConfirmModal'
import { ConfirmAction, ConfirmContextOptions, ConfirmResponse } from './types'

const ConfirmContext = createContext<ConfirmAction>(Promise.reject)

export const ConfirmationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [confirmState, setConfirmState] =
    useState<ConfirmContextOptions | null>(null)

  const awaitingPromiseRef = useRef<{
    resolve: <T = unknown>(isConfirmed: boolean, payload: T) => void
  }>()

  const confirm = useCallback(function <T>(options: ConfirmContextOptions<T>) {
    setConfirmState(options)

    return new Promise<ConfirmResponse<T>>(resolve => {
      awaitingPromiseRef.current = {
        resolve: (isConfirmed: boolean, payload: unknown) =>
          resolve({ isConfirmed, payload: payload as T }),
      }
    })
  }, [])

  const handleCancel = () => {
    let payload: unknown

    if (confirmState?.onCancel) {
      payload = confirmState.onCancel()
    }

    awaitingPromiseRef.current?.resolve(false, payload)
    setConfirmState(null)
  }

  const handleConfirm = async () => {
    let payload: unknown

    if (confirmState?.onConfirm) {
      payload = confirmState.onConfirm()
    }

    await awaitingPromiseRef.current?.resolve(true, payload)
    setConfirmState(null)
  }

  return (
    <ConfirmContext.Provider value={confirm}>
      {children}

      <ConfirmModal
        size="sm"
        isVisible={!!confirmState}
        title={confirmState?.title ?? ''}
        confirmActionText={confirmState?.confirmActionText}
        cancelActionText={confirmState?.cancelActionText}
        singleConfirmation={confirmState?.singleConfirmation}
        onConfirmationAction={handleConfirm}
        onCancelAction={handleCancel}
        allowToClose={confirmState?.allowToClose}
      >
        {!!confirmState && confirmState?.content && confirmState.content}
      </ConfirmModal>
    </ConfirmContext.Provider>
  )
}

export const useConfirmContext = () => useContext(ConfirmContext)
