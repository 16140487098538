import { object, string, array, mixed, AnyObjectSchema, boolean } from 'yup'
import { TFunction } from 'i18next'
import {
  CaseArcadeType,
  CaseCutType,
  CaseTargetType,
} from '@app/services/cases/types'
import { OTHER_SOLUTION_ID } from '@app/pages/cases/utils'

export function specificInfoValidationSchema(t: TFunction): AnyObjectSchema {
  const caseCutType = Object.values(CaseCutType) as CaseCutType[]
  const caseTargetType = Object.values(CaseTargetType) as CaseTargetType[]
  const caseArcadeType = Object.values(CaseArcadeType) as CaseArcadeType[]

  return object().shape({
    specific: object().shape({
      cutType: mixed<CaseCutType>()
        .oneOf(caseCutType)
        .required(t('validation.required')),
      secretRetainers: object().shape({
        requestOnFinish: boolean().required(t('validation.required')),
        archs: mixed<CaseArcadeType>()
          .oneOf(caseArcadeType)
          .when('requestOnFinish', {
            is: (v: boolean) => !!v,
            then: s => s.required(t('validation.required')),
            otherwise: s => s.nullable(),
          }),
      }),
      target: mixed<CaseTargetType>()
        .oneOf(caseTargetType)
        .required(t('validation.required')),
      useElastics: boolean().when('target', {
        is: (v: CaseTargetType) =>
          v === CaseTargetType.EstheticOcclusion ||
          v === CaseTargetType.Occlusion,
        then: s => s.required(t('validation.required')),
        otherwise: s => s.nullable(),
      }),
      distalize: boolean().when('target', {
        is: (v: CaseTargetType) =>
          v === CaseTargetType.EstheticOcclusion ||
          v === CaseTargetType.Occlusion,
        then: s => s.required(t('validation.required')),
        otherwise: s => s.nullable(),
      }),
      thirdMolarExtraction: boolean().when('target', {
        is: (v: CaseTargetType) =>
          v === CaseTargetType.EstheticOcclusion ||
          v === CaseTargetType.Occlusion,
        then: s => s.required(t('validation.required')),
        otherwise: s => s.nullable(),
      }),
      patientProblems: array()
        .of(
          object().shape({
            problemId: string(),
            value: boolean(),
            solutionId: string().when('value', {
              is: (v: boolean) => !!v,
              then: s => s.required(t('validation.required')),
              otherwise: s => s.optional(),
            }),
            otherSolution: string().when('solutionId', {
              is: (v: string) => v === OTHER_SOLUTION_ID,
              then: s => s.required(t('validation.required')),
              otherwise: s => s.optional(),
            }),
          }),
        )
        .optional()
        .nullable(),
      indications: string().notRequired(),
    }),
  })
}
