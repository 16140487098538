import { object, string, ObjectSchema } from 'yup'
import { BillingAddressFormFields } from './types'
import { TFunction } from 'i18next'

export function buildValidationSchema(
  t: TFunction,
): ObjectSchema<BillingAddressFormFields> {
  return object()
    .shape({
      name: string().required(t('validation.required')),
      vatNumber: string().required(t('validation.required')),
      email: string()
        .email(t('validation.email'))
        .required(t('validation.required')),
      phone: string()
        .max(
          11,
          t('validation.max', {
            number: 11,
          }),
        )
        .required(t('validation.required')),
      address: string().required(t('validation.required')),
      addressNumber: string().required(t('validation.required')),
      addressExtra: string(),
      postalCode: string()
        .max(
          5,
          t('validation.max', {
            number: 5,
          }),
        )
        .required(t('validation.required')),
      country: string().required(t('validation.required')),
      province: string().required(t('validation.required')),
      city: string().required(t('validation.required')),
      accountNumber: string(),
    })
    .required() as ObjectSchema<BillingAddressFormFields>
}
