import { Card } from '@app/components'
import { CaseStatus } from '@app/services/cases/types'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsChevronLeft } from 'react-icons/bs'
import { CiEdit } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import { allowedStatusToEdit } from '../../utils'

interface ActionsWidgetProps {
  status: CaseStatus
  idCase: string
}

export const ActionsWidget: FC<ActionsWidgetProps> = ({ status, idCase }) => {
  const { t } = useTranslation()

  const showEditButton = useMemo(
    () => allowedStatusToEdit.some(arr => arr === status),
    [status],
  )

  return (
    <Card className="my-4">
      <Card.Body size={'sm'}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div>
            <Link className="btn btn-secondary" to="../">
              <BsChevronLeft />
              {t('common.back')}
            </Link>
          </div>
          {showEditButton && (
            <div>
              <Link to={`/patients/${idCase}/edit`} className="btn btn-primary">
                <CiEdit />
                {t('common.edit')}
              </Link>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}
