import { StrictMode, Suspense } from 'react'
import { Provider } from 'react-redux'
import { store } from '@app/store/'
import ReactDOM from 'react-dom/client'
import { App } from '@app/App'
import { Loader } from '@app/components'
import { loadValidations } from '@app/core/validations'
import * as Sentry from '@sentry/browser'

import './styles/index.scss'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_BROWSER,
  environment: import.meta.env.MODE,
  tracesSampleRate: 0.01,
})

loadValidations()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </Provider>
  </StrictMode>,
)
