import { Main, StateHandler } from '@app/components'
import { useMemo } from 'react'
import { useBillingAddress, useClinics } from '@app/hooks'
import { CaseNewForm } from './form/CaseNewForm'

export const CaseNewPage = () => {
  const {
    state: { loaded: loadedClinics, error: errorClinics, clinics },
  } = useClinics()
  const {
    state: {
      loaded: loadedBillingAddress,
      error: errorBillingAddress,
      billingAddressList,
    },
  } = useBillingAddress()

  const loadedData = useMemo(
    () => loadedClinics && loadedBillingAddress,
    [loadedBillingAddress, loadedClinics],
  )
  const errorData = useMemo(
    () => errorClinics && errorBillingAddress,
    [errorClinics, errorBillingAddress],
  )

  return (
    <Main ariaLabelledby="case-new-page" className="CaseNewPage">
      <StateHandler loaded={loadedData} error={errorData}>
        <CaseNewForm
          clinics={clinics || []}
          billingAddress={billingAddressList || []}
        />
      </StateHandler>
    </Main>
  )
}
