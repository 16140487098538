import { useCallback, useState } from 'react'
import {
  URLSearchParamsInit,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

type UseNavTabsType<T> = {
  handleOnSelect: (selectedKey: T) => void
  activeTab: T
}

export function useNavTabs<T>(activeTabOnStart: T): UseNavTabsType<T> {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queryParamTab = searchParams.get('tab') as T
  const [activeTab, setActiveTab] = useState<T>(
    queryParamTab ?? activeTabOnStart,
  )

  const handleOnSelect = useCallback(
    (selectedKey: T) => {
      navigate({
        pathname: '.',
        search: createSearchParams({
          tab: selectedKey,
        } as URLSearchParamsInit).toString(),
      })
      setActiveTab(selectedKey!)
    },
    [navigate],
  )

  return {
    handleOnSelect,
    activeTab,
  }
}
