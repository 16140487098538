import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  PropsWithChildren,
} from 'react'
import { FiltersType, IFiltersContext } from './types'

const stub = (): never => {
  throw new Error(
    'You forgot to wrap your component in <FilterSearchProvider>.',
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FiltersContext = createContext<IFiltersContext<any>>({
  filter: {},
  opened: false,
  hasFilters: false,
  toggleVisibility: stub,
  setFilters: stub,
})

export function FiltersProvider<T extends FiltersType>({
  children,
  initialFilters = {},
}: PropsWithChildren<FiltersType>) {
  const emptyFilter = initialFilters as T
  const [opened, setOpened] = useState(false)
  const [filter, setFilters] = useState<T>(emptyFilter)

  const hasFilters = useMemo(() => !!Object.keys(filter).length, [filter])
  const toggleVisibility = useCallback(
    () => setOpened(prevState => !prevState),
    [],
  )

  return (
    <FiltersContext.Provider
      value={{
        toggleVisibility,
        hasFilters,
        setFilters,
        filter,
        opened,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export function useFiltersContext<T extends FiltersType>() {
  return useContext<IFiltersContext<T>>(FiltersContext)
}
