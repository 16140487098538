import { RadioOption } from '@app/components/forms'
import clsx from 'clsx'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CaseFormFields } from '../../types'
import { useTranslation } from 'react-i18next'
import { CaseType } from '@app/services/cases/types'

import './CaseTypeRadioControl.scss'

type CaseTypeRadioControlProps = {
  options: (RadioOption & { body: string })[]
  required: boolean
}

export const CaseTypeRadioControl: FC<CaseTypeRadioControlProps> = ({
  options,
  required,
}) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<CaseFormFields>()

  return (
    <div className="CaseTypeRadio">
      <Controller
        control={control}
        name={'type'}
        render={({ field }) => (
          <>
            {options.map(({ value, label, body }, i) => (
              <div
                className={clsx('CaseTypeRadio-control')}
                key={value.toString()}
              >
                <label
                  className={clsx(
                    'CaseTypeRadio-label text-break',
                    {
                      required,
                    },
                    { checked: field.value === value },
                  )}
                >
                  <input
                    key={value.toString()}
                    className={clsx('CaseTypeRadio-input')}
                    type={'radio'}
                    value={field.value}
                    name={field.name}
                    required={required}
                    checked={field.value === value}
                    onChange={() => field.onChange(value)}
                    onBlur={field.onBlur}
                  />
                  <span>{label}</span>

                  {body && <p>{body}</p>}

                  {i === options.length - 1 && (
                    <button
                      className="btn btn-primary"
                      data-testid="case-type-secret-retainer"
                      type="button"
                      onClick={() => setValue('type', CaseType.SecretRetainer)}
                    >
                      {t('cases.case-type.secretretainer.label')}
                    </button>
                  )}
                </label>
              </div>
            ))}
          </>
        )}
      />
    </div>
  )
}
