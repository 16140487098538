import { DateFilterType } from '@app/services/cases/types'
import { CasesFiltersFormFields } from './types'

export function coerceValues(): Partial<CasesFiltersFormFields> {
  return {
    status: undefined,
    clinic: '',
    billingAddress: '',
    dateType: DateFilterType.CreatedAt,
    createdAt: {
      from: '',
      to: '',
    },
    updatedAt: {
      from: '',
      to: '',
    },
    acceptedAt: {
      from: '',
      to: '',
    },
  }
}
