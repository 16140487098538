import { FC } from 'react'
import { CaseStep } from '../../form-steps/types'
import { Stepper } from '@app/components'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export type FormStep = {
  id: CaseStep
  label: string
}

type CaseStepperProps = {
  steps: FormStep[]
  activeStep: CaseStep
}
export const CaseStepper: FC<CaseStepperProps> = ({ steps, activeStep }) => {
  const { t } = useTranslation()

  return (
    <div className="row justify-content-center mt-5 mb-3">
      <div className="col-12 col-lg-8">
        <Stepper>
          {steps.map((step, index) => (
            <Stepper.Item
              key={step.id}
              className={clsx({
                completed: step.id < activeStep,
                active: step.id === activeStep,
              })}
              data-testid={`step-item-${step.label}`}
            >
              <Stepper.Number>{index + 1}</Stepper.Number>
              <Stepper.Name>{t(`cases.steps.${step.label}`)}</Stepper.Name>
            </Stepper.Item>
          ))}
        </Stepper>
      </div>
    </div>
  )
}
