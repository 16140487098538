import { object, string, ObjectSchema } from 'yup'
import { TFunction } from 'i18next'
import { RequestChangesFormFields } from './types'

export function requestChangesFormValidationSchema(
  t: TFunction,
): ObjectSchema<RequestChangesFormFields> {
  return object().shape({
    requestedChangesText: string().required(t('validation.required')),
  })
}
