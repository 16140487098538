import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

import './Stepper.scss'

type StepperContainerType = PropsWithChildren

const StepperContainer: FC<StepperContainerType & { className?: string }> = ({
  children,
  className,
}) => {
  return <div className={clsx('Stepper', className)}>{children}</div>
}

export const StepperItem: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
  ...props
}) => (
  <div className={clsx('Stepper-item', className)} {...props}>
    {children}
  </div>
)

export const StepperNumber: FC<PropsWithChildren> = ({ children }) => (
  <div className="Stepper-counter">{children}</div>
)

export const StepperName: FC<PropsWithChildren> = ({ children }) => (
  <div className="Stepper-name">{children}</div>
)

export const Stepper = Object.assign(StepperContainer, {
  Item: StepperItem,
  Number: StepperNumber,
  Name: StepperName,
})
