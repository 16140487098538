import { createObjectErrors } from '@app/utils/forms'
import * as yup from 'yup'

declare module 'yup' {
  interface ObjectSchema<TIn, TContext, TDefault, TFlags> {
    atLeastOneOf(
      list: string[],
      message: string,
    ): yup.ObjectSchema<TIn, TContext, TDefault, TFlags>
  }
}

export function loadValidations() {
  yup.addMethod(
    yup.object,
    'atLeastOneOf',
    function (list: string[], message: string) {
      return this.test({
        name: 'atLeastOneOf',
        message,
        exclusive: true,
        params: { keys: list.join(', ') },
        test: (objectValue, { path, createError }) => {
          return objectValue == null || list.some(f => !!objectValue[f])
            ? true
            : createObjectErrors(list, objectValue, path, message, createError)
        },
      })
    },
  )
}
