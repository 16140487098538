import { LanguagesApp } from '../../../i18n/types'

export function getBrowserLanguage() {
  const browserLanguage = navigator.language || navigator.languages[0]
  const localeCode = new Intl.Locale(browserLanguage)
  const languageCode = localeCode.language as LanguagesApp
  const isLanguageApp = Object.values(LanguagesApp).includes(languageCode)

  return isLanguageApp ? languageCode : LanguagesApp.EN
}
