import { Route, Routes } from 'react-router-dom'
import { ProfilePage } from './ProfilePage'
import { NotFound } from '../errors'

export function ProfileRoutes() {
  return (
    <Routes>
      <Route index element={<ProfilePage />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
