import { Card } from '@app/components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UploadControl } from '@app/components/forms'
import { CaseFormFields } from '../types'
import {
  CaseFileRequest,
  CaseFileType,
  RadioIdentifiers,
} from '@app/services/files/types'
import { FC } from 'react'
import { useCaseFileDetail } from '@app/hooks/files'
import { useParams } from 'react-router-dom'

import './RadiographsForm.scss'

export const RadiographsForm: FC = () => {
  const { caseId } = useParams()
  const { t } = useTranslation()
  const { getValues } = useFormContext<CaseFormFields>()
  const { uploadFile, deleteFile, fileToBase64 } = useCaseFileDetail(caseId!)

  const files = getValues('files')

  const handleUpload = async (formName: string, file?: File) => {
    const fileId = formName.split('.')[2] as CaseFileType

    if (file) {
      const fileBase64 = await fileToBase64(file)
      const request: CaseFileRequest = {
        identifier: fileId,
        originalName: file.name,
        file: fileBase64,
      }

      await uploadFile(request)
    } else {
      await deleteFile(fileId)
    }
  }

  return (
    <Card.Body className="RadiographsForm" size={'sm'}>
      <h3 className="form-subtitle">{t('cases.multimedia.radio')}</h3>
      <div className="FilesForm-list">
        {Object.keys(files.radiographs).map(identifier => (
          <div className="FilesForm-item" key={identifier}>
            <UploadControl<CaseFormFields>
              name={`files.radiographs.${identifier as RadioIdentifiers}.file`}
              label={t(`cases.multimedia.${identifier}`)}
              onChange={handleUpload}
              hiddenLabel
              identifier={identifier as RadioIdentifiers}
            />
          </div>
        ))}
      </div>
    </Card.Body>
  )
}
