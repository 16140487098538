import { ButtonAsync, Card, useConfirmContext } from '@app/components'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { showAdditionalAlignersAction } from '@app/store/slices'
import { useStatus } from '@app/hooks'
import { reloadCaseAction } from '@app/store/slices/case/caseSlice'
import { useParams } from 'react-router-dom'
import { CaseStatus } from '@app/services/cases/types'
import { AdditionalAlignersModal } from './additional-aligners/AdditionalAlignersModal'
import { RefinementModal } from './refinement/RefinementModal'

import './ActionsAccepted.scss'

interface ActionsAcceptedProps {
  nextPhaseAvailable: boolean
}

export const ActionsAccepted = ({
  nextPhaseAvailable,
}: ActionsAcceptedProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { caseId } = useParams()
  const { showAdditionalAligners, showRefinement } = useAppSelector(
    state => state.ui,
  )
  const {
    handleChangeStatus,
    state: { loaded },
  } = useStatus(caseId!)
  const confirm = useConfirmContext()

  const handleFinishCase = async () => {
    const { isConfirmed } = await confirm({
      title: t('cases.actions.finish'),
      content: <div>{t('cases.actions.finish-body')}</div>,
    })

    if (isConfirmed) {
      await handleChangeStatus(CaseStatus.Done)
      dispatch(reloadCaseAction(true))
    }
  }
  return (
    <>
      <Card className="ActionsAccepted">
        <Card.Body>
          <div className="ActionsAccepted-content">
            <div className="ActionsAccepted-title">
              {t('cases.actions.available-actions')}
            </div>
            <Button
              variant="primary"
              onClick={() => dispatch(showAdditionalAlignersAction(true))}
            >
              {t('cases.actions.additional-aligners')}
            </Button>
            <ButtonAsync
              className="btn btn-primary"
              isSubmitting={!loaded}
              onClick={handleFinishCase}
            >
              {t('cases.actions.finish')}
            </ButtonAsync>
          </div>
        </Card.Body>
      </Card>
      {showAdditionalAligners && (
        <AdditionalAlignersModal nextPhaseAvailable={nextPhaseAvailable} />
      )}
      {showRefinement && <RefinementModal />}
    </>
  )
}
