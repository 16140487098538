import { State } from '@app/core/types'
import { useToast } from '@app/hooks'
import {
  CaseFile,
  OtherIdentifiers,
  PhotoIdentifiers,
  RadioIdentifiers,
  STLIdentifiers,
} from '@app/services/files/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import filesService from '@app/services/files/files.service'

type FilesState = State<CaseFile[], 'multimedia'> & {
  error?: Error
}

const initialState: FilesState = {
  loaded: false,
  multimedia: null,
  error: undefined,
}

type UseCaseFilesType = {
  state: FilesState
  filteredImages: {
    photo: CaseFile[]
    radio: CaseFile[]
    other: CaseFile[]
    stl: CaseFile[]
  }
}

export const useCaseFiles = (caseId: string): UseCaseFilesType => {
  const [state, setState] = useState<FilesState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()

  const loadFiles = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const res = await filesService.getCaseFiles(id)
        setState(() => ({
          loaded: true,
          multimedia: res,
          error: undefined,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          multimedia: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!caseId) {
      return
    }
    loadFiles(caseId)
  }, [loadFiles, caseId])

  const isPhotoIdentifier = (
    identifier: string,
  ): identifier is PhotoIdentifiers => {
    return Object.values(PhotoIdentifiers).includes(
      identifier as PhotoIdentifiers,
    )
  }

  const isRadioIdentifier = (
    identifier: string,
  ): identifier is RadioIdentifiers => {
    return Object.values(RadioIdentifiers).includes(
      identifier as RadioIdentifiers,
    )
  }

  const isOtherIdentifier = (
    identifier: string,
  ): identifier is OtherIdentifiers => {
    return Object.values(OtherIdentifiers).includes(
      identifier as OtherIdentifiers,
    )
  }

  const isSTLIdentifier = (
    identifier: string,
  ): identifier is STLIdentifiers => {
    return Object.values(STLIdentifiers).includes(identifier as STLIdentifiers)
  }
  const filteredImages = useMemo(() => {
    const photo: CaseFile[] = []
    const radio: CaseFile[] = []
    const other: CaseFile[] = []
    const stl: CaseFile[] = []

    if (state.multimedia) {
      state.multimedia.forEach(item => {
        switch (true) {
          case isPhotoIdentifier(item.identifier):
            photo.push(item)
            break
          case isRadioIdentifier(item.identifier):
            radio.push(item)
            break
          case isOtherIdentifier(item.identifier):
            other.push(item)
            break
          case isSTLIdentifier(item.identifier):
            stl.push(item)
            break
          default:
            break
        }
      })
    }

    return { photo, radio, other, stl }
  }, [state.multimedia])

  return {
    state,
    filteredImages,
  }
}
