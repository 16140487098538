import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import { RequestStatus } from './types'

const getBaseUrl = (config: IConfigService, caseId: string) =>
  `${config.baseUrl}/product-cases/${caseId}/status`

const changeStatus =
  (config: IConfigService, http: IHttpClient) =>
  async (params: RequestStatus): Promise<void> => {
    const { caseId, status } = params
    try {
      await http.post<object, void>(`${getBaseUrl(config, caseId)}`, { status })
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  changeStatus: changeStatus(config, http),
})

export default factory(config, http)
