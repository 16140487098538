import { SearchAsyncControl } from '@app/components/search-control'
import casesService from '@app/services/cases/cases.service'
import { Case, CasesPaginationQuery } from '@app/services/cases/types'
import clsx from 'clsx'
import { FC, useCallback } from 'react'

interface SearchCasesProps {
  className?: string
}

export const SearchCases: FC<SearchCasesProps> = ({ className }) => {
  const fetchCases = useCallback(async (search: string) => {
    const { data: cases } = await casesService.getCases({
      search,
    } as CasesPaginationQuery)

    return cases?.map((data: Case) => ({
      label: `${data.id} - ${data.generalInfo.patient.name}`,
      value: data.id,
    }))
  }, [])

  return (
    <div className={clsx('SearchCases', className)}>
      <SearchAsyncControl loadOptions={fetchCases} />
    </div>
  )
}
