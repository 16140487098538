import React, { FC, useState } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { clsx } from 'clsx'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'
import { CiRead } from 'react-icons/ci'
import './InputControl.scss'

type InputControlType = 'text' | 'email' | 'tel' | 'password' | 'number'
type InputControlValuesType = 'string' | 'number'

type InputControlProps<T extends FieldValues> = {
  type: InputControlType
  value?: InputControlValuesType
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  className?: string
  inputRef?: React.Ref<HTMLInputElement>
} & FormControlWrapperProps<T>

export const InputControl: FC<InputControlProps<FieldValues>> = ({
  warning,
  name,
  required,
  hiddenLabel,
  id,
  label,
  slotLabel,
  placeholder,
  type,
  disabled,
  readonly,
  inputRef,
}: InputControlProps<FieldValues>): JSX.Element => {
  const methods = useFormContext<FieldValues>()
  const { control } = methods
  const prefixedId = id ?? `form-${name}`

  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="InputControl">
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormControlWrapper
            error={fieldState.error}
            warning={warning}
            name={field.name}
            slotLabel={slotLabel}
            hiddenLabel={hiddenLabel}
            label={label}
            id={prefixedId}
          >
            <input
              className={clsx('form-control', {
                'is-invalid': fieldState.error,
                'has-warning': warning,
              })}
              type={showPassword ? 'text' : type}
              placeholder={placeholder}
              id={prefixedId}
              disabled={disabled}
              value={field.value}
              name={field.name}
              readOnly={readonly}
              required={required}
              onChange={e => field.onChange(e.target.value)}
              onBlur={field.onBlur}
              ref={e => {
                field.ref(e)
                if (typeof inputRef === 'function') {
                  inputRef(e)
                } else if (inputRef && 'current' in inputRef) {
                  ;(
                    inputRef as React.MutableRefObject<HTMLInputElement | null>
                  ).current = e
                }
              }}
            />
            {type === 'password' && (
              <span onClick={toggleShowPassword} className="input-group-text">
                <CiRead className={clsx('', { active: showPassword })} />
              </span>
            )}
          </FormControlWrapper>
        )}
      />
    </div>
  )
}
