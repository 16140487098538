import { TreatmentPlan } from '@app/services/treatment-plans/types'
import { getDiscountPercentage } from '@app/utils'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CiRead, CiUnread } from 'react-icons/ci'

import './TreatmentAmount.scss'

interface TreatmentAmountProps {
  selectedPlan: TreatmentPlan
}
export const TreatmentAmount: FC<TreatmentAmountProps> = ({ selectedPlan }) => {
  const [showAmount, setShownAmount] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="TreatmentAmount">
      {showAmount && (
        <div className="TreatmentAmount-price">
          <span>
            {t('cases.amount.aligners', {
              amount: selectedPlan.pricing.total,
              discount: getDiscountPercentage(
                selectedPlan.pricing.total,
                selectedPlan.pricing.priceWithDiscount,
              ),
            })}
          </span>
          <span>
            {t('cases.amount.total', {
              amount: selectedPlan.pricing.priceWithDiscount,
            })}
          </span>
        </div>
      )}

      <button
        className="btn btn-secondary btn-sm"
        onClick={() => setShownAmount(prev => !prev)}
      >
        {showAmount ? <CiUnread className="me-0" /> : <CiRead />}
      </button>
    </div>
  )
}
