import { CaseArcadeType } from '@app/services/cases/types'

export const FIRST_TOP_TOOTH = '18'
export const LAST_TOP_TOOTH = '28'
export const FIRST_BOTTOM_TOOTH = '48'

export const isToothBelongsToArcade = (
  toothId: string,
  arcadeType: CaseArcadeType,
) => {
  if (!arcadeType || arcadeType === CaseArcadeType.Both) {
    return true
  }
  const isUpper = toothId <= LAST_TOP_TOOTH
  return arcadeType === CaseArcadeType.Upper ? isUpper : !isUpper
}
