export * from './cases/index'
export * from './dates/index'
export * from './download/index'
export * from './enums/index'
export * from './forms/index'
export * from './request/index'
export * from './url-sanitizer/index'
export * from './browser'

export const INIT_QUERY_PAGE = 1
export const DEBOUNCE_DELAY = 800

export function getRandomNumber({ min, max }: { min: number; max: number }) {
  return Math.floor(Math.random() * (max - min) + min)
}

export const Responsive = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
}

export function isBoolean(value: string | number | boolean | null) {
  return typeof value === 'boolean'
}

export const getDiscountPercentage = (
  originalPrice: number,
  discountedPrice: number,
) => {
  const discountAmount = originalPrice - discountedPrice
  const discountPercentage = (discountAmount / originalPrice) * 100

  return parseFloat(discountPercentage.toFixed(2))
}

export const cloneObjectDeep = (obj = {}) => JSON.parse(JSON.stringify(obj))
