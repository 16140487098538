import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { PaginatedResult, ResponseData } from '@app/core/types'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import { AcceptanceRequest, TreatmentPlan } from './types'

const getBaseUrl = (config: IConfigService, caseId: string) =>
  `${config.baseUrl}/product-cases/${caseId}/treatment-plans`

const getTreatmentPlans =
  (config: IConfigService, http: IHttpClient) =>
  async (caseId: string): Promise<PaginatedResult<TreatmentPlan>> => {
    try {
      return await http.get(`${getBaseUrl(config, caseId)}`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const getTreatmentPlan =
  (config: IConfigService, http: IHttpClient) =>
  async (caseId: string, treatmentId: string): Promise<TreatmentPlan> => {
    try {
      const { data } = await http.get<ResponseData<TreatmentPlan>>(
        `${getBaseUrl(config, caseId)}/${treatmentId}`,
      )

      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const changeTreatmentAcceptance =
  (config: IConfigService, http: IHttpClient) =>
  async (
    caseId: string,
    treatmentId: string,
    request: AcceptanceRequest,
  ): Promise<void> => {
    try {
      return await http.post(
        `${getBaseUrl(config, caseId)}/${treatmentId}/acceptance`,
        request,
      )
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getTreatments: getTreatmentPlans(config, http),
  getTreatment: getTreatmentPlan(config, http),
  changeTreatmentAcceptance: changeTreatmentAcceptance(config, http),
})

export default factory(config, http)
