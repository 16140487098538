import { Main, PageHeader, StateHandler, Table } from '@app/components'
import { Pagination } from '@app/components/pagination/Pagination'
import { CASES_PAGE_SIZE, useCases } from '@app/hooks/cases/useCases'
import { useTranslation } from 'react-i18next'
import { columnConfig, getTableColumns, getTableRows } from './utils'
import {
  FiltersProvider,
  useFiltersContext,
} from '@app/components/filters/context'
import { SearchControl } from '@app/components/search-control/search-control/SearchControl'
import { useMemo, useState, useEffect } from 'react'
import { FilterButton, FilterPanel } from '@app/components/filters'
import { CasesFiltersForm } from './shared/filters-form/CasesFiltersForm'
import { useClinics } from '@app/hooks/clinics/useClinics'
import { useBillingAddress } from '@app/hooks'
import { CasesPaginationQuery } from '@app/services/cases/types'
import { TableRowType } from '@components/table/types.ts'

export const CasesPage = () => {
  const { t } = useTranslation()
  return (
    <FiltersProvider>
      <Main ariaLabelledby="cases-page" className="CasesPage">
        <PageHeader id="cases-page">{t('cases.title')}</PageHeader>
        <CasesContent />
      </Main>
    </FiltersProvider>
  )
}

export const CasesContent = () => {
  const filterPanelId = 'cases-filter-panel'
  const { t } = useTranslation()
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const { filter } = useFiltersContext()

  const {
    state: { loaded: loadedCases, error: errorCases, cases, ...query },
    setPage,
    debouncedSearch,
    setSortBy,
  } = useCases({ filter } as CasesPaginationQuery)
  const {
    state: { loaded: loadedClinics, error: errorClinics, clinics },
  } = useClinics()
  const {
    state: {
      loaded: loadedBillingAddress,
      error: errorBillingAddress,
      billingAddressList,
    },
  } = useBillingAddress()

  const loadedFilters = useMemo(
    () => loadedClinics && loadedBillingAddress,
    [loadedBillingAddress, loadedClinics],
  )
  const errorFilters = useMemo(
    () => errorClinics && errorBillingAddress,
    [errorClinics, errorBillingAddress],
  )

  const filterButton = (
    <FilterButton
      id={filterPanelId}
      onClick={setOpenFilters}
      open={openFilters}
    />
  )

  const [rows, setRows] = useState<TableRowType[]>([])

  useEffect(() => {
    const fetchRows = async () => {
      const rowData = await getTableRows(cases ?? [], t)
      setRows(rowData)
    }

    if (cases) {
      fetchRows()
    }
  }, [cases, t])

  return (
    <>
      <SearchControl
        onSearch={search => debouncedSearch(search)}
        filterButton={filterButton}
      />

      <FilterPanel open={openFilters} id={filterPanelId}>
        <StateHandler loaded={loadedFilters} error={errorFilters}>
          <CasesFiltersForm
            clinics={clinics ?? []}
            billingAddress={billingAddressList || []}
          />
        </StateHandler>
      </FilterPanel>

      <div>
        <Table
          columns={getTableColumns(t)}
          rows={rows}
          config={{
            columns: columnConfig,
            numRows: CASES_PAGE_SIZE,
          }}
          state={{ loaded: loadedCases, error: errorCases }}
          onSortBy={setSortBy}
        />
      </div>
      <Pagination
        loading={!loadedCases}
        currentPage={query.page}
        totalPages={query.lastPage}
        totalElements={query.total || 0}
        onChange={pageIndex => setPage(pageIndex)}
      />
    </>
  )
}
