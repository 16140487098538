import filesService from '@app/services/files/files.service'

type UseFileType = {
  fileToBase64: (file: File | string) => Promise<string>
}

export const useFile = (): UseFileType => {
  const fileToBase64 = async (file: File | string) => {
    if (!file || !(file instanceof File)) {
      return file
    }

    return await filesService.fileToBase64(file)
  }

  return {
    fileToBase64,
  }
}
