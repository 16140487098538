import { DetailUser } from '@app/services/auth/types'
import configService from '@app/services/config/config.service'
import { LanguagesApp } from '@i18n/types.ts'

export const getUserLanguage = (userDetail: DetailUser | null): string => {
  const language =
    userDetail?.province?.country?.code ??
    userDetail?.locale ??
    configService.language ??
    LanguagesApp.ES

  return language.toLowerCase()
}
