import { FC, PropsWithChildren } from 'react'

import './PageHeader.scss'

export const PageHeader: FC<PropsWithChildren & { id: string }> = ({
  children,
  id,
}) => {
  return (
    <section className="PageHeader">
      <h1 id={id} className="PageHeader-title">
        {children}
      </h1>
    </section>
  )
}
