import { object, mixed, AnyObjectSchema } from 'yup'
import { TFunction } from 'i18next'
import { CaseType } from '@app/services/cases/types'

export function typeValidationSchema(t: TFunction): AnyObjectSchema {
  const caseTypes = Object.values(CaseType) as CaseType[]

  return object().shape({
    type: mixed<CaseType>().oneOf(caseTypes).required(t('validation.required')),
  })
}
