import { useAuth } from '@app/hooks'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { showChangePassAction } from '@app/store/slices'
import { useState } from 'react'
import Offcanvas from 'react-bootstrap/esm/Offcanvas'
import Nav from 'react-bootstrap/esm/Nav'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useMatch } from 'react-router-dom'
import Image from 'react-bootstrap/esm/Image'
import appAvatar from '@images/placeholder-avatar.png'
import { ChangePass, SelectLanguage, SidebarNav } from '@app/components'
import { CiLock, CiLogout, CiUser } from 'react-icons/ci'
import clsx from 'clsx'

import './ProfileMenu.scss'

interface ProfileMenuProps {
  placement: 'start' | 'end'
  name: string
}

export const ProfileMenu = ({ ...props }: ProfileMenuProps) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.user)
  const { showChangePass } = useAppSelector(state => state.ui)
  const location = useLocation()
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const { logout } = useAuth()
  const active = !!useMatch({ path: '/profile', end: false })

  const handleChangePass = () => {
    setShow(false)
    dispatch(showChangePassAction(true))
  }

  return (
    <div className="ProfileMenu">
      <div className="ProfileMenu-contentDetail">
        <div className="ProfileMenu-detail d-none d-md-flex d-lg-none">
          {`${user?.firstName} ${user?.lastNames}`}
          {user?.roles.includes('ROLE_USER') && (
            <small className="fs-sm">{t('menu.dentist')}</small>
          )}
        </div>
        <Image
          className={clsx('ProfileMenu-avatar', {
            'p-0': user?.avatar,
          })}
          src={user && user.avatar ? user.avatar : appAvatar}
          roundedCircle
          onClick={() => setShow(true)}
        />
      </div>

      <Offcanvas show={show} onHide={() => setShow(false)} {...props}>
        <div className="ProfileMenu-wrapper">
          <Offcanvas.Header closeButton closeVariant="white"></Offcanvas.Header>
          <Offcanvas.Body className="ProfileMenu-bodyCanvas">
            <Image
              className={clsx('ProfileMenu-imageCanvas', {
                'p-0': user?.avatar,
              })}
              src={user && user.avatar ? user.avatar : appAvatar}
              roundedCircle
            />

            <div className="ProfileMenu-detailCanvas">
              {`${user?.firstName} ${user?.lastNames}`}
              {user?.roles.includes('ROLE_USER') && (
                <small className="fs-sm">{t('menu.dentist')}</small>
              )}
            </div>

            <SelectLanguage />

            <SidebarNav>
              <SidebarNav.Item>
                <Nav.Link as={Link} to="/profile" active={active}>
                  <CiUser /> {t('menu.profile')}
                </Nav.Link>
              </SidebarNav.Item>
              <SidebarNav.Item>
                <Nav.Link
                  as={Link}
                  to={location.pathname}
                  onClick={handleChangePass}
                >
                  <CiLock />
                  {t('common.change-pass')}
                </Nav.Link>
              </SidebarNav.Item>
              <SidebarNav.Item>
                <Nav.Link as={Link} to="" onClick={logout}>
                  <CiLogout /> {t('menu.logout')}
                </Nav.Link>
              </SidebarNav.Item>
            </SidebarNav>
          </Offcanvas.Body>
        </div>
      </Offcanvas>

      {showChangePass && <ChangePass />}
    </div>
  )
}
