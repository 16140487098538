import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { PaginatedResult } from '@app/core/types'
import { mapFiltersToRequest } from '@app/utils'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import {
  ExtendedPaginatedResult,
  Invoice,
  InvoiceBase,
  InvoiceBasePaginationQuery,
  InvoicePaginationQuery,
} from './types'

const getBaseUrl = (config: IConfigService) => `${config.baseUrl}/invoices`

const getInvoicesCase =
  (config: IConfigService, http: IHttpClient) =>
  async (
    caseId: string,
    query: InvoiceBasePaginationQuery,
  ): Promise<PaginatedResult<InvoiceBase>> => {
    const params = mapFiltersToRequest({ ...query, filter: { caseId } })
    try {
      return await http.get(`${getBaseUrl(config)}?${params}`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const getInvoices =
  (config: IConfigService, http: IHttpClient) =>
  async (
    query: InvoicePaginationQuery,
  ): Promise<ExtendedPaginatedResult<Invoice>> => {
    let params = mapFiltersToRequest(query)
    params += '&aggregate=total,totalMonthlyTrend'
    try {
      return await http.get(`${getBaseUrl(config)}?${params}`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getInvoicesCase: getInvoicesCase(config, http),
  getInvoices: getInvoices(config, http),
})

export default factory(config, http)
