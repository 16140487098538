import { clsx } from 'clsx'
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'
import { useEffect, useRef, useState } from 'react'
import { MediaPreview } from '../../media-preview/MediaPreview'

type UploadControlBaseProps<T> = {
  disabled?: boolean
  readonly?: boolean
  className?: string
  onChange?: (name: Path<T>, file?: File) => Promise<void>
  identifier?: string
}
type UploadControlProps<T extends FieldValues> = UploadControlBaseProps<T> &
  FormControlWrapperProps<T>

export const UploadControl = function <T extends FieldValues>({
  identifier,
  warning,
  name,
  required,
  hiddenLabel,
  id,
  label,
  slotLabel,
  disabled,
  readonly,
  onChange,
}: UploadControlProps<T>): JSX.Element {
  const [imagePreview, setImagePreview] = useState<string>('')
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { control, setValue, watch } = useFormContext<T>()

  const prefixedId = id ?? `form-${name}`

  const handleChangeFile = () => {
    if (!inputFileRef.current) {
      return
    }

    const fileList: FileList = inputFileRef.current.files!
    const files = Array.from(fileList)
    const selectedFile = files[0]

    if (selectedFile) {
      previewImage(selectedFile)
      setValue(name, selectedFile as PathValue<T, Path<T>>)
      onChange && onChange(name, selectedFile)
    }
  }

  const handleClickUpload = () => {
    inputFileRef?.current?.click()
  }

  const handleClear = (name: Path<T>) => {
    setValue(name, '' as PathValue<T, Path<T>>)
    setImagePreview('')
    onChange && onChange(name, undefined)
  }

  const previewImage = (file: File) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = readerEvent => {
      const previewImage = readerEvent?.target?.result as string
      setImagePreview(previewImage)
    }
  }

  useEffect(() => {
    const inputValue = watch(name)
    if (inputValue) {
      setImagePreview(inputValue)
    }
  }, [name, watch])

  return (
    <div className="UploadControl">
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormControlWrapper
            error={fieldState.error}
            warning={warning}
            name={field.name}
            slotLabel={slotLabel}
            hiddenLabel={hiddenLabel}
            label={label}
            id={prefixedId}
          >
            <div>
              <input
                className={clsx('form-control d-none', {
                  'is-invalid': fieldState.error,
                  'has-warning': warning,
                })}
                ref={inputFileRef}
                type="file"
                accept="image/*,application/zip,.dcm,capture=camera"
                id={prefixedId}
                disabled={disabled}
                name={field.name}
                readOnly={readonly}
                required={required}
                onChange={handleChangeFile}
                onBlur={field.onBlur}
                tabIndex={-1}
              />
            </div>

            <MediaPreview
              url={imagePreview}
              title={label as string}
              id={prefixedId}
              handleClear={() => handleClear(field.name)}
              handleUpload={handleClickUpload}
              identifier={identifier}
            />
          </FormControlWrapper>
        )}
      />
    </div>
  )
}
