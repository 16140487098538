import { PaginationFiltersQuery } from '@app/core/types'

export function mapFiltersToRequest<T>(
  query: PaginationFiltersQuery<T>,
  parentKey = '',
) {
  if (!query) {
    return
  }
  let queryString = ''

  for (const key in query) {
    const params = query as unknown as { [key: string]: string }
    const value = params[key]

    if (Object.prototype.hasOwnProperty.call(params, key) && value) {
      const paramName = parentKey ? `${parentKey}[${key}]` : key
      if (typeof value === 'object') {
        queryString += mapFiltersToRequest(value, paramName)
      } else {
        queryString += `${encodeURIComponent(paramName)}=${encodeURIComponent(
          value,
        )}&`
      }
    }
  }

  return updateQueryParamKey(queryString, 'search', 'filter[q]')
}

function updateQueryParamKey(
  queryString: string,
  oldKey: string,
  newKey: string,
) {
  const regex = new RegExp(`(${oldKey}=)([^&]*)`)

  return queryString.replace(regex, `${encodeURIComponent(newKey)}=$2`)
}

export function removeNullValuesFromRequest<T extends object>(obj: T) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) => value !== null && value !== undefined,
    ),
  ) as T
}
