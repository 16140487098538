import { BreadcrumbDynamic, PageHeader } from '@app/components'
import { ItemBreadCrumb } from '@app/components/breadcrumb-dynamic/types'
import { Case } from '@app/services/cases/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface CaseBreadcrumbProps {
  caseData?: Case
}
export const CaseBreadcrumb: FC<CaseBreadcrumbProps> = ({ caseData }) => {
  const { t } = useTranslation()

  const breadcrumbItems: ItemBreadCrumb[] = [
    { label: t('menu.cases'), href: '/patients' },
    { label: caseData?.generalInfo?.patient.name || t('menu.new-patient') },
  ]

  return (
    <PageHeader id="patients-form-page">
      <BreadcrumbDynamic items={breadcrumbItems} />
      {caseData?.generalInfo?.patient.name}{' '}
      <span className="text-gray">{caseData?.id}</span>
    </PageHeader>
  )
}
