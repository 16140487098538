import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'
import ReactMultiCarousel, { CarouselProps } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './Carousel.scss'

type CarouselCustomProps = {
  className?: string
} & PropsWithChildren

const Carousel: FC<CarouselProps & CarouselCustomProps> = ({
  className = '',
  children,
  ...props
}) => {
  return (
    <ReactMultiCarousel {...props} containerClass={clsx('Carousel', className)}>
      {children}
    </ReactMultiCarousel>
  )
}

export type { ResponsiveType as CarouselResponsiveType } from 'react-multi-carousel'
export { Carousel }
