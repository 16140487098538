import { State } from '@app/core/types'
import { useToast } from '@app/hooks'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import phaseService from '@app/services/phase/phase.service'

type PhaseState = State<null, ''> & {
  error?: Error
}

const initialState: PhaseState = {
  loaded: true,
  error: undefined,
}

export const usePhase = () => {
  const [state, setState] = useState<PhaseState>(initialState)
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()

  const handleNextPhase = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        await phaseService.nextPhase(id)
        setState(() => ({
          loaded: true,
          error: undefined,
        }))
        toastSuccess(t('cases.actions.success-status'))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError, toastSuccess],
  )

  return {
    state,
    handleNextPhase,
  }
}
