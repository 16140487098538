import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { ResponseData } from '@app/core/types'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import { RefinementsBase } from './types'

const getBaseUrl = (config: IConfigService, caseId: string) =>
  `${config.baseUrl}/product-cases/${caseId}/refinements`

const getListRefinements =
  (config: IConfigService, http: IHttpClient) =>
  async (productCaseId: string): Promise<RefinementsBase[]> => {
    try {
      const { data } = await http.get<ResponseData<RefinementsBase[]>>(
        `${getBaseUrl(config, productCaseId)}`,
      )

      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const sendRefinements =
  (config: IConfigService, http: IHttpClient) =>
  async (refinements: FormData, caseId: string): Promise<void> => {
    return await http.post(`${getBaseUrl(config, caseId)}`, refinements)
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getListRefinements: getListRefinements(config, http),
  sendRefinements: sendRefinements(config, http),
})

export default factory(config, http)
