import clsx from 'clsx'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import './Loader.scss'

type LoaderProps = {
  className?: string
  size?: 'md' | 'sm'
}

export const Loader: FC<LoaderProps> = ({ className = '', size = 'md' }) => {
  const { t } = useTranslation('loaders', { useSuspense: false })
  return (
    <div className={clsx('Loader', className)}>
      <div
        className={`spinner-border text-primary position-relative spinner-border-${size}`}
        role="status"
      >
        <span className="visually-hidden-focusable">{t('common.loading')}</span>
      </div>
    </div>
  )
}
