import clsx from 'clsx'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import './EmptyList.scss'
interface EmptyListProps {
  title: string
  className?: string
}
export const EmptyList: FC<EmptyListProps> = ({ title, className }) => {
  const { t } = useTranslation()
  return <div className={clsx('EmptyList', className)}>{t(title)}</div>
}
