import { FC, ReactNode } from 'react'
import clsx from 'clsx'

import { CiWarning } from 'react-icons/ci'

export interface ErrorPageProps {
  children: ReactNode
  className?: string
}

export interface TextProps {
  text: string
}

const ErrorContainer: FC<ErrorPageProps> = ({ children, className = '' }) => {
  return (
    <div className={clsx('ErrorPage my-4 text-center', className)}>
      <div className="d-flex flex-column">{children}</div>
    </div>
  )
}

export const ErrorIcon: FC = () => {
  return (
    <div className="text-warning fs-1">
      <CiWarning />
    </div>
  )
}

export const ErrorTitle: FC<TextProps> = ({ text }) => {
  return <h3 className="lead fw-semibold">{text}</h3>
}

export const ErrorBody: FC<TextProps> = ({ text }) => {
  return <p>{text}</p>
}

export const ErrorActions: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return <div className={className}>{children}</div>
}

export const ErrorPage = Object.assign(ErrorContainer, {
  Icon: ErrorIcon,
  Title: ErrorTitle,
  Body: ErrorBody,
  Actions: ErrorActions,
})
