import { object, string, array, mixed, AnyObjectSchema } from 'yup'
import { TFunction } from 'i18next'
import { CaseArcadeType, GenderType } from '@app/services/cases/types'

export function generalInfoValidationSchema(t: TFunction): AnyObjectSchema {
  const genderTypes = Object.values(GenderType) as GenderType[]
  const caseArcadeTypes = Object.values(CaseArcadeType) as CaseArcadeType[]

  return object().shape({
    general: object().shape({
      firstName: string().required(t('validation.required')),
      lastName: string().required(t('validation.required')),
      gender: mixed<GenderType>()
        .oneOf(genderTypes)
        .required(t('validation.required')),
      birthdate: string().required(t('validation.required')),
      archTreat: mixed<CaseArcadeType>()
        .oneOf(caseArcadeTypes)
        .required(t('validation.required')),
      teethNotToMove: array()
        .of(
          object().shape({
            number: string(),
          }),
        )
        .optional()
        .nullable(),
      comments: string().nullable(),
      clinicId: string().required(t('validation.required')),
      billingAddressId: string().required(t('validation.required')),
    }),
  })
}
