import { ErrorPage } from '@app/components'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const NotFound: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ErrorPage className="NotFound">
      <>
        <ErrorPage.Icon />

        <ErrorPage.Title text={t('page-error.not-found.title')} />
        <ErrorPage.Body text={t('page-error.not-found.body')} />

        <ErrorPage.Actions>
          <button
            type="button"
            onClick={() => navigate('/dashboard')}
            className="btn btn-link"
          >
            {t('page-error.not-found.button')}
          </button>
        </ErrorPage.Actions>
      </>
    </ErrorPage>
  )
}
