import { isBoolean } from '@app/utils'
import { TFunction } from 'i18next'
import { DetailCellProps } from './Detail'
import ReactHtmlParser from 'html-react-parser'

export const parsedCellValue = (
  value: DetailCellProps['value'],
  t: TFunction,
) => {
  if (isBoolean(value!)) {
    return t(`common.${value}`)
  } else if (!value) {
    return ' - '
  } else {
    return ReactHtmlParser(value.toString(), {
      trim: true,
    })
  }
}
