import { Form } from 'react-bootstrap'
import { LanguagesApp } from '../../../../i18n/types'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import { useProfile } from '@app/hooks'

export const SelectForm = () => {
  const { t, i18n } = useTranslation()
  const { changeLocale, userLanguage } = useProfile()

  const changeLanguage = async (event: ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value
    i18n.changeLanguage(language)
    changeLocale(language)
  }
  return (
    <Form.Select onChange={changeLanguage} defaultValue={userLanguage}>
      <option value={LanguagesApp.EN}>{t('languages.en')}</option>
      <option value={LanguagesApp.ES}>{t('languages.es')}</option>
      <option value={LanguagesApp.FR}>{t('languages.fr')}</option>
      <option value={LanguagesApp.IT}>{t('languages.it')}</option>
      <option value={LanguagesApp.DE}>{t('languages.de')}</option>
      <option value={LanguagesApp.PT}>{t('languages.pt')}</option>
    </Form.Select>
  )
}
