import { configureStore } from '@reduxjs/toolkit'
import uiReducer from './slices/ui/uiSlice'
import userReducer from './slices/user/userSlice'
import caseReducer from './slices/case/caseSlice'
import authLocalStorageReducer from './slices/authLocalStorage/authLocalStorageSlice'
import { countriesStore } from './slices/countries/countriesSlice'
import { localStorageMiddleware } from './middlewares/localstorage-middleware'

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    case: caseReducer,
    user: userReducer,
    authLocalStorage: authLocalStorageReducer,
    [countriesStore.name]: countriesStore.reducer,
  },
  middleware: getDefaultMiddleware =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDefaultMiddleware().concat(localStorageMiddleware) as any,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
