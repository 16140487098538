import { getBrowserLanguage } from '@app/utils'

export interface IConfigService {
  baseUrl: string
  viewerUrl: string
  token: string | null
  language: string | null
}

class ConfigService implements IConfigService {
  get baseUrl(): string {
    return import.meta.env.VITE_BASE_URL ?? ''
  }

  get token(): string | null {
    const accessToken = localStorage.getItem('user-token')

    return accessToken ? accessToken.replace(/^"|"$/g, '') : null
  }

  get language(): string | null {
    const storedUserLanguage = localStorage.getItem('language')

    if (storedUserLanguage) {
      return storedUserLanguage
    }
    return getBrowserLanguage()
  }

  get viewerUrl(): string {
    return import.meta.env.VITE_VIEWER_URL ?? ''
  }
}

export default new ConfigService()
