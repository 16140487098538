import { object, string, boolean, AnyObjectSchema, mixed } from 'yup'
import { TFunction } from 'i18next'
import { validateTimeInterval } from '@app/utils'

export function buildValidationSchema(t: TFunction): AnyObjectSchema {
  return object().shape({
    name: string().required(t('validation.required')),
    address: string().required(t('validation.required')),
    email: string()
      .email(t('validation.email'))
      .required(t('validation.required')),
    phone: string()
      .max(11, ({ label, max }) => t('validation.maxLength', { label, max }))
      .required(t('validation.required')),

    logo: mixed(),
    addressExtra: string(),
    addressNumber: string().required(t('validation.required')),
    postalCode: string()
      .max(5, ({ label, max }) => t('validation.maxLength', { label, max }))
      .required(t('validation.required')),
    city: string().required(t('validation.required')),
    province: string().required(t('validation.required')),
    country: string().required(t('validation.required')),
    openingHours: object().shape({
      days: object().shape({
        monday: boolean(),
        tuesday: boolean(),
        wednesday: boolean(),
        thursday: boolean(),
        friday: boolean(),
        saturday: boolean(),
        sunday: boolean(),
      }),
      startTimeMorning: string(),
      endTimeMorning: string()
        .when('startTimeMorning', {
          is: (v: string) => !!v,
          then: s => s.required(t('validation.required')),
          otherwise: s => s.nullable(),
        })
        .test(
          'invalidTimePeriod',
          () =>
            t('validation.greater-than', {
              other: t('clinics.morning-opening'),
            }),
          (endHour, global) => {
            return validateTimeInterval(global.parent.startTimeMorning, endHour)
          },
        ),
      startTimeEvening: string(),
      endTimeEvening: string()
        .when('startTimeEvening', {
          is: (v: string) => !!v,
          then: s => s.required(t('validation.required')),
          otherwise: s => s.nullable(),
        })
        .test(
          'invalidTimePeriod',
          () =>
            t('validation.greater-than', {
              other: t('clinics.afternoon-opening'),
            }),
          (endHour, global) => {
            return validateTimeInterval(global.parent.startTimeEvening, endHour)
          },
        ),
    }),
  })
}
