import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

import './SectionSubtitle.scss'

export const SectionSubtitle: FC<
  PropsWithChildren & { className?: string }
> = ({ children, className }) => {
  return <h3 className={clsx('SectionSubtitle', className)}>{children}</h3>
}
