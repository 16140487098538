import { StatePagination } from '@app/core/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import invoicesService from '@app/services/invoices/invoices.service'
import { useQueryFiltersPagination } from '../query-filters-pagination/useQueryFiltersPagination'
import { INIT_QUERY_PAGE } from '@app/utils'
import {
  Aggregates,
  Invoice,
  InvoiceBasePaginationQuery,
  InvoicePaginationQuery,
} from '@app/services/invoices/types'

export const INVOICES_PAGE_SIZE = 10

type InvoicesState = StatePagination<Invoice[], 'invoices'> & {
  aggregates: Aggregates | null
}

const initialState: InvoicesState = {
  loaded: false,
  invoices: null,
  aggregates: null,
  error: undefined,
  lastPage: 0,
  page: INIT_QUERY_PAGE,
  perPage: INVOICES_PAGE_SIZE,
}

const initialQuery: InvoicePaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: INVOICES_PAGE_SIZE,
  filter: {},
}

type UseInvoicesType = {
  state: InvoicesState
  setPage: (page: number) => void
  setSortBy: (querySort: string) => void
  debouncedSearch: (term: string) => void
}

export const useInvoices = (
  customQuery?: InvoicePaginationQuery,
): UseInvoicesType => {
  const [state, setState] = useState<InvoicesState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()
  const initialQueryFilter = useMemo(
    () => ({
      ...initialQuery,
      ...customQuery,
    }),
    [customQuery],
  )

  const { query, debouncedSearch, setPage, setSortBy } =
    useQueryFiltersPagination({
      initialQuery: initialQueryFilter,
      filter: customQuery?.filter,
      onSearch: useCallback(() => setState(initialState), []),
    })

  const loadInvoices = useCallback(
    async (queryBillingAddress: InvoiceBasePaginationQuery) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const {
          data: invoices,
          meta,
          aggregates,
        } = await invoicesService.getInvoices(queryBillingAddress)
        setState(() => ({
          loaded: true,
          invoices,
          aggregates,
          ...meta,
          error: undefined,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          invoices: null,
          aggregates: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    loadInvoices(query)
  }, [loadInvoices, query])

  return {
    state,
    setPage,
    setSortBy,
    debouncedSearch,
  }
}
