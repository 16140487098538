import clsx from 'clsx'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'

import './Pagination.scss'
import { INIT_QUERY_PAGE } from '@app/utils'

export const NUM_PAGES_DISPLAY = 3 // num of pages to display from current one (prev and next)

export type PaginationProps = {
  currentPage: number
  totalPages: number
  totalElements: number
  onChange: (page: number) => void
  loading: boolean
  className?: string
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  totalElements,
  onChange,
  className,
  loading,
}) => {
  const { t } = useTranslation()

  if (totalElements === 0) {
    return null
  }

  const hasPrevPage = currentPage > INIT_QUERY_PAGE
  const hasNextPage = currentPage < totalPages - 1

  const prevPageFn = () => onChange(currentPage - 1)
  const nextPageFn = () => onChange(currentPage + 1)

  const minPageIndex = hasPrevPage
    ? Math.max(currentPage - NUM_PAGES_DISPLAY, INIT_QUERY_PAGE)
    : INIT_QUERY_PAGE
  const maxPageIndex = hasNextPage
    ? Math.min(currentPage + NUM_PAGES_DISPLAY, totalPages - 1)
    : currentPage

  const prevPages = new Array(currentPage - minPageIndex)
    .fill(undefined)
    .map((_, k) => currentPage - 1 - k)
    .reverse()
  const nextPages = new Array(maxPageIndex - currentPage)
    .fill(undefined)
    .map((_, k) => currentPage + 1 + k)

  const displayLastPage =
    totalPages > INIT_QUERY_PAGE && currentPage < totalPages
  const showDots =
    displayLastPage && nextPages[nextPages.length - 1] !== totalPages - 1

  return (
    <nav
      className={clsx('Pagination', className)}
      aria-label={t('pagination.section')}
      data-testid="pagination"
    >
      <ul className="pagination pagination-sm mb-0">
        <li className="page-item">
          <button
            data-testid="pagination-prev"
            className={clsx('page-link Pagination-prev', {
              disabled: loading || !hasPrevPage,
            })}
            onClick={prevPageFn}
            aria-label={t('pagination.prevPage')}
          >
            <BsChevronLeft />
          </button>
        </li>

        {prevPages.map(p => (
          <li className={clsx('page-item', { disabled: loading })} key={p}>
            <button
              data-testid="page-link-prev"
              className="page-link"
              onClick={() => onChange(p)}
            >
              {p}
            </button>
          </li>
        ))}

        <li className="page-item">
          <button
            className="page-link active"
            aria-label={t('pagination.pageNumber', { page: currentPage })}
            aria-current="true"
            disabled
          >
            {currentPage}
          </button>
        </li>

        {nextPages.map(p => (
          <li className={clsx('page-item', { disabled: loading })} key={p}>
            <button
              data-testid="page-link-next"
              className="page-link"
              aria-label={t('pagination.pageNumber', { page: p })}
              onClick={() => onChange(p)}
            >
              {p}
            </button>
          </li>
        ))}

        {displayLastPage && (
          <>
            {showDots && (
              <li className="page-item d-flex align-items-center">
                <span className="Pagination-dots">...</span>
              </li>
            )}
            <li className={clsx('page-item', { disabled: loading })}>
              <button
                data-testid="page-link-last"
                className="page-link"
                aria-label={t('pagination.pageNumber', { page: totalPages })}
                onClick={() => onChange(totalPages)}
              >
                {totalPages}
              </button>
            </li>
          </>
        )}

        <li className="page-item">
          <button
            data-testid="pagination-next"
            className={clsx('page-link Pagination-next ms-3', {
              disabled: loading || !hasNextPage,
            })}
            onClick={nextPageFn}
            aria-label={t('pagination.nextPage')}
          >
            <BsChevronRight />
          </button>
        </li>
      </ul>
    </nav>
  )
}
