import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

import './SectionTitle.scss'

export const SectionTitle: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return <h2 className={clsx('SectionTitle', className)}>{children}</h2>
}
