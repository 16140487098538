import {
  CaseFileType,
  CaseFile,
  PhotoIdentifiers,
  VariationNames,
} from '@app/services/files/types'
import avatarPlaceholder from '@images/placeholder-avatar.png'

export const getFrontalFilePhoto = (
  files: CaseFile[] = [],
  preferredVariation?: VariationNames,
): string => {
  const frontalImage = files.find(
    file => file.identifier === PhotoIdentifiers.PhotographsFrontal,
  )

  if (frontalImage) {
    const { variants: variations } = frontalImage

    if (preferredVariation) {
      const preferred = variations?.find(
        variation => variation.name === preferredVariation,
      )
      if (preferred) {
        return preferred.url
      }
    }

    if (
      (!preferredVariation ||
        preferredVariation !== VariationNames.Miniature) &&
      variations
    ) {
      const miniature = variations.find(
        variation => variation.name === VariationNames.Miniature,
      )
      if (miniature) {
        return miniature.url
      }
    }

    if (
      (!preferredVariation ||
        preferredVariation !== VariationNames.Thumbnail) &&
      variations
    ) {
      const thumbnail = variations.find(
        variation => variation.name === VariationNames.Thumbnail,
      )
      if (thumbnail) {
        return thumbnail.url
      }
    }

    if (frontalImage.url) {
      return frontalImage.url
    }
  }

  return avatarPlaceholder
}

export const getFileById = (files: CaseFile[], file: CaseFileType) => {
  return files.find(f => f.identifier === file)
}
