import { Route, Routes } from 'react-router-dom'
import { BillingAddressPage } from './BillingAddressPage'
import { BillingAddressDetailPage } from './detail/BillingAddressDetailPage'
import { NotFound } from '../errors'
import { BillingAddressNewPage } from './new/BillingAddressNewPage'
import { BillingAddressEditPage } from './edit/BillingAddressEditPage'

export function BillingAddressRoutes() {
  return (
    <Routes>
      <Route index element={<BillingAddressPage />} />
      <Route path=":billingAddressId" element={<BillingAddressDetailPage />} />
      <Route
        path=":billingAddressId/edit"
        element={<BillingAddressEditPage />}
      />
      <Route path="/new" element={<BillingAddressNewPage />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
