import { CaseStatus } from '@app/services/cases/types'
import clsx from 'clsx'

import './StatusBadge.scss'
import { useTranslation } from 'react-i18next'
import { getEnumKeyByValue } from '@app/utils'

interface StatusBadgeProps {
  status: CaseStatus
  outline?: boolean
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  outline = false,
}) => {
  const { t } = useTranslation()

  const badgeClass = clsx(
    'StatusBadge rounded-pill badge',
    `StatusBadge-${status}`,
    `StatusBadge-${status}${outline && '--outline'}`,
  )

  return (
    <span className={badgeClass}>
      {t(
        `cases.status-types.${getEnumKeyByValue(CaseStatus, status)?.toLowerCase()}`,
      )}
    </span>
  )
}
