import { AnyObjectSchema, object } from 'yup'
import { TFunction } from 'i18next'
import { generalInfoValidationSchema } from '../../shared/form-steps/2-general-info/validation'
import { specificInfoValidationSchema } from '../../shared/form-steps/3-specific-info/validation'
import { measuresValidationSchema } from '../../shared/form-steps/6-measures/validation'
import { CaseStep } from '../../shared/form-steps/types'
import { CaseType } from '@app/services/cases/types'

export function buildValidationSchema(
  t: TFunction,
  currentStep: CaseStep,
  caseType: CaseType,
): AnyObjectSchema {
  const generalInfoSchema = generalInfoValidationSchema(t)
  const specificInfoSchema = specificInfoValidationSchema(t)
  const measuresSchema = measuresValidationSchema(t, caseType)

  const validationSchema: Record<CaseStep, AnyObjectSchema> = {
    [CaseStep.Type]: object(),
    [CaseStep.General]: generalInfoSchema,
    [CaseStep.Specific]: object(),
    [CaseStep.Files]: object(),
    [CaseStep.Radiographs]: object(),
    [CaseStep.Measures]: generalInfoSchema.concat(measuresSchema),
  }

  if (caseType !== CaseType.SecretRetainer) {
    validationSchema[CaseStep.Specific] =
      generalInfoSchema.concat(specificInfoSchema)
    validationSchema[CaseStep.Files] =
      generalInfoSchema.concat(specificInfoSchema)
    validationSchema[CaseStep.Measures] = generalInfoSchema
      .concat(specificInfoSchema)
      .concat(measuresSchema)
  }

  return validationSchema[currentStep]
}
