import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { PostPass } from '@app/services/profile/types'
import profileService from '@app/services/profile/profile.service'
import { DetailUser } from '@app/services/auth/types'
import {
  hideLoader,
  setUserLocation,
  showChangePassAction,
  showLoader,
} from '@app/store/slices'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { getUserLanguage } from '@app/utils/locale/languageUtils'

type UseProfileType = {
  changePass: (params: PostPass) => void
  changeLocale: (lang: string) => Promise<void>
  userDetail: DetailUser | null
  isLoaded: boolean
  userLanguage: string
}

export const useProfile = (): UseProfileType => {
  const { user, isLoaded } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()
  const userDetail = user

  const changePass = async (params: PostPass) => {
    try {
      await profileService.changePassword(params)
      toastSuccess(t('profile.success.change-pass'))
    } catch (error) {
      toastError(t('profile.errors.change-pass'))
    } finally {
      dispatch(showChangePassAction(false))
    }
  }

  const changeLocale = async (lang: string) => {
    dispatch(showLoader())
    try {
      dispatch(setUserLocation(getUserLanguage(userDetail)))
      localStorage.setItem('language', lang)
    } catch (error) {
      toastError(t('page-error.unknown.title'))
    } finally {
      dispatch(hideLoader())
    }
  }

  const userLanguage = useMemo(() => getUserLanguage(userDetail), [userDetail])

  return {
    changePass,
    changeLocale,
    userDetail,
    isLoaded,
    userLanguage,
  }
}
