import { StatePagination } from '@app/core/types'
import { useQueryFiltersPagination, useToast } from '@app/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import invoicesService from '@app/services/invoices/invoices.service'
import {} from '@app/services/cases/types'
import { INIT_QUERY_PAGE } from '@app/utils'
import {
  InvoiceBase,
  InvoiceBasePaginationQuery,
} from '@app/services/invoices/types'

const PAGE_SIZE = 10

type BillingState = StatePagination<InvoiceBase[], 'invoices'> & {
  error?: Error
}

const initialState: BillingState = {
  loaded: false,
  invoices: null,
  error: undefined,
  lastPage: 0,
  page: INIT_QUERY_PAGE,
  perPage: PAGE_SIZE,
}

const initialQuery: InvoiceBasePaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: PAGE_SIZE,
}

type UseCaseMultimediaType = {
  state: BillingState
  setPage: (page: number) => void
}

export const useCaseBilling = (caseId: string): UseCaseMultimediaType => {
  const [state, setState] = useState<BillingState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()
  const { query, setPage } = useQueryFiltersPagination({
    initialQuery,
  })

  const loadBilling = useCallback(
    async (id: string, queryInvoices: InvoiceBasePaginationQuery) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))
      try {
        const { data, meta } = await invoicesService.getInvoicesCase(
          id,
          queryInvoices,
        )
        setState(() => ({
          loaded: true,
          invoices: data,
          error: undefined,
          ...meta,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          invoices: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!caseId) {
      return
    }
    loadBilling(caseId, query)
  }, [loadBilling, caseId, query])

  return {
    state,
    setPage,
  }
}
