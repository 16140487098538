import { Route, Routes } from 'react-router-dom'
import { CasesPage } from './CasesPage'
import { CaseDetailPage } from './detail/CaseDetailPage'
import { NotFound } from '../errors'
import { CaseNewPage } from './new/CaseNewPage'
import { CaseEditPage } from './edit/CaseEditPage'

export function CasesRoutes() {
  return (
    <Routes>
      <Route index element={<CasesPage />} />
      <Route path=":caseId" element={<CaseDetailPage />} />
      <Route path=":caseId/edit" element={<CaseEditPage />} />
      <Route path="/new" element={<CaseNewPage />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
