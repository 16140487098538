import { clsx } from 'clsx'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'

type TextareaControlProps<T extends FieldValues> = {
  rows: number
  value?: string
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  className?: string
} & FormControlWrapperProps<T>

export const TextareaControl = function <T extends FieldValues>({
  warning,
  name,
  required,
  hiddenLabel,
  id,
  label,
  slotLabel,
  placeholder,
  disabled,
  readonly,
  rows,
}: TextareaControlProps<T>): JSX.Element {
  const methods = useFormContext<T>()

  const { control } = methods
  const prefixedId = id ?? `form-${name}`

  return (
    <div className="TextareaControl">
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormControlWrapper
            error={fieldState.error}
            warning={warning}
            name={field.name}
            slotLabel={slotLabel}
            hiddenLabel={hiddenLabel}
            label={label}
            id={prefixedId}
          >
            <textarea
              className={clsx('form-control', {
                'is-invalid': fieldState.error,
                'has-warning': warning,
              })}
              rows={rows}
              placeholder={placeholder}
              id={prefixedId}
              disabled={disabled}
              value={field.value}
              name={field.name}
              readOnly={readonly}
              required={required}
              onChange={e => field.onChange(e.target.value)}
              onBlur={field.onBlur}
            />
          </FormControlWrapper>
        )}
      />
    </div>
  )
}
