import { StatePagination } from '@app/core/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import casesService from '@app/services/cases/cases.service'
import { Case, CasesPaginationQuery } from '@app/services/cases/types'
import { useQueryFiltersPagination } from '../query-filters-pagination/useQueryFiltersPagination'
import { INIT_QUERY_PAGE } from '@app/utils'

export const CASES_PAGE_SIZE = 10

type CasesState = StatePagination<Case[], 'cases'>

const initialState: CasesState = {
  loaded: false,
  cases: null,
  lastPage: 0,
  page: INIT_QUERY_PAGE,
  perPage: CASES_PAGE_SIZE,
  total: 0,
}

const initialQuery: CasesPaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: CASES_PAGE_SIZE,
  search: '',
  filter: {},
}

type UseCasesType = {
  state: CasesState
  setPage: (page: number) => void
  setSortBy: (querySort: string) => void
  debouncedSearch: (term: string) => void
}

export const useCases = (customQuery?: CasesPaginationQuery): UseCasesType => {
  const [state, setState] = useState<CasesState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()
  const initialQueryFilter = useMemo(
    () => ({
      ...initialQuery,
      ...customQuery,
    }),
    [customQuery],
  )

  const { query, debouncedSearch, setPage, setSortBy } =
    useQueryFiltersPagination({
      initialQuery: initialQueryFilter,
      filter: customQuery?.filter,
      onSearch: useCallback(() => setState(initialState), []),
    })

  const loadCases = useCallback(
    async (queryCases: CasesPaginationQuery) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const { data: cases, meta } = await casesService.getCases(queryCases)
        setState(() => ({
          loaded: true,
          cases,
          ...meta,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          cases: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    loadCases(query)
  }, [loadCases, query])

  return {
    state,
    setPage,
    setSortBy,
    debouncedSearch,
  }
}
