import { StatePagination } from '@app/core/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import clinicsService from '@app/services/clinics/clinics.service'
import { ClinicBase, ClinicsPaginationQuery } from '@app/services/clinics/types'
import { useQueryFiltersPagination } from '../query-filters-pagination/useQueryFiltersPagination'
import { INIT_QUERY_PAGE } from '@app/utils'

const PAGE_SIZE = 12

type ClinicsState = StatePagination<ClinicBase[], 'clinics'>

const initialState: ClinicsState = {
  loaded: false,
  clinics: null,
  lastPage: 0,
  page: INIT_QUERY_PAGE,
  perPage: PAGE_SIZE,
}

const initialQuery: ClinicsPaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: PAGE_SIZE,
}

type UseClinicsType = {
  state: ClinicsState
  setPage: (page: number) => void
}

export const useClinics = (
  customQuery?: ClinicsPaginationQuery,
): UseClinicsType => {
  const [state, setState] = useState<ClinicsState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()
  const initialQueryFilter = useMemo(
    () => ({
      ...initialQuery,
      ...customQuery,
    }),
    [customQuery],
  )

  const { query, setPage } = useQueryFiltersPagination({
    initialQuery: initialQueryFilter,
    filter: customQuery?.filter,
  })

  const loadClinics = useCallback(
    async (queryClinics: ClinicsPaginationQuery) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const { data: clinics, meta } =
          await clinicsService.getClinics(queryClinics)
        setState(() => ({
          loaded: true,
          clinics,
          ...meta,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          clinics: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    loadClinics(query)
  }, [loadClinics, query])

  return {
    state,
    setPage,
  }
}
