import React, { useEffect } from 'react'
import { useAuth } from '@app/hooks'
import Spinner from '@app/components/spinner/Spinner'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@app/store'

const LoginPage: React.FC = () => {
  const { checkSession } = useAuth()
  const navigate = useNavigate()

  const isSessionChecked = useSelector(
    (state: RootState) => state.authLocalStorage.isSessionChecked,
  )
  const userToken = useSelector((state: RootState) => state.user.token)

  useEffect(() => {
    const performCheckSession = async () => {
      await checkSession()
    }

    performCheckSession()
  }, [checkSession])

  useEffect(() => {
    if (isSessionChecked && userToken) {
      navigate('/dashboard')
    }
  }, [isSessionChecked, userToken, navigate])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Spinner />
    </div>
  )
}

export default LoginPage
