import { Main, StateHandler } from '@app/components'
import { BillingAddressForm } from '../shared/billing-address-form/BillingAddressForm'
import { usePlaces, useToast } from '@app/hooks'
import { useCallback } from 'react'
import { BillingAddressRequest } from '@app/services/billing-address/types'
import billingAddService from '@app/services/billing-address/billing-address.service'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const BillingAddressNewPage = () => {
  const {
    countriesState: { countries, loaded, error },
    provincesState: { provinces },
    citiesState: { cities },
    getProvinces,
    getCities,
  } = usePlaces()
  const { toastError, toastSuccess } = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSubmit = useCallback(
    async (values: BillingAddressRequest) => {
      try {
        const request: BillingAddressRequest = {
          ...values,
        }

        const id = await billingAddService.createBillingAddress(request)
        navigate(`/billing-address/${id}`)
        toastSuccess(t('billing-address.success.create'))
      } catch (error) {
        toastError(t('billing-address.errors.create'))
      }
    },
    [navigate, t, toastError, toastSuccess],
  )

  return (
    <Main
      ariaLabelledby="billing-address-new-page"
      className="BillingAddressNewPage"
    >
      <StateHandler loaded={loaded} error={error}>
        <BillingAddressForm
          countries={countries!}
          provinces={provinces!}
          cities={cities!}
          getProvinces={getProvinces}
          getCities={getCities}
          onSubmit={handleSubmit}
        />
      </StateHandler>
    </Main>
  )
}
