import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { CiSliderHorizontal } from 'react-icons/ci'

type FilterButtonProps = {
  id: string
  open: boolean
  onClick: Dispatch<SetStateAction<boolean>>
}

export const FilterButton: FC<FilterButtonProps> = ({ id, open, onClick }) => {
  const { t } = useTranslation()

  return (
    <button
      className="btn text-dark SearchControl-filters"
      onClick={() => onClick(!open)}
      aria-controls={id}
      aria-expanded={open}
    >
      <CiSliderHorizontal />
      {t('filters.title')}
    </button>
  )
}
