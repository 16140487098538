import { Gender, RangeAge, Speciality } from '@app/services/auth/types'
import { useTranslation } from 'react-i18next'

export const useUtilsProfile = () => {
  const { t } = useTranslation()

  const getGenderString = (gender: Gender): string => {
    switch (gender) {
      case Gender.M:
        return t('profile.man')
      case Gender.F:
        return t('profile.woman')
      case Gender.O:
        return t('profile.other')
      default:
        return ''
    }
  }
  const getRangeAge = (age: RangeAge): string => {
    switch (age) {
      case RangeAge.A:
        return t('profile.range-age.A')
      case RangeAge.B:
        return t('profile.range-age.B')
      case RangeAge.C:
        return t('profile.range-age.C')
      default:
        return ''
    }
  }

  const getSpeciality = (specialty: Speciality): string => {
    switch (specialty) {
      case Speciality.OD:
        return t('profile.specialty-types.OD')
      case Speciality.OR:
        return t('profile.specialty-types.OR')
      case Speciality.OT:
        return t('profile.specialty-types.OT')
      default:
        return ''
    }
  }

  return {
    getGenderString,
    getRangeAge,
    getSpeciality,
  }
}
