import '../i18n/config'
import { AppRouter } from './routes/AppRouter'
import { SpinnerProvider } from './context/SpinnerProvider'
import { ConfirmationProvider } from '@app/components'

export const App = () => {
  return (
    <SpinnerProvider>
      <ConfirmationProvider>
        <AppRouter />
      </ConfirmationProvider>
    </SpinnerProvider>
  )
}
