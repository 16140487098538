import { SelectForm } from './select-form/SelectForm'

import './SelectLanguage.scss'

export const SelectLanguage = () => {
  return (
    <div className="SelectLanguage">
      <SelectForm />
    </div>
  )
}
