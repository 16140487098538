import {
  createContext,
  useContext,
  useState,
  FC,
  PropsWithChildren,
} from 'react'

interface SpinnerContextProps {
  showSpinner: boolean
  setShowSpinner: (show: boolean) => void
}

const SpinnerContext = createContext<SpinnerContextProps | undefined>(undefined)

export const SpinnerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [showSpinner, setShowSpinner] = useState(false)

  return (
    <SpinnerContext.Provider value={{ showSpinner, setShowSpinner }}>
      {children}
    </SpinnerContext.Provider>
  )
}

export const useSpinner = (): SpinnerContextProps => {
  const context = useContext(SpinnerContext)
  if (!context) {
    throw new Error('useSpinner must be used within a SpinnerProvider')
  }
  return context
}
