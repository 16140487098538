import { CarouselResponsiveType } from '@app/components/carousel/Carousel'
import { Responsive } from '@app/utils'

export const VISIBLE_GUTTER = 40
export const SLIDES_TO_SLIDE = 1

export const responsiveConfig: CarouselResponsiveType = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: Responsive.XL,
    },
    items: 4,
    slidesToSlide: SLIDES_TO_SLIDE,
    partialVisibilityGutter: VISIBLE_GUTTER,
  },
  tablet: {
    breakpoint: {
      max: Responsive.XL,
      min: Responsive.MD,
    },
    items: 2,
    slidesToSlide: SLIDES_TO_SLIDE,
    partialVisibilityGutter: VISIBLE_GUTTER,
  },
  mobile: {
    breakpoint: {
      max: Responsive.MD,
      min: 0,
    },
    items: 1,
    slidesToSlide: SLIDES_TO_SLIDE,
  },
}
