import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  TableColumnType,
  TableConfigType,
  TableContainerType,
  TableRowType,
} from './types'
import { StateBase } from '@app/core/types'
import { SortType } from './sort-buttons/SortButtons'

const stub = (): never => {
  throw new Error(
    'You forgot to wrap your component in <TableContext.Provider>.',
  )
}

export const DEFAULT_NUM_ROWS = 5

interface SortedByType {
  id?: string
  type?: SortType
}
export interface TableContextType {
  columns: TableColumnType[]
  state: StateBase
  rows?: TableRowType[]
  config?: TableConfigType
  sortedBy?: SortedByType
  handleSortBy: (id: string, type: SortType) => void
}

const initialSortedBy = {
  id: undefined,
  type: undefined,
}

const initialContext: TableContextType = {
  config: {
    columns: [],
    numRows: DEFAULT_NUM_ROWS,
  },
  rows: [],
  columns: [],
  state: {
    loaded: false,
  },
  handleSortBy: stub,
  sortedBy: initialSortedBy,
}

export const TableContext = createContext<TableContextType>(initialContext)

export function TableProvider({
  children,
  onSortBy,
  ...props
}: PropsWithChildren<TableContainerType>) {
  const [sortedBy, setSortedBy] = useState<SortedByType>(initialSortedBy)

  const handleSortBy = useCallback(
    (id: string, type: SortType) => {
      if (!onSortBy) {
        return
      }

      setSortedBy({
        id,
        type,
      })
      onSortBy(type === 'down' ? `-${id}` : id)
    },
    [onSortBy],
  )

  return (
    <TableContext.Provider value={{ sortedBy, handleSortBy, ...props }}>
      {children}
    </TableContext.Provider>
  )
}

export const useTableContext = (): TableContextType => useContext(TableContext)
