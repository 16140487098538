import { Breadcrumb } from 'react-bootstrap'
import { ItemBreadCrumb } from './types'

import './BreadcrumbDynamic.scss'

interface BreadcrumbProps {
  items: ItemBreadCrumb[]
  activeItem?: string
}

export const BreadcrumbDynamic: React.FC<BreadcrumbProps> = ({
  items,
  activeItem,
}) => {
  return (
    <Breadcrumb className="BreadcrumbDynamic">
      {items.map((item, index) => (
        <Breadcrumb.Item
          active={
            activeItem ? item.label === activeItem : items.length - 1 === index
          }
          key={index}
          href={item.href ? item.href : undefined}
        >
          {item.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}
