import { Route, Routes } from 'react-router-dom'
import { ClinicsPage } from './ClinicsPage'
import { ClinicDetailPage } from './detail/ClinicDetailPage'
import { ClinicEditPage } from './edit/ClinicEditPage'
import { ClinicNewPage } from './new/ClinicNewPage'
import { NotFound } from '../errors'

export function ClinicRoutes() {
  return (
    <Routes>
      <Route index element={<ClinicsPage />} />
      <Route path=":clinicId" element={<ClinicDetailPage />} />
      <Route path=":clinicId/edit" element={<ClinicEditPage />} />
      <Route path="/new" element={<ClinicNewPage />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
