import { Clinic, ClinicRequest, DaysOfWeek } from '@app/services/clinics/types'
import { ClinicFormFields, DaysOfWeekFormFields } from './types'

export function coerceValues(clinic: Clinic): ClinicFormFields {
  return {
    name: clinic.name ?? '',
    address: clinic.address ?? '',
    email: clinic.email ?? '',
    phone: clinic.phone ?? '',
    logo: clinic.logo ?? '',
    addressExtra: clinic.addressExtra ?? '',
    addressNumber: clinic.addressNumber ?? '',
    postalCode: clinic.postalCode ?? '',
    country: clinic.city?.province.country.id ?? '',
    province: clinic.city?.province.id ?? '',
    city: clinic.city?.id ?? '',
    openingHours: {
      days: coerceDays(clinic.openingHours?.days ?? []),
      startTimeMorning: clinic.openingHours?.startTimeMorning ?? '',
      endTimeMorning: clinic.openingHours?.endTimeMorning ?? '',
      startTimeEvening: clinic.openingHours?.startTimeEvening ?? '',
      endTimeEvening: clinic.openingHours?.endTimeEvening ?? '',
    },
  }
}

function coerceDays(days: DaysOfWeek[]): DaysOfWeekFormFields {
  if (!days.length) {
    return {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
    }
  }
  return {
    monday: days.includes(DaysOfWeek.monday) ?? false,
    tuesday: days.includes(DaysOfWeek.tuesday) ?? false,
    wednesday: days.includes(DaysOfWeek.wednesday) ?? false,
    thursday: days.includes(DaysOfWeek.thursday) ?? false,
    friday: days.includes(DaysOfWeek.friday) ?? false,
    saturday: days.includes(DaysOfWeek.saturday) ?? false,
    sunday: days.includes(DaysOfWeek.sunday) ?? false,
  }
}

function mapDaysForRequest(days: DaysOfWeekFormFields): DaysOfWeek[] {
  const result = Object.entries(days)
    .filter(([, isChecked]) => isChecked)
    .map(([key]) => DaysOfWeek[key as unknown as keyof typeof DaysOfWeek])

  return result
}

export function mapClinicFormForRequest(
  formValues: ClinicFormFields,
  logoFile?: string,
): ClinicRequest {
  return {
    name: formValues.name,
    address: formValues.address,
    email: formValues.email,
    phone: formValues.phone,
    addressNumber: formValues.addressNumber,
    addressExtra: formValues.addressExtra,
    postalCode: formValues.postalCode,
    cityId: formValues.city,
    logo: logoFile ?? '',
    openingHours: {
      days: mapDaysForRequest(formValues.openingHours.days),
      startTimeMorning: formValues.openingHours.startTimeMorning,
      endTimeMorning: formValues.openingHours.endTimeMorning,
      startTimeEvening: formValues.openingHours.startTimeEvening,
      endTimeEvening: formValues.openingHours.endTimeEvening,
    },
  }
}
