import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { showChangePassAction } from '@app/store/slices'
import Modal from 'react-bootstrap/esm/Modal'
import Button from 'react-bootstrap/esm/Button'
import { CiCircleRemove, CiFloppyDisk } from 'react-icons/ci'
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Form, InputControl } from '@app/components/forms'
import { useCallback } from 'react'
import { ButtonAsync } from '@app/components/button-async/ButtonAsync'
import { yupResolver } from '@hookform/resolvers/yup'
import { changePassFormValidationSchema } from './validation'
import { useProfile } from '@app/hooks'

type ChangePassFormFields = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

const defaultValues: ChangePassFormFields = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
}

export const ChangePass = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { changePass } = useProfile()
  const { showChangePass } = useAppSelector(state => state.ui)
  const methods = useForm<ChangePassFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(changePassFormValidationSchema(t)),
  })

  const {
    formState: { isValid, errors, isSubmitting },
  } = methods

  const handleSubmitForm: SubmitHandler<ChangePassFormFields> = useCallback(
    async (formValues: ChangePassFormFields) => {
      const { currentPassword, password, passwordConfirmation } = formValues
      const params = {
        currentPassword,
        password,
        passwordConfirmation,
      }
      changePass(params)
    },
    [changePass],
  )

  return (
    <Modal
      show={showChangePass}
      onHide={() => dispatch(showChangePassAction(false))}
      className="ChangePass"
    >
      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <Modal.Header>
            <Modal.Title>{t('profile.password.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column gap-2">
              <InputControl
                type="password"
                name="currentPassword"
                label={t('profile.password.pass')}
                placeholder={t('profile.password.pass')}
                error={errors.password}
                required
              />
              <InputControl
                type="password"
                name="password"
                label={t('profile.password.new')}
                placeholder={t('profile.password.new')}
                error={errors.password}
                required
              />
              <InputControl
                type="password"
                name="passwordConfirmation"
                label={t('profile.password.confirm')}
                placeholder={t('profile.password.confirm')}
                error={errors.passwordConfirmation}
                required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              onClick={() => dispatch(showChangePassAction(false))}
            >
              <CiCircleRemove />
              {t('common.cancel')}
            </Button>
            <ButtonAsync
              className="btn-primary"
              disabled={!isValid}
              isSubmitting={isSubmitting}
            >
              <CiFloppyDisk />
              {t('common.save')}
            </ButtonAsync>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  )
}
