import { useAppDispatch } from '@app/store/hooks'
import Modal from 'react-bootstrap/esm/Modal'
import { useTranslation } from 'react-i18next'
import { ButtonAsync } from '@app/components'
import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Form, TextareaControl } from '@app/components/forms'
import { yupResolver } from '@hookform/resolvers/yup'
import { requestChangesFormValidationSchema } from './validation'
import { RequestChangesFormFields } from './types'
import { reloadCaseAction } from '@app/store/slices/case/caseSlice'
import Button from 'react-bootstrap/esm/Button'
import {
  AcceptanceRequest,
  PlanAcceptance,
} from '@app/services/treatment-plans/types'

const defaultValues: RequestChangesFormFields = {
  requestedChangesText: '',
}

type RequestChangesModalProps = {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  onSubmit: (request: AcceptanceRequest) => Promise<void>
}

export const RequestChangesModal: FC<RequestChangesModalProps> = ({
  setShowModal,
  showModal,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const methods = useForm<RequestChangesFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(requestChangesFormValidationSchema(t)),
  })
  const {
    formState: { isSubmitting, isValid },
  } = methods

  const handleSubmitForm: SubmitHandler<RequestChangesFormFields> = useCallback(
    async (formValues: RequestChangesFormFields) => {
      const request: AcceptanceRequest = {
        result: PlanAcceptance.RequestedChanges,
        requestedChangesText: formValues.requestedChangesText,
      }
      onSubmit(request)
      dispatch(reloadCaseAction(true))
    },
    [dispatch, onSubmit],
  )

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="modal-md"
    >
      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <Modal.Header closeButton>
            <Modal.Title>{t('cases.requestedChanges.modal.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <div className="d-flex flex-column gap-2">
              <p>{t('cases.requestedChanges.modal.body')}</p>

              <TextareaControl<RequestChangesFormFields>
                rows={3}
                name="requestedChangesText"
                label={t('cases.requestedChanges.modal.modifications')}
                required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="button"
              onClick={() => setShowModal(false)}
            >
              {t('cases.requestedChanges.modal.button.cancel')}
            </Button>
            <ButtonAsync
              className="btn-primary"
              disabled={!isValid}
              isSubmitting={isSubmitting}
            >
              {t('cases.requestedChanges.modal.button.accept')}
            </ButtonAsync>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  )
}
