import {
  Case,
  CaseArcadeType,
  GeneralInfoRequest,
  CasesTeethNotMove,
  TeethNotToMoveRequest,
} from '@app/services/cases/types'
import { CaseCreateFormFields } from '../types'
import { isToothBelongsToArcade } from '@app/components/teeth-select/utils'

export function generalInfoCoerceValues(caseData?: Case): CaseCreateFormFields {
  return {
    type: caseData?.type,
    general: {
      firstName: caseData?.generalInfo?.patient.firstName ?? '',
      lastName: caseData?.generalInfo?.patient.lastName ?? '',
      gender: caseData?.generalInfo?.patient.gender,
      birthdate: caseData?.generalInfo?.patient.birthdate ?? '',
      archTreat: caseData?.generalInfo?.patient.archTreat,
      teethNotToMove: caseData?.generalInfo?.patient.teethNotToMove ?? [],
      comments: caseData?.generalInfo?.comments ?? '',
      clinicId: caseData?.generalInfo?.clinic?.id ?? '',
      billingAddressId: caseData?.generalInfo?.billingAddress?.id ?? '',
    },
  }
}

export function mapGeneralInfoValuesForRequest(
  formValues: CaseCreateFormFields,
): GeneralInfoRequest {
  return {
    type: formValues.type!,
    generalInfo: {
      patient: {
        firstName: formValues.general.firstName,
        lastName: formValues.general.lastName,
        gender: formValues.general.gender!,
        birthdate: formValues.general.birthdate,
        archTreat: formValues.general.archTreat!,
        teethNotToMove: mapTeethNotToMoveForRequest(
          formValues.general.archTreat!,
          formValues.general.teethNotToMove,
        ),
        comments: formValues.general.comments,
      },
      clinicId: Number(formValues.general.clinicId),
      billingAddressId: Number(formValues.general.billingAddressId),
    },
  }
}

function mapTeethNotToMoveForRequest(
  arcadeType: CaseArcadeType,
  teethNotToMove: CasesTeethNotMove[],
): TeethNotToMoveRequest[] {
  return teethNotToMove
    .filter(tooth => isToothBelongsToArcade(tooth.number, arcadeType))
    .map(tooth => ({
      number: Number(tooth.number),
    }))
}
