import {
  BreadcrumbDynamic,
  ButtonAsync,
  Card,
  Detail,
  PageHeader,
} from '@app/components'
import { ItemBreadCrumb } from '@app/components/breadcrumb-dynamic/types'
import { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BillingAddressFormFields } from './types'
import { Form, InputControl, SelectControl } from '@app/components/forms'
import { Link } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { CiFloppyDisk } from 'react-icons/ci'
import { yupResolver } from '@hookform/resolvers/yup'
import { buildValidationSchema } from './validation'
import { Place } from '@app/services/places/types'
import {
  BillingAddressDetail,
  BillingAddressRequest,
} from '@app/services/billing-address/types'
import { useClinics } from '@app/hooks'
import { ClinicBase } from '@app/services/clinics/types'
import { loadValuesBillingAddress } from './utils'

interface BillingAddressFormProps {
  countries: Place[]
  provinces?: Place[]
  cities?: Place[]
  getProvinces: (countryCode: string) => Promise<void>
  getCities: (provinceCode: string) => Promise<void>
  onSubmit: (values: BillingAddressRequest) => Promise<void>
  billingAddress?: BillingAddressDetail
}

export const BillingAddressForm: FC<BillingAddressFormProps> = ({
  countries,
  provinces = [],
  cities = [],
  getProvinces,
  getCities,
  onSubmit,
  billingAddress = {} as BillingAddressDetail,
}) => {
  const { t } = useTranslation()
  const [loadingProvince, setLoadingProvince] = useState(false)
  const [disabledProvince, setDisabledProvince] = useState(false)
  const [disabledCity, setDisabledCity] = useState(false)
  const [loadingCity, setLoadingCity] = useState(false)
  const {
    state: { loaded, clinics },
  } = useClinics()
  const [selectedClinic, setSelectedClinic] = useState<ClinicBase>()
  const methods = useForm<BillingAddressFormFields>({
    mode: 'onChange',
    defaultValues: loadValuesBillingAddress(billingAddress),
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    formState: { isValid, isSubmitting },
    watch,
    setFocus,
    setValue,
    clearErrors,
  } = methods
  const countryWatch = watch('country')
  const provinceWatch = watch('province')

  const breadcrumbItems: ItemBreadCrumb[] = [
    { label: t('menu.billing'), href: '/billing-address' },
    { label: billingAddress.name || t('billing-address.new') },
  ]
  const onChangeClinic = useCallback(
    (value: string | boolean, clinics: ClinicBase[]) => {
      const stringValue = String(value)
      const findClinic = clinics.find(clinic => clinic.id === stringValue)
      setSelectedClinic(findClinic)
    },
    [],
  )

  const copyClinicData = useCallback(() => {
    if (selectedClinic) {
      setValue('phone', selectedClinic.phone)
      setValue('email', selectedClinic.email)
      setValue('address', selectedClinic.address)
      setValue('addressNumber', selectedClinic.addressNumber)
      selectedClinic.addressExtra &&
        setValue('addressExtra', selectedClinic.addressExtra)
      setValue('postalCode', selectedClinic.postalCode)
      setValue('country', selectedClinic.city.province.country.id)
      setLoadingProvince(true)
      setLoadingCity(true)
      getProvinces(selectedClinic.city.province.country.id).finally(() => {
        setLoadingProvince(false)
        setValue('province', selectedClinic.city.province.id)
      })
      getCities(selectedClinic.city.province.id).finally(() => {
        setLoadingCity(false)
        setValue('city', selectedClinic.city.id)
      })
    }
  }, [getCities, getProvinces, selectedClinic, setValue])
  const onChangeCountry = useCallback(
    (value: string | boolean) => {
      const stringValue = String(value) // Convertir a cadena

      setLoadingProvince(true)
      setValue('province', '')
      setValue('city', '')
      clearErrors('province')
      clearErrors('city')
      getProvinces(stringValue).finally(() => {
        setLoadingProvince(false)
        setFocus('province')
      })
    },
    [clearErrors, getProvinces, setFocus, setValue],
  )
  const onChangeProvince = useCallback(
    (value: string | boolean) => {
      const stringValue = String(value)
      setLoadingCity(true)
      setValue('city', '')
      clearErrors('city')
      getCities(stringValue).finally(() => {
        setLoadingCity(false)
        setFocus('city')
      })
    },
    [clearErrors, getCities, setFocus, setValue],
  )
  const handleSubmitForm: SubmitHandler<BillingAddressFormFields> = async (
    formValues: BillingAddressFormFields,
  ) => {
    onSubmit({
      name: formValues.name,
      vatNumber: formValues.vatNumber,
      address: formValues.address,
      email: formValues.email,
      phone: formValues.phone,
      addressNumber: formValues.addressNumber,
      addressExtra: formValues.addressExtra,
      postalCode: formValues.postalCode,
      country: formValues.country,
      province: formValues.province,
      cityId: formValues.city,
      accountNumber: formValues.accountNumber,
    })
  }

  useEffect(() => {
    setDisabledProvince(!countryWatch)
    setDisabledCity(!countryWatch || !provinceWatch)
  }, [countryWatch, provinceWatch])

  return (
    <>
      <PageHeader id="billing-address-form">
        <BreadcrumbDynamic
          items={breadcrumbItems}
          activeItem={t('billing-address.new')}
        />
        {billingAddress.name}
      </PageHeader>
      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <Card>
            <Card.Body size={'sm'}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="name"
                    label={t('billing-address.name')}
                    placeholder={t('billing-address.name')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="vatNumber"
                    label={t('billing-address.cif')}
                    placeholder={t('billing-address.cif')}
                    required
                  />
                </div>
                {loaded && clinics?.length && (
                  <>
                    <div className="col-12 col-md-6">
                      <SelectControl
                        name="clinic"
                        label={t('billing-address.copy-clinic')}
                        options={clinics.map(d => ({
                          value: d.id,
                          label: d.name,
                        }))}
                        onChange={value =>
                          onChangeClinic(String(value), clinics)
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                      <button
                        className="btn btn-primary"
                        disabled={!selectedClinic}
                        type="button"
                        onClick={copyClinicData}
                      >
                        {t('billing-address.copy-data')}
                      </button>
                    </div>
                  </>
                )}
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="phone"
                    label={t('billing-address.phone')}
                    placeholder={t('billing-address.phone')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="email"
                    name="email"
                    label={t('billing-address.email')}
                    placeholder={t('billing-address.email')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="address"
                    label={t('clinics.address')}
                    placeholder={t('clinics.address')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="addressNumber"
                    label={t('clinics.address-number')}
                    placeholder={t('clinics.address-number')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="addressExtra"
                    label={t('clinics.address-floor')}
                    placeholder={t('clinics.address-floor')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputControl
                    type="text"
                    name="postalCode"
                    label={t('clinics.postal-code')}
                    placeholder={t('clinics.postal-code')}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectControl
                    name="country"
                    label={t('clinics.country')}
                    options={countries.map(d => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    onChange={onChangeCountry}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectControl
                    name="province"
                    label={t('clinics.province')}
                    disabled={disabledProvince}
                    loading={loadingProvince}
                    options={provinces?.map(d => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    onChange={value => onChangeProvince(String(value))}
                    required
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectControl
                    name="city"
                    label={t('clinics.city')}
                    disabled={disabledCity}
                    loading={loadingCity}
                    options={cities?.map(d => ({
                      value: d.id,
                      label: d.name,
                    }))}
                    required
                  />
                </div>
                <div className="col-12 mt-2">
                  <Detail.SubTitle>{t('billing-address.pays')}</Detail.SubTitle>
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <InputControl
                    type="text"
                    name="accountNumber"
                    label={t('billing-address.iban')}
                    placeholder={t('billing-address.iban')}
                  />
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Link className="btn btn-secondary" to="../">
                <BsChevronLeft />
                {t('common.back')}
              </Link>
              <ButtonAsync
                className="btn btn-primary"
                disabled={!isValid}
                isSubmitting={isSubmitting}
              >
                <CiFloppyDisk />
                {t('common.save')}
              </ButtonAsync>
            </Card.Footer>
          </Card>
        </Form>
      </FormProvider>
    </>
  )
}
