import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { Nav } from 'react-bootstrap'

import './SidebarNav.scss'

type SidebarNavContainerType = {
  className?: string
} & PropsWithChildren

const SidebarNavContainer: FC<SidebarNavContainerType> = ({
  children,
  className,
}) => {
  return <Nav className={clsx('SidebarNav', className)}>{children}</Nav>
}

export const SidebarNavItem: FC<PropsWithChildren> = ({ children }) => (
  <Nav.Item className="SidebarNav-item">{children}</Nav.Item>
)

export const SidebarNav = Object.assign(SidebarNavContainer, {
  Item: SidebarNavItem,
})
