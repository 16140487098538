import { ErrorPage } from '@app/components'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const UnknownError: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ErrorPage className="UnknownError">
      <>
        <ErrorPage.Title text={t('page-error.unknown.title')} />
        <ErrorPage.Body text={t('page-error.unknown.body')} />

        <ErrorPage.Actions>
          <button
            type="button"
            onClick={() => navigate(0)}
            className="btn btn-link"
          >
            {t('page-error.unknown.button')}
          </button>
        </ErrorPage.Actions>
      </>
    </ErrorPage>
  )
}
