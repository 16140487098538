import clsx from 'clsx'
import { ButtonHTMLAttributes, ReactNode, useEffect, useState } from 'react'

export type ButtonAsyncProps<T> = {
  children: ReactNode
  onClick?: () => Promise<T>
  className?: string
  disabled?: boolean
  isSubmitting?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export const ButtonAsync = <T,>({
  onClick,
  children,
  className = '',
  disabled = false,
  isSubmitting = false,
  ...props
}: ButtonAsyncProps<T>) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(isSubmitting)
  }, [isSubmitting])

  return (
    <button
      {...props}
      className={clsx('btn', className)}
      disabled={disabled || loading}
      onClick={() => {
        if (onClick) {
          setLoading(true)
          onClick().finally(() => setLoading(false))
        }
      }}
    >
      <>{children}</>
      {loading && (
        <>
          {' '}
          <span
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
        </>
      )}
    </button>
  )
}
