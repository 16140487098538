import * as yup from 'yup'
import { ObjectShape, mixed } from 'yup'

export const oneOfEnum = <T extends object>(enumObject: T) =>
  mixed<T>().oneOf(Object.values(enumObject))

export function createObjectErrors(
  list: string[],
  objectValue: ObjectShape,
  path: string,
  message: string,
  createError: (params?: yup.CreateErrorOptions) => yup.ValidationError,
) {
  const errors = list
    .map(field => {
      if (objectValue[field]) {
        return null
      }
      return new yup.ValidationError(message, field, `${path}.${field}`)
    })
    .filter(Boolean)

  return createYupErrors(errors as yup.ValidationError[], createError)
}

export function createYupErrors(
  errors: yup.ValidationError[],
  createError: (params?: yup.CreateErrorOptions) => yup.ValidationError,
) {
  if (errors.length === 0) {
    return true
  }

  return createError({
    message: () => errors,
  })
}
