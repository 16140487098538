import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { ResponseData } from '@app/core/types'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import { Prescription } from './types'

const getBaseUrl = (config: IConfigService) => `${config.baseUrl}/prescription`

const getProblems =
  (config: IConfigService, http: IHttpClient) =>
  async (): Promise<ResponseData<Prescription[]>> => {
    try {
      return await http.get(`${getBaseUrl(config)}/problems`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const getSolutions =
  (config: IConfigService, http: IHttpClient) =>
  async (): Promise<ResponseData<Prescription[]>> => {
    try {
      return await http.get(`${getBaseUrl(config)}/solutions`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getProblems: getProblems(config, http),
  getSolutions: getSolutions(config, http),
})

export default factory(config, http)
