import { useTranslation } from 'react-i18next'
import { FC, useEffect } from 'react'
import { CaseStep } from '../../form-steps/types'
import { mapEnumIntoIterableTypeValue } from '@app/utils'
import { CaseType } from '@app/services/cases/types'
import { Card } from '@app/components'
import { BsChevronRight } from 'react-icons/bs'
import { useFormContext } from 'react-hook-form'
import { Button } from 'react-bootstrap'
import { CaseTypeRadioControl } from '../../form-steps/1-type/radio-control/CaseTypeRadioControl'

const dateTypeOptions = mapEnumIntoIterableTypeValue(
  CaseType,
  'cases.case-type',
)

interface CaseTypeFormProps {
  handleNavigation: (selectedKey: CaseStep) => void
}

export const CaseTypeForm: FC<CaseTypeFormProps> = ({ handleNavigation }) => {
  const { t } = useTranslation()
  const {
    watch,
    formState: { isValid, isDirty },
  } = useFormContext()

  const typeWatch = watch('type')

  useEffect(() => {
    if (typeWatch) {
      handleNavigation(CaseStep.General)
    }
  }, [handleNavigation, typeWatch])

  return (
    <>
      <Card.Body size={'sm'} className="CaseType">
        <CaseTypeRadioControl
          required
          options={dateTypeOptions?.map(d => ({
            label: t(`${d.type}.label`),
            body: t(`${d.type}.body`),
            value: d.value as unknown as string,
          }))}
        />
      </Card.Body>
      <Card.Footer size={'sm'}>
        <Button
          className="btn btn-primary"
          type="button"
          onClick={() => handleNavigation(CaseStep.General)}
          disabled={!isValid && !isDirty}
        >
          {t('common.next')}
          <BsChevronRight />
        </Button>
      </Card.Footer>
    </>
  )
}
