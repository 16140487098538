import { PaginationQuery } from '@app/core/types'
import { Place } from '../places/types'

export interface BillingAddress {
  id: string
  name: string
  address: string
  vatNumber: string
}

export enum PaymentMethods {
  NotSelected = 'not_selected',
  NotEstablished = 'NOT_ESTABLISHED',
  Domiciliate15Days = 'domiciliation_15_days',
  Domiciliate30Days = 'domiciliation_30_days',
  BankTransfer15Days = 'bank_transfer_15_days',
  BankTransfer30Days = 'bank_transfer_30_days',
  Domiciliate60Days = 'domiciliation_60_days',
  Domiciliate2Payments = 'domiciliation_2_payments',
  BankTransfer60Days = 'bank_transfer_60_days',
  BankTransfer2Payments = 'bank_transfer_2_payments',
  BankTransfer5Days = 'bank_transfer_5_days',
  Domiciliate5Days = 'domiciliation_5_days',
  BankTransfer180Payments = 'bank_transfer_180_payments',
  BankTransfer360Payments = 'bank_transfer_360_payments',
  BankTransfer90Payments = 'bank_transfer_90_payments',
  BankTransfer120Payments = 'bank_transfer_120_payments',
  BankTransfer150Payments = 'bank_transfer_150_payments',
}

export type BillingAddressDetail = BillingAddress & {
  phone: string
  addressNumber: string
  addressExtra: string
  postalCode: string
  email: string
  city: Place & {
    province: Place & {
      country: Place
    }
  }
  accountNumber: string | null
  paymentMethod: PaymentMethods
}

export type BillingAddressPaginationQuery = PaginationQuery

export type BillingAddressRequest = {
  name: string
  vatNumber: string
  phone: string
  email: string
  address: string
  addressNumber: string
  addressExtra: string
  postalCode: string
  country: string
  province: string
  cityId: string
  accountNumber: string
}
export type CreateBillingAddressRequest = BillingAddressRequest

export type UpdateBillingAddressRequest = BillingAddressRequest & {
  id: string
}
