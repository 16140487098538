import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Image from 'react-bootstrap/esm/Image'
import { CaseFile } from '@app/services/files/types'

interface ShowImageProps {
  photo: CaseFile
  setShowModal: (param: boolean) => void
}

export const ShowImage = ({ setShowModal, photo }: ShowImageProps) => {
  const { t } = useTranslation()
  return (
    <Modal show={true} size="lg" onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>{t(`cases.multimedia.${photo.identifier}`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="row">
          <Image src={photo.url!} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          {t('common.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
