import { Aggregates } from '@app/services/invoices/types'
import { useMemo } from 'react'
import { AxisOptions } from 'react-charts'
import { useTranslation } from 'react-i18next'

export const useBarchart = (aggregates: Aggregates) => {
  const { t } = useTranslation()
  const chartData = useMemo(
    () => [
      {
        label: t('invoices.monthly'),
        data: aggregates.totalMonthlyTrend.map(trend => ({
          primary: trend.date,
          secondary: trend.aggregate,
        })),
      },
    ],
    [aggregates.totalMonthlyTrend, t],
  )
  const primaryAxis = useMemo<AxisOptions<{ primary: string }>>(
    () => ({
      getValue: datum => datum.primary,
    }),
    [],
  )

  const secondaryAxes = useMemo<AxisOptions<{ secondary: number }>[]>(
    () => [
      {
        getValue: datum => datum.secondary,
        elementType: 'bar',
      },
    ],
    [],
  )

  const totalValue = aggregates.total

  return {
    chartData,
    primaryAxis,
    secondaryAxes,
    totalValue,
  }
}
