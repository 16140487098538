import { StateBase } from '@app/core/types'
import { useToast } from '@app/hooks'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import statusService from '@app/services/status/status.service'
import { CaseStatus } from '@app/services/cases/types'

const initialState: StateBase = {
  loaded: true,
  error: undefined,
}

export const useStatus = (caseId: string) => {
  const [state, setState] = useState<StateBase>(initialState)
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()

  const handleChangeStatus = useCallback(
    async (status: CaseStatus) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const params = {
          caseId,
          status,
        }
        await statusService.changeStatus(params)
        setState(() => ({
          loaded: true,
          error: undefined,
        }))
        toastSuccess(t('cases.actions.success-status'))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [caseId, t, toastError, toastSuccess],
  )

  return {
    state,
    handleChangeStatus,
  }
}
