import AsyncSelect from 'react-select/async'
import { CiSearch } from 'react-icons/ci'
import { SearchControlStyle } from '../shared/search-control-style/SearchControlStyle'
import { GroupBase, OptionsOrGroups } from 'react-select'
import debounce from 'lodash.debounce'
import { DEBOUNCE_DELAY } from '@app/utils'
import {
  CustomControl,
  CustomMenu,
  CustomOption,
} from '../shared/SearchCustomComponents'
import { useTranslation } from 'react-i18next'

interface SearchAsyncControlProps {
  loadOptions: (
    search: string,
  ) => Promise<OptionsOrGroups<unknown, GroupBase<unknown>>>
}

export const SearchAsyncControl = ({
  loadOptions,
}: SearchAsyncControlProps) => {
  const { t } = useTranslation()
  const debouncedLoadOptions = debounce((search, callback) => {
    loadOptions(search)!.then(options => callback(options))
  }, DEBOUNCE_DELAY)

  // If MsEdge's translation feature is active and react-select uses aria live
  // messaging, the React app crashes when the dropdown menu is closed. Because
  // of this we disable aria live messaging when MsEdge's translation feature is
  // active.
  // https://github.com/JedWatson/react-select/issues/5816
  const emptyAriaLiveMessages = {
    guidance: () => '',
    onChange: () => '',
    onFilter: () => '',
    onFocus: () => ''
  };

  return (
    <SearchControlStyle>
      <div className="SearchControl mb-0">
        <div className="SearchControl-group">
          <span className="SearchControl-icon">
            <CiSearch />
          </span>
          <AsyncSelect
            ariaLiveMessages={emptyAriaLiveMessages}
            className="w-100"
            classNamePrefix={'SelectControl'}
            cacheOptions
            isClearable
            defaultOptions
            placeholder={t('cases.search')}
            loadOptions={debouncedLoadOptions}
            components={{
              Option: CustomOption,
              DropdownIndicator: null,
              Control: CustomControl,
              Menu: CustomMenu,
            }}
          />
        </div>
      </div>
    </SearchControlStyle>
  )
}
