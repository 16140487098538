import { FC } from 'react'
import { Link } from 'react-router-dom'
import Image from 'react-bootstrap/esm/Image'
import appLogo from '@images/logo-secretaligner.svg'
import { ProfileInfo } from '../profile-info/ProfileInfo'
import { NavbarMenu } from '../navbar-menu/NavbarMenu'
import { ButtonPhone, SelectLanguage } from '@app/components'

import './Sidebar.scss'

export const Sidebar: FC = () => {
  return (
    <div className="Sidebar">
      <div className="Sidebar-wrapper">
        <div className="Sidebar-container">
          <Link className="Sidebar-logo" to="/">
            <Image src={appLogo} />
          </Link>

          <div className="Sidebar-profileInfo">
            <ProfileInfo />
          </div>

          <div className="Sidebar-selectLang d-none d-lg-flex">
            <SelectLanguage />
          </div>

          <div className="Sidebar-navbarMenu">
            <NavbarMenu />
          </div>

          <div className="d-none d-lg-flex Sidebar-phone">
            <ButtonPhone />
          </div>
        </div>
      </div>
    </div>
  )
}
