import { Case } from '@app/services/cases/types'
import { enumKeys, getFileById } from '@app/utils'
import { RadioFormFields, FilesRadioFormFields } from '../types'
import { RadioIdentifiers } from '@app/services/files/types'

export function radioCoerceValues(caseData: Case): RadioFormFields {
  const radiographsKeys = enumKeys(RadioIdentifiers)

  const radiographs = radiographsKeys.reduce((acc, key) => {
    const fileById = getFileById(caseData.files, RadioIdentifiers[key])
    acc[RadioIdentifiers[key] as RadioIdentifiers] = {
      identifier: fileById?.identifier,
      originalName: fileById?.fileName ?? '',
      file: fileById?.url ?? '',
    }

    return acc
  }, {} as FilesRadioFormFields)

  return {
    files: {
      radiographs,
    },
  }
}
