import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import { mapEnumIntoIterableTypeValue } from '@app/utils'
import { CaseType } from '@app/services/cases/types'
import { Card } from '@app/components'
import { CaseTypeRadioControl } from './radio-control/CaseTypeRadioControl'
import { useFormContext } from 'react-hook-form'
import { FormControlFeedback } from '@app/components/forms/form-control-wrapper/FormControlWrapper'
import { CaseFormFields } from '../types'

const dateTypeOptions = mapEnumIntoIterableTypeValue(
  CaseType,
  'cases.case-type',
)

export const TreatmentTypeForm: FC = () => {
  const { t } = useTranslation()

  const {
    formState: { errors },
  } = useFormContext<CaseFormFields>()

  const typeErrorsWatch = useMemo(() => errors.type, [errors.type])

  return (
    <>
      <Card.Body size={'sm'} className="CaseType">
        <div className="input-group">
          <CaseTypeRadioControl
            required
            options={dateTypeOptions?.map(d => ({
              label: t(`${d.type}.label`),
              body: t(`${d.type}.body`),
              value: d.value as unknown as string,
            }))}
          />

          {typeErrorsWatch && (
            <FormControlFeedback
              level={'error'}
              message={t('cases.errors.type')}
            />
          )}
        </div>
      </Card.Body>
    </>
  )
}
