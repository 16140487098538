import { Card, Table } from '@app/components'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination } from '@app/components/pagination/Pagination'
import { useCases } from '@app/hooks/cases/useCases'
import clsx from 'clsx'
import { columnConfig, getTableColumns, getTableRows } from './utils'
import { INIT_QUERY_PAGE } from '@app/utils'
import { CaseStatus, CasesPaginationQuery } from '@app/services/cases/types'
import { TableRowType } from '@app/components/table/types'

interface PendingCasesType {
  className?: string
}

const customQuery: CasesPaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: 10,
  filter: { status: CaseStatus.Pending },
}

export const PendingCases: FC<PendingCasesType> = ({ className }) => {
  const { t } = useTranslation()
  const {
    state: { cases, loaded, error, ...query },
    setPage,
    setSortBy,
  } = useCases(customQuery)

  const [rows, setRows] = useState<TableRowType[]>([])

  useEffect(() => {
    const fetchRows = async () => {
      const rowData = await getTableRows(cases ?? [], t)
      setRows(rowData)
    }

    if (cases) {
      fetchRows()
    }
  }, [cases, t])

  return (
    <Card className={className}>
      <Card.Body>
        <Card.Title>
          {t('dashboard.pending-cases.title')}
          <Card.Badge className={clsx({ invisible: !loaded })}>
            {query.total}
          </Card.Badge>
        </Card.Title>

        <Table
          columns={getTableColumns(t)}
          rows={rows}
          config={{
            columns: columnConfig,
            numRows: customQuery.perPage,
          }}
          state={{ loaded, error }}
          onSortBy={setSortBy}
        />

        <Pagination
          loading={!loaded}
          currentPage={query.page}
          totalPages={query.lastPage}
          totalElements={query.total || 0}
          onChange={pageIndex => setPage(pageIndex)}
        />
      </Card.Body>
    </Card>
  )
}
