import { FC, PropsWithChildren, RefAttributes } from 'react'
import {
  TableButtonProps,
  TableCompositionType,
  TableColIdType,
  TablePlaceholderProps,
  TableRowProps,
  TableConfigColType,
  TableContainerType,
} from './types'
import Image, { ImageProps } from 'react-bootstrap/esm/Image'
import clsx from 'clsx'
import { Link, LinkProps } from 'react-router-dom'
import { TableStateHandler } from '../state-handler/StateHandler'
import { EmptyList } from '../empty-list/EmptyList'
import Placeholder from 'react-bootstrap/esm/Placeholder'
import { Responsive, getRandomNumber } from '@app/utils'
import { DEFAULT_NUM_ROWS, TableProvider, useTableContext } from './context'
import { useViewport } from '@app/hooks/viewport/useViewport'
import { StatusBadge } from '../status-badge/StatusBadge'
import { CaseStatus } from '@app/services/cases/types'
import { TableBodyWrapper } from '..'
import './Table.scss'
import { SortButtons } from './sort-buttons/SortButtons'
import avatarPlaceholder from '@images/placeholder-avatar.png'

const defaultColumnsConfig: TableConfigColType = {
  target: '_all',
  hidden: false,
  orderable: true,
}

const getColumnConfig = (
  columnId: TableColIdType,
  config?: TableConfigColType[],
): TableConfigColType => {
  return (
    config?.find(cellConfig => cellConfig.target === columnId) ??
    defaultColumnsConfig
  )
}

const TableContainer = (
  props: TableContainerType & Partial<TableCompositionType>,
) => {
  if (!props.columns) {
    return null
  }

  return (
    <TableProvider {...props}>
      <div className="table-responsive">
        <table className={clsx('table table-striped Table', props.className)}>
          <>
            <TableHead />
            <TableBody />
          </>
        </table>
      </div>
    </TableProvider>
  )
}

const TableHead = () => {
  const { config, columns } = useTableContext()
  return (
    <thead>
      <tr>
        {columns?.map(({ id, title }) => {
          const customConfig = getColumnConfig(id, config?.columns)

          if (customConfig?.hidden) {
            return null
          }

          return (
            <th
              key={id}
              scope="col"
              className={clsx({
                'd-none d-lg-table-cell': customConfig.hideMobile,
              })}
            >
              <div className="Table-headCell">
                {title}

                {customConfig?.orderable === false ? null : (
                  <SortButtons sortId={id} />
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

const TableBody: FC = () => {
  const {
    config,
    columns,
    rows,
    state: { loaded, error },
  } = useTableContext()
  const numCols = columns.length

  return (
    <TableStateHandler
      loaded={loaded}
      error={error}
      numCols={numCols}
      fallback={
        <TablePlaceholder
          columns={numCols}
          rows={config?.numRows ?? DEFAULT_NUM_ROWS}
        />
      }
    >
      {!rows?.length ? (
        <TableBodyWrapper numCols={numCols}>
          <EmptyList title="empty-list.title" />
        </TableBodyWrapper>
      ) : (
        <tbody>{rows?.map((row, i) => <TableRow key={i} row={row} />)}</tbody>
      )}
    </TableStateHandler>
  )
}

const TableRow: FC<TableRowProps> = ({ row }) => {
  const { config } = useTableContext()
  return (
    <tr>
      {Object.entries(row).map(([id, cell]) => {
        const customConfig = getColumnConfig(id, config?.columns)
        return (
          <TableCell key={id} hideMobile={customConfig.hideMobile}>
            {cell}
          </TableCell>
        )
      })}
    </tr>
  )
}

const TablePlaceholder: FC<TablePlaceholderProps> = ({ columns, rows }) => {
  const { innerWidth } = useViewport()
  const mobileCols = 2
  const configColSize = {
    min: 4,
    max: 12,
  }

  const columnsLength = innerWidth <= Responsive.LG ? mobileCols : columns
  const tableRows = Array.from({ length: rows }, () => {
    return Array.from({ length: columnsLength }, () => ({
      xs: getRandomNumber(configColSize),
    }))
  })

  return (
    <tbody>
      {tableRows.map((tds, trIndex) => (
        <tr key={trIndex + 1}>
          {tds.map((td, tdIndex) => {
            return (
              <td style={{ cursor: 'wait' }} key={tdIndex}>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={td.xs} />
                </Placeholder>
              </td>
            )
          })}
        </tr>
      ))}
    </tbody>
  )
}

const TableCell: FC<PropsWithChildren & { hideMobile?: boolean }> = ({
  children,
  hideMobile = false,
}) => (
  <td
    className={clsx('align-middle', {
      'd-none d-lg-table-cell': hideMobile,
    })}
  >
    {children}
  </td>
)

const TableAvatar: FC<ImageProps & RefAttributes<HTMLImageElement>> = props => (
  <Image
    className="Table-avatar"
    {...props}
    roundedCircle
    onError={e => {
      e.currentTarget.src = avatarPlaceholder
    }}
  />
)

const TableActions: FC<PropsWithChildren> = ({ children }) => (
  <div className="d-flex gap-2">{children}</div>
)

const TableButton: FC<TableButtonProps> = ({ children, onClick }) => (
  <button className="btn btn-secondary btn-sm fs-sm" onClick={onClick}>
    {children}
  </button>
)

const TablePill: FC<{ status: CaseStatus }> = ({ status }) => (
  <StatusBadge status={status} />
)

const TableLink: FC<PropsWithChildren & LinkProps> = ({ to, children }) => (
  <Link to={to} className="Table-link">
    {children}
  </Link>
)

export const Table = Object.assign(TableContainer, {
  Row: TableRow,
  Actions: TableActions,
  Button: TableButton,
  Avatar: TableAvatar,
  Pill: TablePill,
  Link: TableLink,
})
