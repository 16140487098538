import profileService from '@app/services/profile/profile.service'
import { Photo } from '@app/services/profile/types'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import {
  hideLoader,
  setUserDetail,
  showLoader,
  showUploadImageAction,
} from '@app/store/slices'
import { useCallback, useMemo, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'

export const useUploadImage = () => {
  const [files, setFiles] = useState<Photo[]>([])
  const { isLoaded } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map(file => {
      const photo: Photo = {
        path: file.name,
        preview: URL.createObjectURL(file),
        name: file.name,
      }
      return photo
    })
    setFiles(newFiles)
  }, [])

  const withFiles = useMemo(() => {
    return files.length
  }, [files])

  const filesAccept = {
    'image/jpeg': [],
    'image/png': [],
  }

  const sendFile = useCallback(
    async (fileCrop: FormData) => {
      dispatch(showLoader())
      try {
        const response = await profileService.uploadImageProfile(fileCrop)
        dispatch(setUserDetail(response.data))
        toastSuccess(t('profile.success.upload-image'))
      } catch (error) {
        toastError(t('profile.errors.upload-image'))
      } finally {
        dispatch(hideLoader())
        dispatch(showUploadImageAction(false))
      }
    },
    [dispatch, t, toastError, toastSuccess],
  )

  return {
    withFiles,
    files,
    filesAccept,
    onDrop,
    sendFile,
    sending: !isLoaded,
  }
}
