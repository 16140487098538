import { Main, StateHandler } from '@app/components'
import { useBillingAddressDetail, usePlaces, useToast } from '@app/hooks'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BillingAddressForm } from '../shared/billing-address-form/BillingAddressForm'
import {
  BillingAddressRequest,
  UpdateBillingAddressRequest,
} from '@app/services/billing-address/types'
import { useTranslation } from 'react-i18next'
import billingAddService from '@app/services/billing-address/billing-address.service'

export const BillingAddressEditPage = () => {
  const { billingAddressId } = useParams()
  const { toastError, toastSuccess } = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    state: {
      loaded: loadedBillingAddress,
      error: errorBillingAddress,
      billingAddress,
    },
  } = useBillingAddressDetail(billingAddressId!)
  const {
    countriesState: {
      countries,
      loaded: loadedCountries,
      error: errorCountries,
    },
    provincesState: {
      provinces,
      loaded: loadedProvinces,
      error: errorProvinces,
    },
    citiesState: { cities, loaded: loadedCities, error: errorCities },
    getProvinces,
    getCities,
  } = usePlaces(
    billingAddress?.city.province.country.id,
    billingAddress?.city.province.id,
  )

  const loaded = useMemo(
    () =>
      loadedBillingAddress &&
      loadedCountries &&
      loadedProvinces &&
      loadedCities,
    [loadedBillingAddress, loadedCountries, loadedProvinces, loadedCities],
  )

  const error = useMemo(
    () =>
      errorBillingAddress || errorCountries || errorProvinces || errorCities,
    [errorCountries, errorProvinces, errorCities, errorBillingAddress],
  )

  const handleSubmit = useCallback(
    async (values: BillingAddressRequest) => {
      const request: UpdateBillingAddressRequest = {
        ...values,
        id: billingAddressId!,
      }
      try {
        await billingAddService.updateBillingAddress(request)
        navigate(`../${billingAddressId}`)
        toastSuccess(t('billing-address.success.update'))
      } catch (error) {
        toastError(t('billing-address.errors.update'))
      }
    },
    [billingAddressId, navigate, t, toastError, toastSuccess],
  )

  return (
    <div>
      <Main
        ariaLabelledby="billing-address-edit-page"
        className="BillingAddressEditPage"
      >
        <StateHandler loaded={loaded} error={error}>
          <BillingAddressForm
            countries={countries!}
            provinces={provinces!}
            cities={cities!}
            getProvinces={getProvinces}
            getCities={getCities}
            onSubmit={handleSubmit}
            billingAddress={billingAddress!}
          />
        </StateHandler>
      </Main>
    </div>
  )
}
