import { BillingAddressDetail } from '@app/services/billing-address/types'
import { BillingAddressFormFields } from './types'

export function loadValuesBillingAddress(
  billingAddress: BillingAddressDetail,
): BillingAddressFormFields {
  return {
    name: billingAddress.name ?? '',
    vatNumber: billingAddress.vatNumber ?? '',
    phone: billingAddress.phone ?? '',
    email: billingAddress.email ?? '',
    address: billingAddress.address ?? '',
    addressNumber: billingAddress.addressNumber ?? '',
    addressExtra: billingAddress.addressExtra ?? '',
    postalCode: billingAddress.postalCode ?? '',
    country: billingAddress.city?.province.country.id ?? '',
    province: billingAddress.city?.province.id ?? '',
    city: billingAddress.city?.id ?? '',
    accountNumber: billingAddress.accountNumber ?? '',
  }
}
