import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import userManager from '../../../config/authConfig'
import Spinner from '../../components/spinner/Spinner'
import { useAppDispatch } from '@app/store/hooks'
import { useToast } from '@app/hooks'
import { useTranslation } from 'react-i18next'
import { setUserDetail, setUserToken } from '@app/store/slices'
import profileService from '@app/services/profile/profile.service'
import {
  setIsLoggingIn,
  setIsSessionChecked,
} from '@app/store/slices/authLocalStorage/authLocalStorageSlice'
import throttle from 'lodash.throttle'

const Callback: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { toastError } = useToast()
  const { t } = useTranslation()

  const handleCallback = throttle(async () => {
    try {
      const user = await userManager.signinRedirectCallback()
      dispatch(setUserToken(user.access_token))

      const userProfile = await profileService.getUserProfile()
      dispatch(setUserDetail(userProfile.data))

      dispatch(setIsLoggingIn(false))
      dispatch(setIsSessionChecked(false))

      navigate('/dashboard')
    } catch (error) {
      console.error('Error during sign-in callback:', error)
      dispatch(setIsLoggingIn(false))
      dispatch(setIsSessionChecked(false))
    }
  }, 300)

  useEffect(() => {
    handleCallback()
  }, [navigate, dispatch, toastError, t, handleCallback])

  return <Spinner />
}

export default Callback
