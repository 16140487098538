import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import {
  Clinic,
  ClinicBase,
  ClinicsPaginationQuery,
  UpdateClinicRequest,
  CreateClinicRequest,
} from './types'
import { PaginatedResult, ResponseData } from '@app/core/types'
import { mapFiltersToRequest } from '@app/utils'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'

const getBaseUrl = (config: IConfigService) => `${config.baseUrl}/clinics`

const getClinics =
  (config: IConfigService, http: IHttpClient) =>
  async (
    query: ClinicsPaginationQuery,
  ): Promise<PaginatedResult<ClinicBase>> => {
    const params = mapFiltersToRequest(query)
    try {
      return await http.get(`${getBaseUrl(config)}?${params}`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const getClinic =
  (config: IConfigService, http: IHttpClient) =>
  async (id: string): Promise<Clinic> => {
    try {
      const { data } = await http.get<ResponseData<Clinic>>(
        `${getBaseUrl(config)}/${id}`,
      )

      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const updateClinic =
  (config: IConfigService, http: IHttpClient) =>
  async (clinic: UpdateClinicRequest): Promise<void> => {
    return await http.put(`${getBaseUrl(config)}/${clinic.id}`, clinic)
  }

const createClinic =
  (config: IConfigService, http: IHttpClient) =>
  async (clinic: CreateClinicRequest): Promise<string> => {
    const { data } = await http.post<
      CreateClinicRequest,
      ResponseData<{ id: string }>
    >(`${getBaseUrl(config)}/`, clinic)

    return data.id
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getClinics: getClinics(config, http),
  getClinic: getClinic(config, http),
  updateClinic: updateClinic(config, http),
  createClinic: createClinic(config, http),
})

export default factory(config, http)
