import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface AuthLocalStorageState {
  isLoggingIn: boolean
  isSessionChecked: boolean
}

const initialState: AuthLocalStorageState = {
  isLoggingIn: localStorage.getItem('isLoggingIn') === 'true',
  isSessionChecked: localStorage.getItem('isSessionChecked') === 'true',
}

export const authLocalStorageSlice = createSlice({
  name: 'authLocalStorage',
  initialState,
  reducers: {
    setIsLoggingIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggingIn = action.payload
      localStorage.setItem('isLoggingIn', action.payload.toString())
    },
    setIsSessionChecked: (state, action: PayloadAction<boolean>) => {
      state.isSessionChecked = action.payload
      localStorage.setItem('isSessionChecked', action.payload.toString())
    },
  },
})

export const { setIsLoggingIn, setIsSessionChecked } =
  authLocalStorageSlice.actions

export default authLocalStorageSlice.reducer
