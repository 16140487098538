import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { Place } from './types'
import { ResponseData } from '@app/core/types'
import { IHttpClient } from '@app/core/http'

const PER_PAGE = 100

const getCountries =
  (config: IConfigService, http: IHttpClient) => async (): Promise<Place[]> => {
    const response = await http.get<ResponseData<Place[]>>(
      `${config.baseUrl}/countries?perPage=${PER_PAGE}`,
    )

    return response?.data
  }

const getProvinces =
  (config: IConfigService, http: IHttpClient) =>
  async (countryId: string): Promise<Place[]> => {
    const response = await http.get<ResponseData<Place[]>>(
      `${config.baseUrl}/provinces?perPage=${PER_PAGE}&filter[country]=${countryId}`,
    )

    return response?.data
  }

const getCities =
  (config: IConfigService, http: IHttpClient) =>
  async (provinceId: string): Promise<Place[]> => {
    const response = await http.get<ResponseData<Place[]>>(
      `${config.baseUrl}/cities?filter[province]=${provinceId}`,
    )

    return response?.data
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getCountries: getCountries(config, http),
  getProvinces: getProvinces(config, http),
  getCities: getCities(config, http),
})

export default factory(config, http)
