import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

type MainProps = PropsWithChildren & {
  ariaLabelledby: string
  className?: string
}
export const Main: FC<MainProps> = ({
  children,
  ariaLabelledby,
  className,
}) => {
  return (
    <main aria-labelledby={ariaLabelledby} className={clsx('Main', className)}>
      {children}
    </main>
  )
}
