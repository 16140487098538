import React, { forwardRef, useCallback } from 'react'
import { clsx } from 'clsx'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'
import { useTranslation } from 'react-i18next'
import Select, { SelectInstance } from 'react-select'

import './SelectControl.scss'

export type SelectOption = {
  value: string | boolean
  label: string
}

type SelectControlProps<T extends FieldValues> = {
  options?: SelectOption[]
  disabled?: boolean
  readonly?: boolean
  className?: string
  loading?: boolean
  clearable?: boolean
  searchable?: boolean
  onChange?: (v: string | boolean) => void
  selectRef?: React.Ref<SelectInstance<SelectOption, false>>
} & FormControlWrapperProps<T>

export const SelectControl = forwardRef(function <T extends FieldValues>({
  warning,
  name,
  required,
  hiddenLabel,
  id,
  label,
  slotLabel,
  options = [],
  disabled,
  loading,
  clearable = true,
  searchable = true,
  onChange,
  selectRef,
}: SelectControlProps<T>): JSX.Element {
  const methods = useFormContext<T>()
  const { t } = useTranslation()

  const { control } = methods
  const prefixedId = id ?? `form-${name}`

  const getSelectedValue = useCallback(
    (value: string) => {
      return options?.find(o => o.value === value) ?? null
    },
    [options],
  )

  // If MsEdge's translation feature is active and react-select uses aria live
  // messaging, the React app crashes when the dropdown menu is closed. Because
  // of this we disable aria live messaging when MsEdge's translation feature is
  // active.
  // https://github.com/JedWatson/react-select/issues/5816
  const emptyAriaLiveMessages = {
    guidance: () => '',
    onChange: () => '',
    onFilter: () => '',
    onFocus: () => ''
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControlWrapper
          error={fieldState.error}
          warning={warning}
          name={field.name}
          slotLabel={slotLabel}
          hiddenLabel={hiddenLabel}
          label={label}
          id={prefixedId}
        >
          <Select
            ariaLiveMessages={emptyAriaLiveMessages}
            className={clsx(`SelectControl SelectControl-container`, {
              'is-invalid': fieldState.error,
              'has-warning': warning,
            })}
            classNamePrefix={'SelectControl'}
            inputId={prefixedId}
            name={name}
            isDisabled={disabled}
            isLoading={loading}
            value={getSelectedValue(field.value)}
            required={required}
            placeholder={
              loading
                ? t('common.select-loading', { label })
                : t('common.select-placeholder')
            }
            noOptionsMessage={() => <>{t('empty-list.title')}</>}
            isClearable={clearable}
            isSearchable={searchable}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            options={options}
            onChange={v => {
              field.onChange((v as SelectOption)?.value ?? null)
              if (onChange) onChange((v as SelectOption)?.value)
            }}
            onBlur={field.onBlur}
            ref={instance => {
              field.ref(instance)
              if (selectRef) {
                if (typeof selectRef === 'function') {
                  selectRef(instance)
                } else {
                  ;(
                    selectRef as React.MutableRefObject<SelectInstance<
                      SelectOption,
                      false
                    > | null>
                  ).current = instance
                }
              }
            }}
          />
        </FormControlWrapper>
      )}
    />
  )
})
