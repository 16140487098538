import { State } from '@app/core/types'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import treatmentPlansService from '@app/services/treatment-plans/treatment-plans.service'
import {
  AcceptanceRequest,
  TreatmentPlan,
} from '@app/services/treatment-plans/types'
import { ApiForbiddenError } from '@app/core/api-errors'
import { setTreatmentStatusToAccepted } from './utils'
import { useAppDispatch } from '@app/store/hooks'
import { reloadCaseAction } from '@app/store/slices/case/caseSlice'

type TreatmentPlansState = State<TreatmentPlan[], 'treatmentPlans'>

const initialState: TreatmentPlansState = {
  loaded: false,
  treatmentPlans: null,
}

type UseTreatmentPlansType = {
  state: TreatmentPlansState
  selectedPlan: TreatmentPlan | undefined
  withVisorUrl: boolean
  setSelectedPlan: Dispatch<SetStateAction<TreatmentPlan | undefined>>
  changeTreatmentAcceptance: (
    caseId: string,
    treatmentId: string,
    request: AcceptanceRequest,
  ) => Promise<void>
}

export const useTreatmentPlans = (
  treatmentPlanId?: string,
): UseTreatmentPlansType => {
  const [state, setState] = useState<TreatmentPlansState>(initialState)
  const [selectedPlan, setSelectedPlan] = useState<TreatmentPlan | undefined>()
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const loadTreatmentPlans = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const { data: treatmentPlans } =
          await treatmentPlansService.getTreatments(id)
        setState(() => ({
          loaded: true,
          treatmentPlans,
        }))
        const lastTreatmentPlan = treatmentPlans[treatmentPlans.length - 1]
        setSelectedPlan(lastTreatmentPlan)
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          treatmentPlans: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  const changeTreatmentAcceptance = useCallback(
    async (caseId: string, treatmentId: string, request: AcceptanceRequest) => {
      try {
        await treatmentPlansService.changeTreatmentAcceptance(
          caseId,
          treatmentId,
          request,
        )
        dispatch(reloadCaseAction(true))

        setSelectedPlan(prevState => setTreatmentStatusToAccepted(prevState!))

        setState(prevState => {
          let selected = prevState.treatmentPlans!.find(
            plan => plan.id === selectedPlan?.id,
          )
          selected = setTreatmentStatusToAccepted(selected!)

          return {
            ...prevState,
            ...selected,
          }
        })

        toastSuccess(t(`cases.${request.result}.feedback.success`))
      } catch (e) {
        if (e instanceof ApiForbiddenError) {
          toastError(t(`cases.${request.result}.feedback.permissions-error`))
          return
        }
        toastError(t(`cases.${request.result}.feedback.error`))
      }
    },
    [dispatch, selectedPlan?.id, t, toastError, toastSuccess],
  )

  useEffect(() => {
    if (!treatmentPlanId) {
      return
    }
    loadTreatmentPlans(treatmentPlanId)
  }, [loadTreatmentPlans, treatmentPlanId])

  const withVisorUrl = useMemo(() => {
    if (
      selectedPlan &&
      (!selectedPlan.visorUrl || selectedPlan.visorUrl === '')
    ) {
      return false
    }
    return true
  }, [selectedPlan])

  return {
    state,
    selectedPlan,
    withVisorUrl,
    setSelectedPlan,
    changeTreatmentAcceptance,
  }
}
