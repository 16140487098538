import { State } from '@app/core/types'
import placesService from '@app/services/places/places.service'
import { Place } from '@app/services/places/types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type CountriesState = State<Place[], 'countries'>

const initialState: CountriesState = {
  countries: [],
  loaded: false,
}

export const fetchCountries = createAsyncThunk(
  'countries',
  async (_, { rejectWithValue }) => {
    try {
      return await placesService.getCountries()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const { name, reducer, actions } = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCountries.fulfilled, (_, action) => {
        return {
          loaded: true,
          countries: action.payload,
        }
      })
      .addCase(fetchCountries.pending, () => {
        return {
          loaded: false,
          countries: [],
        }
      })
      .addCase(fetchCountries.rejected, (_, e) => {
        return {
          ...initialState,
          loaded: true,
          error: e.payload as Error,
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const countriesStore = { name, reducer, actions }

export default reducer
