import {
  Case,
  CaseCutType,
  CasePatientProblem,
  CaseSpecificInfoDetails,
  CaseTargetType,
  CaseType,
  PatientProblemRequest,
  SpecificInfoRequest,
} from '@app/services/cases/types'
import { Prescription } from '@app/services/prescription/types'
import { PatientProblemForm, SpecificInfoFields } from '../types'
import { removeNullValuesFromRequest } from '@app/utils'

export function specificInfoCoerceValues(
  caseData: Case,
  problems: Prescription[] = [],
) {
  return {
    specific: {
      cutType:
        caseData?.specificInfo?.details.cutType ??
        (undefined as unknown as CaseCutType),
      secretRetainers: {
        requestOnFinish:
          caseData?.specificInfo?.details.secretRetainers?.requestOnFinish ??
          (undefined as unknown as boolean),
        archs:
          caseData?.specificInfo?.details.secretRetainers?.archs ?? undefined,
      },
      useElastics: caseData?.specificInfo?.details.useElastics,
      distalize: caseData?.specificInfo?.details.distalize,
      thirdMolarExtraction:
        caseData?.specificInfo?.details.thirdMolarExtraction,
      target:
        caseData?.specificInfo?.treatmentTarget.target ??
        (undefined as unknown as CaseTargetType),
      patientProblems: coercePatientProblem(
        caseData?.specificInfo?.treatmentTarget.patientProblems,
        caseData.type === CaseType.Tailor ? problems : undefined,
      ),
      indications: caseData?.specificInfo?.indications ?? '',
    },
  }
}

export function coercePatientProblem(
  patientProblems: CasePatientProblem[] = [],
  problems?: Prescription[],
): PatientProblemForm[] {
  if (!problems?.length) {
    return []
  }
  const result = problems.reduce((acc, current) => {
    const currentPatientProblem = patientProblems.find(
      value => value.problemId === current.id,
    )

    const item: PatientProblemForm = {
      problemId: current.id.toString(),
      solutionId: currentPatientProblem?.solutionId
        ? currentPatientProblem?.solutionId.toString()
        : '',
      otherSolution: currentPatientProblem?.otherSolution ?? '',
      value: !patientProblems.length ? false : !!currentPatientProblem,
    }

    return [...acc, item]
  }, [] as PatientProblemForm[])

  return result
}

export function mapSpecificInfoValuesForRequest(
  formValues: SpecificInfoFields,
): SpecificInfoRequest {
  const details: CaseSpecificInfoDetails = {
    cutType: formValues.specific.cutType,
    secretRetainers: formValues.specific.secretRetainers,
    useElastics: formValues.specific.useElastics,
    distalize: formValues.specific.distalize,
    thirdMolarExtraction: formValues.specific.thirdMolarExtraction,
  }

  return {
    specificInfo: {
      details: removeNullValuesFromRequest(details),
      treatmentTarget: {
        target: formValues.specific.target,
        patientProblems: mapPatientProblemsForRequest(
          formValues.specific.patientProblems,
        ),
      },
      indications: formValues.specific.indications,
    },
  }
}

function mapPatientProblemsForRequest(
  problems: PatientProblemForm[],
): PatientProblemRequest[] {
  return problems
    .filter(problem => !!problem.value)
    .map(problem => ({
      problemId: Number(problem.problemId),
      solutionId: Number(problem.solutionId),
      otherSolution: problem.otherSolution,
    }))
}
