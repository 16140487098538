import { ButtonAsync, Card } from '@app/components'
import { useFiltersContext } from '@app/components/filters/context'
import { InvoicesFiltersFormFields } from './types'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { DatepickerControl, Form } from '@app/components/forms'
import { useTranslation } from 'react-i18next'
import { CiTrash } from 'react-icons/ci'
import { cloneObjectDeep } from '@app/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { buildValidationSchema } from './validation'

const defaultValues = {
  createdAt: {
    from: '',
    to: '',
  },
}

export const InvoicesFiltersForm = () => {
  const { t } = useTranslation()
  const { setFilters } = useFiltersContext()

  const methods = useForm<InvoicesFiltersFormFields>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(buildValidationSchema()),
  })
  const {
    trigger,
    reset,
    formState: { isSubmitting, isDirty },
  } = methods

  const handleSubmitForm: SubmitHandler<InvoicesFiltersFormFields> = async (
    formValues: InvoicesFiltersFormFields,
  ) => {
    const filtersRequest = cloneObjectDeep(formValues)
    setFilters(filtersRequest)
  }
  return (
    <div className="InvoicesFiltersForm">
      <FormProvider {...methods}>
        <Form onValid={handleSubmitForm}>
          <Card className="mb-3">
            <Card.Body className="pb-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <DatepickerControl<InvoicesFiltersFormFields>
                    name={`createdAt.from`}
                    label={t('cases.from')}
                    onChange={() => trigger(`createdAt.to`)}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <DatepickerControl<InvoicesFiltersFormFields>
                    name={`createdAt.to`}
                    label={t('cases.to')}
                    onChange={() => trigger(`createdAt.from`)}
                  />
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="CasesFiltersForm-footer">
              <button
                className="btn btn-secondary"
                onClick={() => reset(defaultValues)}
              >
                <CiTrash />
                {t('filters.clear')}
              </button>
              <ButtonAsync
                className="btn btn-primary"
                disabled={!isDirty}
                isSubmitting={isSubmitting}
              >
                {t('filters.filter')}
              </ButtonAsync>
            </Card.Footer>
          </Card>
        </Form>
      </FormProvider>
    </div>
  )
}
