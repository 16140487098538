import { FC, PropsWithChildren } from 'react'

type TableBodyWrapperProps = PropsWithChildren & {
  numCols: number
}
export const TableBodyWrapper: FC<TableBodyWrapperProps> = ({
  numCols,
  children,
}) => {
  return (
    <tbody>
      <tr>
        <td colSpan={numCols}>{children}</td>
      </tr>
    </tbody>
  )
}
