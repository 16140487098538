import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { FieldPath, FieldValues } from 'react-hook-form'

import './FormControlWrapper.scss'

type FeedbackMessage = { message?: string }

export type FormControlWrapperProps<T extends FieldValues> = {
  name: FieldPath<T>
  label?: string | JSX.Element
  id?: string
  required?: boolean
  slotLabel?: string
  className?: string
  helperText?: string
  error?: FeedbackMessage
  warning?: FeedbackMessage
  hiddenLabel?: boolean
}

export const FormControlWrapper = function <T extends FieldValues>({
  name,
  className,
  children,
  helperText,
  error,
  warning,
  required,
  hiddenLabel,
  label,
  slotLabel,
  id,
}: PropsWithChildren & FormControlWrapperProps<T>) {
  return (
    <div
      id={`form-control-wrapper--${name}`}
      className={clsx('FormControlWrapper', className)}
    >
      {label && (
        <div className="form-label-wrapper">
          <label
            className={clsx('form-label', {
              required,
              'has-warning': warning,
              'visually-hidden': hiddenLabel,
            })}
            htmlFor={id}
          >
            {label}
          </label>
          {slotLabel && (
            <small className="FormControlWrapper-slotLabel">
              ({slotLabel})
            </small>
          )}
        </div>
      )}
      <div className="input-group">
        {children}

        {helperText && <div className="form-text">{helperText}</div>}

        {(error || warning) && (
          <FormControlFeedback
            level={error?.message ? 'error' : 'warning'}
            message={(error?.message as string) || (warning?.message as string)}
          />
        )}
      </div>
    </div>
  )
}

type FormControlFeedbackProps = {
  message: string
  level: 'warning' | 'error'
}

export const FormControlFeedback: FC<FormControlFeedbackProps> = ({
  message,
  level,
}) => {
  if (!message) {
    return null
  }

  return (
    <>
      {(() => {
        switch (level) {
          case 'warning':
            return (
              <div className="FormControlWrapper-feedback warning-feedback">
                <small>{message}</small>
              </div>
            )
          case 'error':
            return (
              <div className="FormControlWrapper-feedback invalid-feedback">
                <small>{message}</small>
              </div>
            )
          default:
            return null
        }
      })()}
    </>
  )
}
