import { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { CiSearch } from 'react-icons/ci'
import { SearchControlStyle } from '../shared/search-control-style/SearchControlStyle'

interface SearchControlProps {
  onSearch: (searchTerm: string) => void
  filterButton?: ReactElement
  withoutLink?: boolean
}

export const SearchControl: FC<SearchControlProps> = ({
  onSearch,
  filterButton,
  withoutLink,
}) => {
  const { t } = useTranslation()
  const [term, setTerm] = useState('')

  return (
    <SearchControlStyle>
      <div className="SearchControl">
        <div className="SearchControl-group">
          <span className="SearchControl-icon">
            <CiSearch />
          </span>
          <input
            type="text"
            className="form-control SearchControl-input"
            aria-label={t('search.title')}
            value={term}
            onChange={event => {
              setTerm(event.target.value)
              onSearch(event.target.value)
            }}
          />

          {filterButton && filterButton}
        </div>
        {!withoutLink && (
          <Link to={'./new'} className="btn btn-primary">
            <BsPlus />
            {t('cases.new')}
          </Link>
        )}
      </div>
    </SearchControlStyle>
  )
}
