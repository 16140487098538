import { AlertMessage } from '@app/components'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'

import './AlertClinics.scss'
import { useClinics } from '@app/hooks'
import { Link } from 'react-router-dom'
import { INIT_QUERY_PAGE } from '@app/utils'
import { ClinicsPaginationQuery } from '@app/services/clinics/types'
import { CiWarning } from 'react-icons/ci'

interface AlertClinicsType {
  className?: string
}

const customQuery: ClinicsPaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: 5,
  filter: { withoutLogo: true },
}

export const AlertClinics: FC<AlertClinicsType> = ({ className }) => {
  const { t } = useTranslation()
  const {
    state: { clinics, loaded },
  } = useClinics(customQuery)

  if (!loaded || !clinics?.length) {
    return null
  }

  return (
    <AlertMessage className={clsx(className, 'AlertClinics')}>
      <CiWarning />{' '}
      <Trans
        i18nKey={t('dashboard.alert-clinics')}
        components={[<Link key="" to={'/clinics'} />]}
      />
    </AlertMessage>
  )
}
