import { Case } from '@app/services/cases/types'
import { CaseStep, CaseCreateFormFields } from '../../shared/form-steps/types'
import { generalInfoCoerceValues } from '../../shared/form-steps/2-general-info/utils'

export function coerceValues(caseData?: Case): CaseCreateFormFields {
  const generalInfoCoerce = generalInfoCoerceValues(caseData)

  return {
    currentStep: CaseStep.Type,
    ...generalInfoCoerce,
  }
}
