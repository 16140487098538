import {
  ButtonAsync,
  Card,
  Detail,
  EmptyList,
  StateHandler,
} from '@app/components'
import { useCaseDocumentation } from '@app/hooks/cases'
import { CiFileOn, CiSaveDown2 } from 'react-icons/ci'

import './CaseDetailDocu.scss'

interface DocumentationPageProps {
  idCase: string
}
export const CaseDetailDocu = ({ idCase }: DocumentationPageProps) => {
  return (
    <>
      <Card.Body size={'sm'} className="CaseDetailDocu">
        <Detail.Section>
          <DocumentationContent idCase={idCase} />
        </Detail.Section>
      </Card.Body>
    </>
  )
}

const DocumentationContent = ({ idCase }: DocumentationPageProps) => {
  const {
    state: { docu: docuList, loaded, error, submitDownload },
    downloadDocu,
  } = useCaseDocumentation(idCase)
  const isDownloading = (docId: string) => submitDownload === docId

  return (
    <>
      <StateHandler loaded={loaded} error={error}>
        {!docuList?.length ? (
          <EmptyList title="empty-list.title" />
        ) : (
          <>
            <div className="col-12">
              {docuList.map(doc => (
                <div className="CaseDetailDocu-contain" key={doc.key}>
                  <div>
                    <CiFileOn />
                    <span>{doc.name}</span>
                  </div>
                  <ButtonAsync
                    className="btn btn-secondary btn-sm"
                    onClick={() => downloadDocu(doc.key)}
                    isSubmitting={isDownloading(doc.key)}
                  >
                    {!isDownloading(doc.key) && <CiSaveDown2 />}
                  </ButtonAsync>
                </div>
              ))}
            </div>
          </>
        )}
      </StateHandler>
    </>
  )
}
