import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import { ResponseData } from '@app/core/types'
import { CommentChat, RequestAsRead, RequestMessage } from './types'

const getBaseUrl = (config: IConfigService, caseId: string) =>
  `${config.baseUrl}/product-cases/${caseId}/internal-chat`

const getListComments =
  (config: IConfigService, http: IHttpClient) =>
  async (productCaseId: string): Promise<CommentChat[]> => {
    try {
      const { data } = await http.get<ResponseData<CommentChat[]>>(
        `${getBaseUrl(config, productCaseId)}`,
      )
      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const sendComment =
  (config: IConfigService, http: IHttpClient) =>
  async (params: RequestMessage): Promise<void> => {
    const comment = { body: params.body, requestChanges: params.requestChanges }
    try {
      await http.post<object, void>(
        `${getBaseUrl(config, params.caseId)}`,
        comment,
      )
    } catch (e) {
      throw errorFactory(e)
    }
  }

const changeAsRead =
  (config: IConfigService, http: IHttpClient) =>
  async (params: RequestAsRead): Promise<void> => {
    return await http.patch(
      `${getBaseUrl(config, params.caseId)}/${params.lastMessageId}/mark-as-read`,
      {},
    )
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getListComments: getListComments(config, http),
  sendComment: sendComment(config, http),
  changeAsRead: changeAsRead(config, http),
})

export default factory(config, http)
