import esJSON from './es.json'
import enJSON from './en.json'
import ptJSON from './pt.json'
import frJSON from './fr.json'
import itJSON from './it.json'
import deJSON from './de.json'

const translations = {
  es: esJSON,
  en: enJSON,
  pt: ptJSON,
  fr: frJSON,
  it: itJSON,
  de: deJSON,
}

export default translations
