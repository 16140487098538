import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './ActionsNotAccepted.scss'
import {
  AcceptanceRequest,
  PlanAcceptance,
  TreatmentPlan,
} from '@app/services/treatment-plans/types'
import { ButtonAsync, useConfirmContext } from '@app/components'
import { reloadCaseAction } from '@app/store/slices/case/caseSlice'
import { useAppDispatch } from '@app/store/hooks'
import { RequestChangesModal } from './request-changes/RequestChangesModal'

interface ActionsNotAcceptedProps {
  onChange: (
    caseId: string,
    treatmentId: string,
    request: AcceptanceRequest,
  ) => Promise<void>
  selectedPlan: TreatmentPlan
}
export const ActionsNotAccepted: FC<ActionsNotAcceptedProps> = ({
  onChange,
  selectedPlan,
}) => {
  const { t } = useTranslation()
  const confirm = useConfirmContext()
  const dispatch = useAppDispatch()
  const [showRequestChangesModal, setShowRequestChangesModal] = useState(false)

  if (!selectedPlan?.permissions?.acceptance) {
    return null
  }

  const acceptModal = {
    title: t('cases.accepted.modal.title'),
    content: (
      <div className="d-flex gap-3 flex-column">
        <div>{t('cases.accepted.modal.subtitle')}</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {t('cases.name')}: <strong>{selectedPlan.name}</strong>
          </li>
          <li className="list-group-item">
            {t('cases.upper-aligners')}:{' '}
            <strong>{selectedPlan.details.upperAligners}</strong>
          </li>
          <li className="list-group-item">
            {t('cases.lower-aligners')}:{' '}
            <strong>{selectedPlan.details.lowerAligners}</strong>
          </li>
        </ul>
        <div>{t('cases.accepted.modal.body')}</div>
      </div>
    ),
  }

  const rejectModal = {
    title: t('cases.rejected.modal.title'),
    content: (
      <div className="d-flex gap-3 flex-column">
        <div>{t('cases.rejected.modal.body')}</div>
      </div>
    ),
    cancelActionText: t('cases.rejected.modal.button.cancel'),
    confirmActionText: t('cases.rejected.modal.button.accept'),
  }

  const handleSubmit = async (request: AcceptanceRequest) => {
    return await onChange(selectedPlan.caseId, selectedPlan.id, request)
  }

  const handleOnClick = async (requestType: PlanAcceptance) => {
    const request = {
      result: requestType,
    }

    const modalContent = (type: PlanAcceptance) => {
      switch (type) {
        case PlanAcceptance.Accepted:
          return acceptModal
        case PlanAcceptance.Rejected:
          return rejectModal
      }
    }

    const { isConfirmed } = await confirm(modalContent(requestType)!)

    if (isConfirmed) {
      await handleSubmit(request)
      dispatch(reloadCaseAction(true))
    }
  }

  return (
    <>
      <div className="ActionsNotAccepted">
        <ButtonAsync
          className="btn btn-secondary"
          onClick={() => handleOnClick(PlanAcceptance.Rejected)}
        >
          {t('cases.rejected.button')}
        </ButtonAsync>
        <button
          className="btn btn-secondary"
          onClick={() => setShowRequestChangesModal(true)}
        >
          {t('cases.requestedChanges.button')}
        </button>
        <ButtonAsync
          className="btn btn-primary"
          onClick={() => handleOnClick(PlanAcceptance.Accepted)}
        >
          {t('cases.accepted.button')}
        </ButtonAsync>
      </div>

      {showRequestChangesModal && (
        <RequestChangesModal
          showModal={showRequestChangesModal}
          setShowModal={setShowRequestChangesModal}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}
