import { CaseArcadeType, CasesTeethNotMove } from '@app/services/cases/types'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FIRST_BOTTOM_TOOTH,
  FIRST_TOP_TOOTH,
  isToothBelongsToArcade,
} from '../utils'

export const useTeethSelect = (
  arcadeType: CaseArcadeType,
  selectedTeeth: CasesTeethNotMove[],
  setSelectedTeeth?: Dispatch<SetStateAction<CasesTeethNotMove[]>>,
) => {
  const { t } = useTranslation()

  const belongsToArcade = useCallback(
    (toothNumber: string) => isToothBelongsToArcade(toothNumber, arcadeType),
    [arcadeType],
  )

  const isTopArcade = belongsToArcade(FIRST_TOP_TOOTH)
  const isBottomArcade = belongsToArcade(FIRST_BOTTOM_TOOTH)

  const getFillColor = (toothId: string) =>
    selectedTeeth.find(tooth => tooth.number === toothId)
      ? '#64748B'
      : '#FFFFFF'

  const select = (toothId: string) => {
    if (setSelectedTeeth) {
      setSelectedTeeth?.(
        selectedTeeth.find(teeth => teeth.number === toothId)
          ? selectedTeeth.filter(t => t.number !== toothId)
          : selectedTeeth.concat([{ number: toothId }]),
      )
    } else {
      return () => {}
    }
  }

  const getAttributes = (toothId: string) => {
    return {
      'data-cy': toothId,
      'data-testid': toothId,
      fill: getFillColor(toothId),
      'aria-label': t('select-tooth', { tooth: toothId }),
      style: { cursor: `${setSelectedTeeth ? 'pointer' : 'normal'}` },
      onClick: () => select(toothId),
    }
  }

  return {
    isTopArcade,
    isBottomArcade,
    getAttributes,
  }
}
