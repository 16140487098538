import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import placeholderPicture from '@images/placeholder-image.jpg'
import Image from 'react-bootstrap/esm/Image'
import { SectionSubtitle } from '../section-subtitle/SectionSubtitle'
import { SectionTitle } from '../section-title/SectionTitle'
import { useTranslation } from 'react-i18next'
import { parsedCellValue } from './utils'

import './Detail.scss'

type DetailContainerType = PropsWithChildren & {
  className?: string
}

type DetailCompositionType = {
  Cell: FC
  Image: FC
  Section: FC
  Title: FC
  Side: FC
}

export type DetailCellProps = {
  label: string
  value?: string | number | boolean | null
  className?: string
} & Partial<PropsWithChildren>

interface DetailImageProps {
  src?: string
  circle?: boolean
}

const DetailContainer: FC<DetailContainerType> &
  Partial<DetailCompositionType> = ({ children, className }) => {
  return <div className={clsx('Detail', className)}>{children}</div>
}

const DetailSide: FC<DetailContainerType> & Partial<DetailCompositionType> = ({
  children,
  className,
}) => {
  return <div className={clsx('Detail-side', className)}>{children}</div>
}

const DetailCell: FC<DetailCellProps> = ({
  label,
  value,
  className = '',
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('col-12 col-md-6 Detail-cell', className)}>
      <span className="Detail-label">{label}</span>
      <div className="Detail-value">
        {children ?? parsedCellValue(value, t)}
      </div>
    </div>
  )
}

export const DetailImage: FC<DetailImageProps> = ({ src, circle }) => {
  return (
    <section>
      <Image
        width={148}
        height={148}
        className={circle ? 'Detail-image rounded-circle' : 'Detail-image'}
        src={src ?? placeholderPicture}
        rounded
      />
    </section>
  )
}

const DetailSection: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <section className={clsx('row Detail-section', className)}>
      {children}
    </section>
  )
}

const DetailTitle: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className="col-12">
      <SectionTitle className={clsx('mb-0', className)}>
        {children}
      </SectionTitle>
    </div>
  )
}

const DetailSubTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="col-12">
      <SectionSubtitle className="mb-0">{children}</SectionSubtitle>
    </div>
  )
}

export const Detail = Object.assign(DetailContainer, {
  Cell: DetailCell,
  Image: DetailImage,
  Section: DetailSection,
  Title: DetailTitle,
  SubTitle: DetailSubTitle,
  Side: DetailSide,
})
