import { FC, PropsWithChildren, ReactElement } from 'react'

import { State } from '@app/core/types'
import { Loader, TableBodyWrapper } from '@app/components/'
import { ApiForbiddenError, ApiNotFoundError } from '@app/core/api-errors'
import { AccessDenied, NotFound, UnknownError } from '@app/pages/errors'

type StateHandlerProps = Pick<State<unknown, ''>, 'loaded' | 'error'> &
  PropsWithChildren & {
    fallback?: ReactElement
  }

export const StateHandler: FC<StateHandlerProps> = ({
  loaded,
  error,
  children,
  fallback,
}) => {
  if (error) {
    return <ErrorHandler error={error} />
  }

  if (!loaded) {
    return fallback ?? <Loader />
  }

  return <>{children}</>
}

export const TableStateHandler: FC<StateHandlerProps & { numCols: number }> = ({
  loaded,
  error,
  children,
  fallback,
  numCols,
}) => {
  if (error) {
    return (
      <TableBodyWrapper numCols={numCols}>
        <ErrorHandler error={error} />
      </TableBodyWrapper>
    )
  }

  if (!loaded) {
    return fallback ?? <Loader />
  }

  return <>{children}</>
}

export const ErrorHandler: FC<{ error: Error }> = ({ error }) => {
  if (error instanceof ApiNotFoundError) {
    return <NotFound />
  }

  if (error instanceof ApiForbiddenError) {
    return <AccessDenied />
  }

  return <UnknownError />
}
