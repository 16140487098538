import { AnyObjectSchema, object } from 'yup'
import { TFunction } from 'i18next'
import { generalInfoValidationSchema } from '../../shared/form-steps/2-general-info/validation'
import { typeValidationSchema } from '../../shared/form-steps/1-type/validation'
import { CaseStep } from '../../shared/form-steps/types'

export function buildValidationSchema(
  t: TFunction,
  currentStep: CaseStep,
): AnyObjectSchema {
  const typeSchema = typeValidationSchema(t)
  const generalInfoSchema = generalInfoValidationSchema(t)

  const validationSchema: Record<CaseStep, AnyObjectSchema> = {
    [CaseStep.Type]: typeSchema,
    [CaseStep.General]: typeSchema.concat(generalInfoSchema),
    [CaseStep.Specific]: object(),
    [CaseStep.Files]: object(),
    [CaseStep.Radiographs]: object(),
    [CaseStep.Measures]: object(),
  }

  return validationSchema[currentStep]
}
