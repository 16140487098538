import { useCallback } from 'react'
import { ToastOptions, toast } from 'react-toastify'

const configuration: ToastOptions<unknown> = {
  position: 'top-center',
}

export const useToast = () => {
  const toastError = useCallback((message: string) => {
    toast.error(message, configuration)
  }, [])

  const toastSuccess = useCallback((message: string) => {
    toast.success(message, configuration)
  }, [])

  return {
    toastError,
    toastSuccess,
  }
}
