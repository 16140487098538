import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UIState {
  showModal: boolean
  showUploadImage: boolean
  showChangePass: boolean
  showAdditionalAligners: boolean
  showRefinement: boolean
  isFavorite: boolean
}

const getInitialState = () => {
  if (typeof localStorage === 'undefined') return {}
  const favorites = JSON.parse(localStorage.getItem('favorite') ?? '{}')
  return favorites
}

const initialState: UIState = {
  showModal: false,
  showUploadImage: false,
  showChangePass: false,
  showAdditionalAligners: false,
  showRefinement: false,
  isFavorite: getInitialState(),
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showModalAction: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
    changeFavoriteAction: (state, action: PayloadAction<boolean>) => {
      state.isFavorite = action.payload
    },
    showUploadImageAction: (state, action: PayloadAction<boolean>) => {
      state.showUploadImage = action.payload
    },
    showChangePassAction: (state, action: PayloadAction<boolean>) => {
      state.showChangePass = action.payload
    },
    showAdditionalAlignersAction: (state, action: PayloadAction<boolean>) => {
      state.showAdditionalAligners = action.payload
      state.showRefinement = false
    },
    showRefinementAction: (state, action: PayloadAction<boolean>) => {
      state.showRefinement = action.payload
      state.showAdditionalAligners = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  showModalAction,
  changeFavoriteAction,
  showUploadImageAction,
  showChangePassAction,
  showAdditionalAlignersAction,
  showRefinementAction,
} = uiSlice.actions

export default uiSlice.reducer
