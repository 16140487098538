import { object, string, AnyObjectSchema } from 'yup'

export function buildValidationSchema(): AnyObjectSchema {
  const PeriodSchema = object().shape({
    from: string(),
    to: string(),
  })

  return object().shape({
    createdAt: PeriodSchema.nullable(),
  })
}
