import download from 'downloadjs'

export const downloadDocument = (
  url?: string | null,
  filename?: string | null,
) => {
  if (!url) {
    return
  }

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      download(blob, filename || url?.split('/').pop() || 'download')
    })
    .catch(error => console.log(error))
}
