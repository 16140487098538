import { State } from '@app/core/types'
import { useToast } from '@app/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import docuService from '@app/services/documentation/documentation.service'
import { DocumentationBase } from '@app/services/documentation/types'

type DocumentationState = State<DocumentationBase[], 'docu'> & {
  error?: Error
  submitDownload?: string
}

const initialState: DocumentationState = {
  loaded: false,
  submitDownload: undefined,
  docu: null,
  error: undefined,
}

type UseCaseDetailType = {
  state: DocumentationState
  downloadDocu: (docId: string) => Promise<unknown>
}

export const useCaseDocumentation = (caseId: string): UseCaseDetailType => {
  const [state, setState] = useState<DocumentationState>(initialState)
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()

  const loadDocu = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const res = await docuService.getDocumentationCase(id)
        setState(() => ({
          loaded: true,
          docu: res,
          error: undefined,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          docu: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!caseId) {
      return
    }
    loadDocu(caseId)
  }, [loadDocu, caseId])

  const downloadDocu = async (docId: string) => {
    setState(prevState => ({
      ...prevState,
      submitDownload: docId,
    }))
    try {
      await docuService.downloadDocCase(caseId, docId)
      toastSuccess(t('documentation.download-success'))
    } catch (error) {
      toastError(t('documentation.download-error'))
    } finally {
      setState(prevState => ({
        ...prevState,
        submitDownload: undefined,
      }))
    }
  }

  return {
    state,
    downloadDocu,
  }
}
