import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import {
  Case,
  CasesPaginationQuery,
  CreateCaseRequest,
  HistoryItem,
  HistoryPaginationQuery,
  UpdateCaseRequest,
} from './types'
import { PaginatedResult, ResponseData } from '@app/core/types'
import { mapFiltersToRequest } from '@app/utils'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'

const getBaseUrl = (config: IConfigService) => `${config.baseUrl}/product-cases`
const INCLUDED_ENTITIES = 'clinic,billingAddress'

const getCases =
  (config: IConfigService, http: IHttpClient) =>
  async (query: CasesPaginationQuery): Promise<PaginatedResult<Case>> => {
    const params = mapFiltersToRequest(query)

    try {
      return await http.get(
        `${getBaseUrl(config)}?include=${INCLUDED_ENTITIES}&${params}`,
      )
    } catch (e) {
      throw errorFactory(e)
    }
  }

const getCase =
  (config: IConfigService, http: IHttpClient) =>
  async (id: string): Promise<Case> => {
    try {
      const { data } = await http.get<ResponseData<Case>>(
        `${getBaseUrl(config)}/${id}`,
      )

      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const updateCase =
  (config: IConfigService, http: IHttpClient) =>
  async (request: UpdateCaseRequest): Promise<void> => {
    return await http.put(`${getBaseUrl(config)}/${request.id}`, request)
  }

const createCase =
  (config: IConfigService, http: IHttpClient) =>
  async (request: CreateCaseRequest): Promise<string> => {
    const { data } = await http.post<
      CreateCaseRequest,
      ResponseData<{ id: string }>
    >(`${getBaseUrl(config)}/`, request)

    return data.id
  }

const getCaseHistory =
  (config: IConfigService, http: IHttpClient) =>
  async (
    id: string,
    query: HistoryPaginationQuery,
  ): Promise<PaginatedResult<HistoryItem>> => {
    const params = mapFiltersToRequest(query)
    try {
      return await http.get<PaginatedResult<HistoryItem>>(
        `${getBaseUrl(config)}/${id}/status?${params}`,
      )
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getCases: getCases(config, http),
  getCase: getCase(config, http),
  createCase: createCase(config, http),
  updateCase: updateCase(config, http),
  getCaseHistory: getCaseHistory(config, http),
})

export default factory(config, http)
