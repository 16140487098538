import { PaginationFiltersQuery } from '@app/core/types'
import { Place } from '../places/types'

export interface OpeningHours {
  days: DaysOfWeek[]
  startTimeMorning?: string
  endTimeMorning?: string
  startTimeEvening?: string
  endTimeEvening?: string
}

export enum DaysOfWeek {
  monday = 0,
  tuesday = 1,
  wednesday = 2,
  thursday = 3,
  friday = 4,
  saturday = 5,
  sunday = 6,
}

export type DaysOfWeekKey = keyof DaysOfWeek

export interface ClinicBase {
  id: string
  name: string
  address: string
  email: string
  phone: string
  logo: string
  addressExtra?: string
  addressNumber: string
  postalCode: string
  city: Place & {
    province: Place & {
      country: Place
    }
  }
}

export type Clinic = ClinicBase & {
  openingHours: OpeningHours
}

export type ClinicsQueryFilter = {
  withoutLogo: boolean
}

export type ClinicsPaginationQuery = PaginationFiltersQuery<
  Partial<ClinicsQueryFilter>
>

export type ClinicRequest = {
  name: string
  address: string
  email: string
  phone: string
  addressNumber: string
  addressExtra?: string
  postalCode: string
  cityId: string
  logo?: string
  openingHours: OpeningHours
}

export type CreateClinicRequest = ClinicRequest

export type UpdateClinicRequest = ClinicRequest & {
  id: string
}
