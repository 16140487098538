import { Chart } from 'react-charts'
import { useTranslation } from 'react-i18next'
import { useBarchart } from '@app/hooks'
import { Aggregates } from '@app/services/invoices/types'

import './BarChart.scss'
import { Card } from '@app/components'
import { formatPrice } from '@app/utils/numbers'
interface BarChartProps {
  aggregates: Aggregates
}
export const BarChart = ({ aggregates }: BarChartProps) => {
  const { t } = useTranslation()
  const { chartData, primaryAxis, secondaryAxes, totalValue } =
    useBarchart(aggregates)

  return (
    <div className="BarChart">
      <Card>
        <Card.Body>
          <Card.Title>
            {t('invoices.title')}
            <Card.Badge>{formatPrice(totalValue)} </Card.Badge>
          </Card.Title>
          <div className="BarChart-content">
            <Chart
              options={{
                data: chartData,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
