import { FC, PropsWithChildren } from 'react'
import Collapse from 'react-bootstrap/esm/Collapse'

type FilterPanelProps = PropsWithChildren & {
  open: boolean
  id: string
}

export const FilterPanel: FC<FilterPanelProps> = ({ children, open, id }) => {
  return (
    <Collapse in={open}>
      <div id={id}>{children}</div>
    </Collapse>
  )
}
