import {
  Card,
  EmptyList,
  Main,
  PageHeader,
  StateHandler,
} from '@app/components'
import { useTranslation } from 'react-i18next'
import { BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useBillingAddress } from '@app/hooks'
import { Placeholder } from 'react-bootstrap'
import { Pagination } from '@app/components/pagination/Pagination'
import { FC } from 'react'

import './BillingAddressPage.scss'

export const BillingAddressPage = () => {
  const { t } = useTranslation()
  const {
    state: {
      loaded,
      error,
      billingAddressList,
      lastPage,
      page,
      perPage,
      total,
    },
    setPage,
  } = useBillingAddress()

  return (
    <Main ariaLabelledby="billing-address-page" className="BillingAddressPage">
      <PageHeader id="billing-address-page">
        {t('billing-address.title')}
      </PageHeader>
      <div className="d-flex justify-content-end mb-3">
        <Link to={'./new'} className="btn btn-primary">
          <BsPlus />
          {t('billing-address.new')}
        </Link>
      </div>
      <div className="BillingAddressPage-cards">
        <StateHandler
          loaded={loaded}
          error={error}
          fallback={<BillingAddressCardsPlaceholder cardsNum={perPage} />}
        >
          {!billingAddressList?.length ? (
            <EmptyList title="empty-list.title" />
          ) : (
            billingAddressList.map(item => (
              <Card
                className="BillingAddressPage-card"
                to={`${item.id}`}
                key={`${item.id}`}
              >
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="BillingAddressPage-body">
                      <Card.Title className="mb-0 d-block text-truncate">
                        {item.name}
                      </Card.Title>
                      <p>{item.address}</p>
                      <div>{item.vatNumber}</div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))
          )}
        </StateHandler>
      </div>
      <Pagination
        loading={!loaded}
        currentPage={page}
        totalPages={lastPage}
        totalElements={total || 0}
        onChange={pageIndex => setPage(pageIndex)}
      />
    </Main>
  )
}

const BillingAddressCardsPlaceholder: FC<{ cardsNum: number }> = ({
  cardsNum,
}) => {
  const cards = new Array(cardsNum).fill(undefined).map((_, k) => k)

  return (
    <>
      {cards.map(card => (
        <Card className="BillingAddressPage-card" key={card}>
          <Card.Body>
            <div className="BillingAddressPage-body">
              <Placeholder as={'div'} animation="glow">
                <Placeholder xs={5} size="lg" />
                <div className="mt-3 mb-2">
                  <Placeholder xs={10} size="xs" />
                  <Placeholder xs={5} size="xs" />{' '}
                  <Placeholder xs={3} size="xs" />
                  <Placeholder xs={4} size="xs" />
                </div>
              </Placeholder>
            </div>
          </Card.Body>
        </Card>
      ))}
    </>
  )
}
