import clsx from 'clsx'
import { HTMLProps } from 'react'
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'

export type FormProps<T extends FieldValues> = {
  onValid?: SubmitHandler<T>
} & HTMLProps<HTMLFormElement>

export const Form = <T extends FieldValues>({
  children,
  onValid,
  ...props
}: FormProps<T>) => {
  const methods = useFormContext<T>()

  if (!methods) {
    throw new Error('You forgot to wrap your component in a <FormProvider>')
  }

  const { handleSubmit } = methods

  return (
    <form
      noValidate={true}
      autoComplete="off"
      className={clsx('Form')}
      onSubmit={onValid ? handleSubmit(onValid) : undefined}
      {...props}
    >
      {children}
    </form>
  )
}
