import { useTranslation } from 'react-i18next'
import { Main, PageHeader } from '@app/components'
import { PendingCases } from './pending-cases/PendingCases'
import { CasesWithMessage } from './cases-message/CasesWithMessage'
import { SearchCases } from './search-cases/SearchCases'
import { AlertClinics } from './alert-clinics/AlertClinics'

export const DashboardPage = () => {
  const { t } = useTranslation()

  return (
    <Main ariaLabelledby="dashboard-page" className="DashboardPage">
      <PageHeader id="dashboard-page">{t('dashboard.title')}</PageHeader>

      <div className="row row-gap-3">
        <div className="col-md-6">
          <SearchCases />
        </div>
        <div className="col-md-6">
          <AlertClinics />
        </div>

        <div className="col-12">
          <CasesWithMessage />
        </div>

        <div className="col-12">
          <PendingCases />
        </div>
      </div>
    </Main>
  )
}
