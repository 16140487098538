import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { PostPass } from './types'
import { IHttpClient } from '@app/core/http'
import { ResponseData } from '@app/core/types'
import { DetailUser } from '../auth/types'

const getBaseUrl = (config: IConfigService) => `${config.baseUrl}/profile`

const changePassword =
  (config: IConfigService, http: IHttpClient) =>
  async (params: PostPass): Promise<void> => {
    return await http.patch(`${getBaseUrl(config)}`, params)
  }

const changeLocale =
  (config: IConfigService, http: IHttpClient) =>
  async (lang: string): Promise<ResponseData<DetailUser>> => {
    return await http.patch(`${getBaseUrl(config)}`, { locale: lang })
  }

const uploadImageProfile =
  (config: IConfigService, http: IHttpClient) =>
  async (file: FormData): Promise<ResponseData<DetailUser>> => {
    return await http.post(`${getBaseUrl(config)}/avatar`, file)
  }

const getUserProfile =
  (config: IConfigService, http: IHttpClient) =>
  async (): Promise<ResponseData<DetailUser>> => {
    return await http.get(`${getBaseUrl(config)}`)
  }

const factory = (config: IConfigService, http: IHttpClient) => {
  return {
    changePassword: changePassword(config, http),
    changeLocale: changeLocale(config, http),
    uploadImageProfile: uploadImageProfile(config, http),
    getUserProfile: getUserProfile(config, http),
  }
}

export default factory(config, http)
