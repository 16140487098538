import { useEffect, useState } from 'react'

import throttle from 'lodash.throttle'

export type ViewportType = {
  innerWidth: number
  innerHeight: number
}

export function useViewport(): ViewportType {
  const [viewport, setViewport] = useState<ViewportType>({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  })

  useEffect(() => {
    const handleWindowResize = throttle(() => {
      const { innerHeight, innerWidth } = window

      setViewport({ innerWidth, innerHeight })
    }, 350)

    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return viewport
}
