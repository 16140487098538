import { useRef, useState } from 'react'
import { Crop } from 'react-image-crop'

export const useCropImage = () => {
  const [crop, setCrop] = useState<Crop>()
  const imgRef = useRef<HTMLImageElement | null>(null)
  const onLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    imgRef.current = e.currentTarget
  }

  const getCroppedImg = async (): Promise<Blob | null> => {
    if (!imgRef.current || !crop?.width || !crop?.height) {
      return null
    }

    const canvas = document.createElement('canvas')
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return null
    }

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
          return
        }
        resolve(blob)
      }, 'image/jpeg')
    })
  }
  const configCrop = {
    aspect: 1,
    minWidth: 250,
    minHeight: 250,
    maxWidth: 500,
    maxHeight: 500,
  }
  return {
    crop,
    setCrop,
    onLoad,
    getCroppedImg,
    configCrop,
  }
}
