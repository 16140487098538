import { FC } from 'react'
import './DetailItem.scss'

type DetailItemProps = {
  title: string
  description: string
}

export const DetailItem: FC<DetailItemProps> = ({ title, description }) => {
  return (
    <div className="col-12 col-md-6 Detail-cell col-md-12 fw-normal">
      <span className="Detail-label-bold">{title}</span>
      <div className="Detail-value mb-3">
        {description
          ?.split('\r\n')
          .map((line, idx) => <div key={idx}>{line}</div>)}
      </div>
    </div>
  )
}
