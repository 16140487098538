import { useProfile } from '@app/hooks'
import { useTranslation } from 'react-i18next'
import {
  Card,
  Detail,
  Main,
  UploadImage,
  PageHeader,
  StateHandler,
  ChangePass,
} from '@app/components'
import { FC } from 'react'
import placeHolder from '@images/placeholder-upload.png'
import { useUtilsProfile } from '@app/hooks/profile/useUtilsProfile'
import { CiLock } from 'react-icons/ci'
import { Button } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { showChangePassAction, showUploadImageAction } from '@app/store/slices'
import { DetailUser } from '@app/services/auth/types'

export const ProfilePage = () => {
  const { isLoaded, userDetail } = useProfile()

  return (
    <Main ariaLabelledby="profile-page" className="ProfilePage">
      <StateHandler loaded={isLoaded}>
        <ProfileCard profile={userDetail!} />
      </StateHandler>
    </Main>
  )
}

export const ProfileCard: FC<{ profile: DetailUser }> = ({ profile }) => {
  const { t } = useTranslation()
  const { getGenderString, getRangeAge, getSpeciality } = useUtilsProfile()
  const dispatch = useAppDispatch()
  const { showUploadImage, showChangePass } = useAppSelector(state => state.ui)

  return (
    <>
      <PageHeader id="profile-page">{t('profile.title')}</PageHeader>
      <Card>
        <Card.Body size={'sm'}>
          <Detail>
            <Detail.Side>
              <Detail.Section>
                <Button
                  variant="link"
                  onClick={() => dispatch(showUploadImageAction(true))}
                >
                  <Detail.Image
                    src={profile.avatar ? profile.avatar : placeHolder}
                    circle={true}
                  />
                </Button>
              </Detail.Section>
            </Detail.Side>
            <Detail.Side>
              <Detail.Section>
                <Detail.SubTitle>{t('profile.personal')}</Detail.SubTitle>
                <Detail.Cell
                  label={t('profile.name')}
                  value={profile.firstName}
                />
                <Detail.Cell
                  label={t('profile.surname')}
                  value={profile.lastNames}
                />
                <Detail.Cell
                  label={t('profile.phone')}
                  value={profile.phone || ''}
                />
                <Detail.Cell label={t('profile.email')} value={profile.email} />
                <Detail.Cell
                  label={t('profile.age')}
                  value={profile.ageRange ? getRangeAge(profile.ageRange) : ''}
                />
                <Detail.Cell
                  label={t('profile.gender')}
                  value={getGenderString(profile.gender)}
                />
                <Detail.Cell
                  label={t('profile.collegiate')}
                  value={profile.collegiateNumber}
                />
                <Detail.Cell
                  label={t('profile.specialty')}
                  value={
                    profile.speciality ? getSpeciality(profile.speciality) : ''
                  }
                />
              </Detail.Section>
              <Detail.Section>
                <Detail.SubTitle>{t('profile.clinic')}</Detail.SubTitle>
                <Detail.Cell
                  label={t('profile.address')}
                  value={profile.clinicAddress}
                />
                <Detail.Cell
                  label={t('profile.postal')}
                  value={profile.clinicPostalCode || ''}
                />
                <Detail.Cell
                  label={t('profile.country')}
                  value={profile.province?.country?.name || ''}
                />
                <Detail.Cell
                  label={t('profile.province')}
                  value={profile.province?.name || ''}
                />
                <Detail.Cell
                  label={t('profile.cif')}
                  value={profile.clinicVat || ''}
                />
                <Detail.Cell
                  label={t('profile.credit')}
                  value={profile.credit}
                />
              </Detail.Section>
            </Detail.Side>
          </Detail>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="primary"
            onClick={() => dispatch(showChangePassAction(true))}
          >
            <CiLock />
            {t('common.change-pass')}
          </Button>
        </Card.Footer>
      </Card>
      {showUploadImage && <UploadImage />}
      {showChangePass && <ChangePass />}
    </>
  )
}
