import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'

const getBaseUrl = (config: IConfigService, caseId: string) =>
  `${config.baseUrl}/product-cases/${caseId}/next-phase`

const nextPhase =
  (config: IConfigService, http: IHttpClient) =>
  async (productCaseId: string): Promise<void> => {
    try {
      await http.post<object, void>(`${getBaseUrl(config, productCaseId)}`)
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  nextPhase: nextPhase(config, http),
})

export default factory(config, http)
