import { useAppSelector, useAsyncDispatch } from '@app/store/hooks'
import { useToast } from '../toast/useToast'
import { CountriesState, fetchCountries } from '@app/store/slices'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import placesService from '@app/services/places/places.service'
import { State } from '@app/core/types'
import { Place } from '@app/services/places/types'

type ProvincesState = State<Place[], 'provinces'>
type CitiesState = State<Place[], 'cities'>

interface UsePlacesType {
  countriesState: CountriesState
  provincesState: ProvincesState
  citiesState: CitiesState
  getProvinces: (countryCode: string) => Promise<void>
  getCities: (provinceCode: string) => Promise<void>
}

export const usePlaces = (
  countryCode?: string,
  provinceCode?: string,
): UsePlacesType => {
  const { toastError } = useToast()
  const asyncDispatch = useAsyncDispatch()
  const { t } = useTranslation()

  const countriesState = useAppSelector(state => state.countries)
  const [provincesState, setProvincesState] = useState<ProvincesState>({
    loaded: false,
    provinces: null,
  })
  const [citiesState, setCitiesState] = useState<CitiesState>({
    loaded: false,
    cities: null,
  })

  const getProvinces = useCallback(
    async (country: string) => {
      try {
        const provinces = await placesService.getProvinces(country)
        setProvincesState(() => ({
          loaded: true,
          provinces,
        }))
      } catch (error) {
        setProvincesState(prevState => ({
          ...prevState,
          loaded: true,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  const getCities = useCallback(
    async (province: string) => {
      try {
        const cities = await placesService.getCities(province)
        setCitiesState(() => ({
          loaded: true,
          cities,
        }))
      } catch (error) {
        setCitiesState(prevState => ({
          ...prevState,
          loaded: true,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (countriesState.countries?.length === 0) {
      asyncDispatch(fetchCountries()).catch(() => {
        toastError(t('errors.load-data'))
      })
    }
  }, [asyncDispatch, countriesState.countries?.length, t, toastError])

  useEffect(() => {
    if (countryCode) {
      getProvinces(countryCode)
    }
  }, [countryCode, getProvinces])

  useEffect(() => {
    if (provinceCode) {
      getCities(provinceCode)
    }
  }, [provinceCode, getCities])

  return {
    countriesState,
    provincesState,
    citiesState,
    getProvinces,
    getCities,
  }
}
