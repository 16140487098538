import { PaginationFiltersQuery, PaginationQuery } from '@app/core/types'
import { Clinic } from '../clinics/types'
import { BillingAddress } from '../billing-address/types'
import { CaseFile } from '../files/types'

export enum CaseStatus {
  Creating = 'creating',
  Requested = 'requested',
  PickUp = 'pickup',
  Pending = 'pending',
  Planning = 'planning',
  Accepted = 'accepted',
  Fabricating = 'fabricating',
  Cancelled = 'cancelled',
  Sent = 'sent',
  Done = 'done',
}

export enum CaseType {
  CareFree = 'carefree',
  Tailor = 'tailor_made_for_you',
  PreProsthetic = 'preprosthetic_treatment',
  SecretRetainer = 'secretretainer',
}

export enum DateFilterType {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  AcceptedAt = 'acceptedAt',
}

export enum CasePhaseType {
  First = 'first_phase',
  Second = 'second_phase',
  Refinement = 'refinement_phase',
}

export enum CaseArcadeType {
  Both = 'both',
  Upper = 'upper',
  Lower = 'lower',
}

export enum CaseCutType {
  AtNeckline = 'trim_at_neckline',
  LeavingOfGum = 'trim_leaving_of_gum',
}

export enum CaseTargetType {
  EstheticOcclusion = 'esthetic_occlusion',
  Esthetic = 'esthetic',
  Occlusion = 'occlusion',
}

export enum GenderType {
  Male = 'male',
  Female = 'female',
}

export enum CasePrintingType {
  Silicone = 'silicone',
  Polyester = 'polyether',
  Scanner = 'intraoral_scanner',
}

export type CasesTeethNotMove = {
  number: string
}

export type CasePatientProblem = {
  problemId: string
  problem: string
  solutionId?: string
  solution?: string
  otherSolution?: string
}

export type CaseSecretRetainerType = {
  requestOnFinish: boolean
  archs?: CaseArcadeType
}

export type CaseGeneralInfo = {
  patient: {
    name: string
    firstName: string
    lastName: string
    gender: GenderType
    birthdate: string
    archTreat: CaseArcadeType
    teethNotToMove: CasesTeethNotMove[]
  }
  clinic?: Partial<Clinic>
  billingAddress?: Partial<BillingAddress>
  comments: string
}

export type CaseSpecificInfoDetails = {
  cutType: CaseCutType
  secretRetainers: CaseSecretRetainerType
  useElastics?: boolean
  distalize?: boolean
  thirdMolarExtraction?: boolean
}

export type CaseSpecificInfo = {
  details: Partial<CaseSpecificInfoDetails>
  treatmentTarget: Partial<{
    target: CaseTargetType
    patientProblems: CasePatientProblem[]
  }>
  indications?: string
}

export interface CaseBase {
  id: string
  type: CaseType
  generalInfo: CaseGeneralInfo
  createdAt: Date
  updatedAt: Date
  status: CaseStatus
  phase: CasePhaseType
  files: CaseFile[]
}

export type Case = CaseBase & {
  specificInfo: CaseSpecificInfo
  nextPhaseAvailable: boolean
}

export interface UserInfo {
  id: number
  firstName: string
  lastNames: string
  avatar: string
}

export interface HistoryItem {
  id: number
  oldStatus: CaseStatus
  newStatus: CaseStatus
  createdAt: string
  user: UserInfo
}

export type CasesQueryFilter = {
  status: CaseStatus
  clinic: string
  billingAddress: string
  from: Date
  to: Date
  dateType: DateFilterType
  withUnreadMessages: boolean
}

export type CasesSortQuery =
  | 'name'
  | 'clinic'
  | 'status'
  | 'updatedAt'
  | 'createdAt'
  | 'billingAddress'

export type CasesPaginationQuery = PaginationFiltersQuery<
  Partial<CasesQueryFilter>,
  Partial<CasesSortQuery>
>

export type HistoryPaginationQuery = PaginationQuery

export type PatientProblemRequest = {
  problemId: number
  solutionId?: number
  otherSolution?: string
}

export type TeethNotToMoveRequest = {
  number: number
}

export type GeneralInfoRequest = {
  type: CaseType
  generalInfo: {
    patient: {
      firstName: string
      lastName: string
      gender: GenderType
      birthdate: string
      archTreat: CaseArcadeType
      teethNotToMove: TeethNotToMoveRequest[]
      comments: string
    }
    clinicId: number
    billingAddressId: number
  }
}

export type SpecificInfoRequest = {
  specificInfo: {
    details: CaseSpecificInfoDetails
    treatmentTarget: {
      target: CaseTargetType
      patientProblems: PatientProblemRequest[]
    }
    indications: string
  }
}

export type MeasuresRequest = {
  measurementMethod: CasePrintingType
  published: boolean
}
export type CreateCaseRequest = GeneralInfoRequest

export type UpdateCaseRequest = Partial<
  {
    id: string
  } & GeneralInfoRequest &
    SpecificInfoRequest &
    MeasuresRequest
>
