import { clsx } from 'clsx'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'

type DatepickerControlProps<T extends FieldValues> = {
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  className?: string
  onChange?: (v: string) => void
} & FormControlWrapperProps<T>

export const DatepickerControl = function <T extends FieldValues>({
  warning,
  name,
  required,
  hiddenLabel,
  id,
  label,
  slotLabel,
  placeholder,
  disabled,
  readonly,
  onChange,
}: DatepickerControlProps<T>): JSX.Element {
  const methods = useFormContext<T>()

  const { control } = methods
  const prefixedId = id ?? `form-${name}`

  return (
    <div className="DatepickerControl">
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormControlWrapper
            error={fieldState.error}
            warning={warning}
            name={field.name}
            slotLabel={slotLabel}
            hiddenLabel={hiddenLabel}
            label={label}
            id={prefixedId}
          >
            <input
              className={clsx('form-control', {
                'is-invalid': fieldState.error,
                'has-warning': warning,
              })}
              type={'date'}
              placeholder={placeholder}
              id={prefixedId}
              disabled={disabled}
              value={field.value}
              name={field.name}
              readOnly={readonly}
              required={required}
              onChange={e => {
                field.onChange(e.target.value)
                if (onChange) onChange(e.target.value)
              }}
              onBlur={field.onBlur}
            />
          </FormControlWrapper>
        )}
      />
    </div>
  )
}
