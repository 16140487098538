import { State } from '@app/core/types'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import { BillingAddressDetail } from '@app/services/billing-address/types'
import billingAddressService from '@app/services/billing-address/billing-address.service'

type BillingAddressState = State<BillingAddressDetail, 'billingAddress'>

const initialState: BillingAddressState = {
  loaded: false,
  billingAddress: null,
}

type UseBillingType = {
  state: BillingAddressState
}

export const useBillingAddressDetail = (
  billingAddressId: string,
): UseBillingType => {
  const [state, setState] = useState<BillingAddressState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()

  const loadBillingAddress = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const billingAddress = await billingAddressService.getBillingAddress(id)
        setState(() => ({
          loaded: true,
          billingAddress,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          clinic: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!billingAddressId) {
      return
    }
    loadBillingAddress(billingAddressId)
  }, [loadBillingAddress, billingAddressId])

  return {
    state,
  }
}
