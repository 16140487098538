import { Modify } from '@app/core/types'
import { sanitizeUrl } from '@app/utils/url-sanitizer'
import { FC, DetailedHTMLProps, AnchorHTMLAttributes } from 'react'

type ExternalLinkProps = DetailedHTMLProps<
  Modify<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    {
      href: string | undefined
    }
  >,
  HTMLAnchorElement
>
const prepareUrl = (href: string) => sanitizeUrl(href)

export const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  href,
  ...props
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={prepareUrl(href!)}
      {...props}
    >
      {children}
    </a>
  )
}
