import { Main, StateHandler } from '@app/components'
import { usePlaces } from '@app/hooks/places/usePlaces'
import { useToast } from '@app/hooks/toast/useToast'
import { ClinicForm } from '../shared/clinic-form/ClinicForm'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clinicsService from '@app/services/clinics/clinics.service'
import { ClinicRequest, CreateClinicRequest } from '@app/services/clinics/types'

export const ClinicNewPage = () => {
  const { toastError, toastSuccess } = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    countriesState: { countries, loaded, error },
    provincesState: { provinces },
    citiesState: { cities },
    getProvinces,
    getCities,
  } = usePlaces()

  const handleSubmit = useCallback(
    async (values: ClinicRequest) => {
      try {
        const request: CreateClinicRequest = {
          ...values,
        }

        const id = await clinicsService.createClinic(request)
        navigate(`/clinics/${id}`)
        toastSuccess(t('clinics.success.create'))
      } catch (error) {
        toastError(t('clinics.errors.create'))
      }
    },
    [navigate, t, toastError, toastSuccess],
  )

  return (
    <Main ariaLabelledby="clinic-new-page" className="ClinicNewPage">
      <StateHandler loaded={loaded} error={error}>
        <ClinicForm
          countries={countries!}
          provinces={provinces!}
          cities={cities!}
          getProvinces={getProvinces}
          getCities={getCities}
          onSubmit={handleSubmit}
        />
      </StateHandler>
    </Main>
  )
}
