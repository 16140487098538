import { State } from '@app/core/types'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '../toast/useToast'
import { useTranslation } from 'react-i18next'
import clinicsService from '@app/services/clinics/clinics.service'
import { Clinic } from '@app/services/clinics/types'

type ClinicState = State<Clinic, 'clinic'>

const initialState: ClinicState = {
  loaded: false,
  clinic: null,
}

type UseClinicType = {
  state: ClinicState
}

export const useClinicDetail = (clinicId: string): UseClinicType => {
  const [state, setState] = useState<ClinicState>(initialState)
  const { toastError } = useToast()
  const { t } = useTranslation()

  const loadClinic = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const clinic = await clinicsService.getClinic(id)
        setState(() => ({
          loaded: true,
          clinic,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          clinic: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!clinicId) {
      return
    }
    loadClinic(clinicId)
  }, [loadClinic, clinicId])

  return {
    state,
  }
}
