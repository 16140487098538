import { StateBase } from '@app/core/types'
import { useToast } from '@app/hooks'
import { CaseFileRequest } from '@app/services/files/types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import filesService from '@app/services/files/files.service'
import { useFile } from './useFile'

type FileState = StateBase

const initialState: FileState = {
  loaded: false,
  error: undefined,
}

type UseCaseFilesType = {
  state: FileState
  uploadFile: (request: CaseFileRequest) => Promise<void>
  deleteFile: (fileId: string) => Promise<void>
  fileToBase64: (file: File) => Promise<string>
}

export const useCaseFileDetail = (caseId: string): UseCaseFilesType => {
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()
  const [state, setState] = useState<FileState>(initialState)
  const { fileToBase64 } = useFile()

  const uploadFile = useCallback(
    async (request: CaseFileRequest) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        await filesService.uploadCaseFile(caseId, request)
        setState(() => ({
          loaded: true,
          error: undefined,
        }))
        toastSuccess(t('files.success.upload'))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          error: error as Error,
        }))
        toastError(t('files.errors.upload'))
      }
    },
    [caseId, t, toastError, toastSuccess],
  )

  const deleteFile = useCallback(
    async (fileId: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        await filesService.deleteCaseFile(caseId, fileId)
        setState(() => ({
          loaded: true,
          error: undefined,
        }))
        toastSuccess(t('files.success.delete'))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          error: error as Error,
        }))
        toastError(t('files.errors.delete'))
      }
    },
    [caseId, t, toastError, toastSuccess],
  )

  return {
    state,
    uploadFile,
    deleteFile,
    fileToBase64,
  }
}
