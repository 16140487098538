import { Card } from '@app/components'
import { Form, SelectControl } from '@app/components/forms'
import { FC, useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CasesFiltersFormFields } from './types'
import { coerceValues } from './utils'
import { useTranslation } from 'react-i18next'
import { cloneObjectDeep, mapEnumIntoIterableTypeValue } from '@app/utils'
import { CaseStatus, DateFilterType } from '@app/services/cases/types'
import { ClinicBase } from '@app/services/clinics/types'
import { BillingAddress } from '@app/services/billing-address/types'
import { CiTrash } from 'react-icons/ci'
import { RadioControl } from '@app/components/forms/radio-control/RadioControl'
import { DatepickerControl } from '@app/components/forms/datepicker-control/DatepickerControl'
import { yupResolver } from '@hookform/resolvers/yup'
import { buildValidationSchema } from './validation'
import { useFiltersContext } from '@app/components/filters/context'

import './CasesFiltersForm.scss'

const statusOptions = mapEnumIntoIterableTypeValue(
  CaseStatus,
  'cases.status-types',
)

const dateTypeOptions = mapEnumIntoIterableTypeValue(DateFilterType, 'cases')

type CasesFiltersFormProps = {
  clinics: ClinicBase[]
  billingAddress: BillingAddress[]
}

export const CasesFiltersForm: FC<CasesFiltersFormProps> = ({
  clinics,
  billingAddress,
}) => {
  const { t } = useTranslation()
  const { setFilters } = useFiltersContext()

  const methods = useForm<CasesFiltersFormFields>({
    mode: 'onChange',
    defaultValues: coerceValues(),
    resolver: yupResolver(buildValidationSchema(t)),
  })

  const { watch, reset, getValues, setValue, trigger, clearErrors } = methods

  const dateTypeWatch = watch('dateType')

  useEffect(() => {
    if (dateTypeWatch) {
      setValue(`${dateTypeWatch}.to`, '' as never)
      setValue(`${dateTypeWatch}.from`, '' as never)
    }
  }, [dateTypeWatch, setValue])

  const submitForm = useCallback(() => {
    const filtersRequest = cloneObjectDeep(getValues())
    delete (filtersRequest as Partial<CasesFiltersFormFields>).dateType
    setFilters(filtersRequest)
  }, [getValues, setFilters])

  return (
    <div className="CasesFiltersForm">
      <FormProvider {...methods}>
        <Form>
          <Card className="mb-3">
            <Card.Body className="pb-0">
              <div className="row">
                <div className="col-12 col-md-4">
                  <SelectControl
                    name="status"
                    label={t('cases.status.title')}
                    options={statusOptions?.map(d => ({
                      label: t(d.type),
                      value: d.value as unknown as string,
                    }))}
                    onChange={submitForm}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectControl
                    name="clinic"
                    label={t('cases.clinic')}
                    options={clinics?.map(d => ({
                      label: d.name,
                      value: d.id,
                    }))}
                    onChange={submitForm}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectControl
                    name="billingAddress"
                    label={t('cases.billing-address')}
                    options={billingAddress?.map(d => ({
                      label: d.name,
                      value: d.id,
                    }))}
                    onChange={submitForm}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4">
                  <RadioControl
                    className="CasesFiltersForm-dateType"
                    name="dateType"
                    label={t('cases.date-type')}
                    options={dateTypeOptions?.map(d => ({
                      label: t(d.type),
                      value: d.value as unknown as string,
                    }))}
                    onChange={() => {
                      const dateTypeValue = getValues('dateType')
                      if (
                        getValues(`${dateTypeValue}.from`) ||
                        getValues(`${dateTypeValue}.to`)
                      ) {
                        submitForm()
                      } else {
                        trigger(`${dateTypeValue}.from`)
                        trigger(`${dateTypeValue}.to`)
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <DatepickerControl<CasesFiltersFormFields>
                        disabled={!dateTypeWatch}
                        name={`${dateTypeWatch}.from`}
                        label={t('cases.from')}
                        onChange={async () => {
                          const fromValue = getValues(`${dateTypeWatch}.from`)
                          const toValue = getValues(`${dateTypeWatch}.to`)
                          clearErrors(`${dateTypeWatch}.to`)

                          if (fromValue && toValue) {
                            const isValid = await trigger(`${dateTypeWatch}.to`)
                            if (isValid) {
                              submitForm()
                            }
                          } else {
                            submitForm()
                          }
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <DatepickerControl<CasesFiltersFormFields>
                        disabled={!dateTypeWatch}
                        name={`${dateTypeWatch}.to`}
                        label={t('cases.to')}
                        onChange={async () => {
                          const fromValue = getValues(`${dateTypeWatch}.from`)
                          const toValue = getValues(`${dateTypeWatch}.to`)
                          clearErrors(`${dateTypeWatch}.from`)

                          if (fromValue && toValue) {
                            const isValid = await trigger(`${dateTypeWatch}.to`)
                            if (isValid) {
                              submitForm()
                            }
                          } else {
                            submitForm()
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="CasesFiltersForm-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  reset(coerceValues())
                  submitForm()
                }}
              >
                <CiTrash />
                {t('filters.clear')}
              </button>
            </Card.Footer>
          </Card>
        </Form>
      </FormProvider>
    </div>
  )
}
