import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RadioControl,
  SelectControl,
  TextareaControl,
} from '@app/components/forms'
import {
  CaseArcadeType,
  CaseCutType,
  CaseTargetType,
  CaseType,
} from '@app/services/cases/types'
import { Card } from '@app/components'
import { useFormContext } from 'react-hook-form'
import { Prescription } from '@app/services/prescription/types'
import { PrescriptionForm } from '@pages/cases/shared/form'
import { mapEnumIntoIterableTypeValue } from '@app/utils'

const cutTypeOptions = mapEnumIntoIterableTypeValue(
  CaseCutType,
  'cases.cut-type',
)

const treatmentTargetOptions = mapEnumIntoIterableTypeValue(
  CaseTargetType,
  'cases.treatment-target',
)

const arcadeTypeOptions = mapEnumIntoIterableTypeValue(
  CaseArcadeType,
  'cases.arcade',
)

type SpecificInfoFormProps = {
  caseType: CaseType
  problems?: Prescription[]
  solutions?: Prescription[]
}

export const SpecificInfoForm: FC<SpecificInfoFormProps> = ({
  caseType,
  problems = [],
  solutions = [],
}) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()

  const treatmentTargetWatch = watch('specific.target')
  const requestOnFinishWatch = watch('specific.secretRetainers.requestOnFinish')

  const showOcclusionFields = useMemo(
    () =>
      treatmentTargetWatch === CaseTargetType.EstheticOcclusion ||
      treatmentTargetWatch === CaseTargetType.Occlusion,
    [treatmentTargetWatch],
  )

  const showPrescriptionFields = useMemo(
    () => caseType === CaseType.Tailor,
    [caseType],
  )

  const booleanOptions = [
    {
      label: t('common.true'),
      value: true,
    },
    {
      label: t('common.false'),
      value: false,
    },
  ]

  return (
    <Card.Body
      className="SpecificInfo"
      size={showPrescriptionFields ? 'default' : 'sm'}
    >
      {showPrescriptionFields && (
        <PrescriptionForm problems={problems} solutions={solutions} />
      )}

      <div className="row">
        <div
          className={
            showPrescriptionFields ? 'col-12 col-xl-7 offset-xl-3' : 'col-12'
          }
        >
          <h3 className="form-subtitle">{t('cases.secret-retainer.title')}</h3>

          <div className="col-12">
            <RadioControl
              name="specific.secretRetainers.requestOnFinish"
              label={t('cases.secret-retainer.completion')}
              required
              options={booleanOptions}
            />
          </div>
          {requestOnFinishWatch && (
            <div className="col-12 mb-3">
              <SelectControl
                name="specific.secretRetainers.archs"
                label={t('cases.arcade.title')}
                options={arcadeTypeOptions?.map(d => ({
                  label: t(d.type),
                  value: d.value as unknown as string,
                }))}
              />
            </div>
          )}

          <h3 className="form-subtitle">{t('cases.treatment-data')}</h3>

          <div className="col-12">
            <RadioControl
              name="specific.cutType"
              label={t('cases.cut-type.title')}
              required
              options={cutTypeOptions?.map(d => ({
                label: t(d.type),
                value: d.value as unknown as string,
              }))}
            />
          </div>

          <div className="col-12">
            <SelectControl
              name="specific.target"
              label={t('cases.treatment-target.title')}
              options={treatmentTargetOptions?.map(d => ({
                label: t(d.type),
                value: d.value as unknown as string,
              }))}
              required
            />
          </div>
          {showOcclusionFields && (
            <>
              <div className="col-12">
                <SelectControl
                  name="specific.useElastics"
                  label={t('cases.use-elastics')}
                  options={booleanOptions}
                  required
                />
              </div>

              <div className="col-12">
                <SelectControl
                  name="specific.distalize"
                  label={t('cases.distalize')}
                  options={booleanOptions}
                  required
                />
              </div>

              <div className="col-12">
                <SelectControl
                  name="specific.thirdMolarExtraction"
                  label={t('cases.third-molar-extraction')}
                  options={booleanOptions}
                  required
                />
              </div>
            </>
          )}

          <div className="col-12">
            <TextareaControl
              rows={5}
              name="specific.indications"
              label={t('cases.indications')}
            />
          </div>
        </div>
      </div>
    </Card.Body>
  )
}
