import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const ButtonPhone = () => {
  const { t } = useTranslation()
  const formattedPhoneNumber = useMemo(() => {
    const param = t('menu.phone')
    return param.replace(/\s|\(|\)/g, '')
  }, [t])

  return (
    <a
      className="btn btn-primary btn-block w-100 btn-primary-opacity"
      href={`tel:${formattedPhoneNumber}`}
      target="_blank"
    >
      {t('menu.phone')}
    </a>
  )
}
