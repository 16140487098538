import { Card, EmptyList, StateHandler } from '@app/components'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Case, CasesPaginationQuery } from '@app/services/cases/types'
import Image from 'react-bootstrap/esm/Image'
import placeholderPicture from '@images/placeholder-image.jpg'
import { formatDate } from '@app/utils/dates'
import { Carousel } from '@app/components/carousel/Carousel'
import { responsiveConfig } from './utils'
import Placeholder from 'react-bootstrap/esm/Placeholder'
import { useViewport } from '@app/hooks/viewport/useViewport'
import { useCases } from '@app/hooks/cases/useCases'
import { getFrontalFilePhoto } from '@app/utils/cases'
import { INIT_QUERY_PAGE } from '@app/utils'

import './CasesWithMessage.scss'

interface CasesWithMessageType {
  className?: string
}

const customQuery: CasesPaginationQuery = {
  page: INIT_QUERY_PAGE,
  perPage: 50,
  filter: { withUnreadMessages: true },
}

export const CasesWithMessage: FC<CasesWithMessageType> = ({ className }) => {
  const { t } = useTranslation()
  const {
    state: { cases, loaded, error, total },
  } = useCases(customQuery)

  return (
    <Card className={clsx(className, 'CasesWithMessage')}>
      <Card.Body>
        <Card.Title>
          {t('dashboard.cases-message.title')}
          <Card.Badge className={clsx({ invisible: !loaded })}>
            {total}
          </Card.Badge>
        </Card.Title>

        <StateHandler
          loaded={loaded}
          error={error}
          fallback={<CasesSliderPlaceholder />}
        >
          <CasesSlider cases={cases!} />
        </StateHandler>
      </Card.Body>
    </Card>
  )
}

interface CasesSliderProps {
  cases: Case[]
}
const CasesSlider: FC<CasesSliderProps> = ({ cases }) => {
  const { t } = useTranslation()

  if (!cases?.length) {
    return <EmptyList title="empty-list.title" />
  }

  return (
    <Carousel
      className="CasesSlider"
      responsive={responsiveConfig}
      centerMode={false}
      partialVisible
      focusOnSelect={false}
    >
      {cases?.map(item => (
        <Card
          key={item.id}
          to={`/patients/${item.id}?tab=assessments`}
          className="CasesSlider-item"
        >
          <Card.Body className="p-3">
            <div>
              <Image
                width={40}
                height={40}
                src={getFrontalFilePhoto(item.files)}
                roundedCircle
                className="object-fit-cover"
              ></Image>
            </div>
            <div className="CasesSlider-text">
              <Card.Title className="text-truncate d-block mb-0 lh-base">
                {item.generalInfo.patient.name}
              </Card.Title>
              <div className="text-muted">
                <small>{t('cases.updatedat')}</small>
                <div>{formatDate(item.updatedAt)}</div>
              </div>
            </div>
          </Card.Body>
        </Card>
      ))}
    </Carousel>
  )
}

const CasesSliderPlaceholder: FC = () => {
  const { innerWidth } = useViewport()

  const numCardsDevice = useMemo(() => {
    const currentDevice = Object.values(responsiveConfig).find(
      device =>
        innerWidth >= device.breakpoint.min &&
        innerWidth <= device.breakpoint.max,
    )

    return currentDevice?.items ?? responsiveConfig.mobile.items
  }, [innerWidth])

  const cards = new Array(numCardsDevice).fill(undefined).map((_, k) => k)

  return (
    <div className="d-flex">
      {cards.map(card => (
        <Card className="CasesSlider-item flex-fill" key={card}>
          <Card.Body className="p-3">
            <div>
              <Image
                width={40}
                height={40}
                src={placeholderPicture}
                roundedCircle
              ></Image>
            </div>
            <div className="CasesSlider-text">
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder as={'div'} animation="glow" className="">
                <Placeholder xs={7} /> <Placeholder xs={4} />{' '}
                <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
              </Placeholder>
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  )
}
