import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

const clientId = import.meta.env.VITE_OAUTH2_CLIENT_ID
const redirectUri = import.meta.env.VITE_OAUTH2_REDIRECT_URI
const authority = import.meta.env.VITE_OAUTH2_PROVIDER_URL
const tokenEndpoint = import.meta.env.VITE_OAUTH2_TOKEN_URL
const userInfoEndpoint = import.meta.env.VITE_OAUTH2_PROFILE
const redirectUriLogout = import.meta.env.VITE_OAUTH2_REDIRECT_URI_LOGOUT

const userManagerConfig = {
  authority,
  client_id: clientId,
  redirect_uri: redirectUri,
  response_type: 'code',
  scope: '*',
  post_logout_redirect_uri: redirectUriLogout,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraTokenParams: {
    client_secret: import.meta.env.VITE_OAUTH2_CLIENT_SECRET,
  },
  metadata: {
    authorization_endpoint: `${authority}`,
    token_endpoint: `${tokenEndpoint}`,
    userinfo_endpoint: userInfoEndpoint,
    end_session_endpoint: `${authority}/logout`,
  },
}

const userManager = new UserManager(userManagerConfig)

export default userManager
