import classnames from 'classnames'
import { FC, ReactNode } from 'react'
import { Modal, ModalProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export type ConfirmModalProps = {
  children?: ReactNode
  title: string
  onConfirmationAction: () => void
  onCancelAction?: () => void
  isVisible: boolean
  singleConfirmation?: boolean
  confirmActionText?: string
  cancelActionText?: string
  allowToClose?: boolean
  size?: ModalProps['size']
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  children,
  isVisible = false,
  singleConfirmation = false,
  onCancelAction,
  onConfirmationAction,
  cancelActionText,
  confirmActionText,
  allowToClose = false,
  size = 'lg',
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      show={isVisible}
      onHide={onCancelAction}
      size={size}
      backdrop={allowToClose ? true : 'static'}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {!singleConfirmation && (
          <button
            type="button"
            onClick={onCancelAction}
            className="btn btn-link"
          >
            {cancelActionText ?? t('common.cancel')}
          </button>
        )}
        <button
          className={classnames('btn btn-primary', {
            'mx-5': singleConfirmation,
          })}
          onClick={onConfirmationAction}
        >
          {confirmActionText ?? t('common.accept')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
