import {
  Card,
  EmptyList,
  Main,
  PageHeader,
  StateHandler,
  Tooltip,
} from '@app/components'
import { useClinics } from '@app/hooks/clinics/useClinics'
import { Image, Placeholder } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Pagination } from '@app/components/pagination/Pagination'
import { FC } from 'react'
import placeholderImage from '@images/placeholder-image.jpg'
import { Link } from 'react-router-dom'
import { BsPlus } from 'react-icons/bs'

import './ClinicsPage.scss'

export const ClinicsPage = () => {
  const { t } = useTranslation()
  const {
    state: { loaded, error, clinics, lastPage, page, perPage, total },
    setPage,
  } = useClinics()

  return (
    <Main ariaLabelledby="clinics-page" className="ClinicsPage">
      <PageHeader id="clinics-page">{t('clinics.title')}</PageHeader>

      <div className="d-flex justify-content-end mb-3">
        <Link to={'./new'} className="btn btn-primary">
          <BsPlus />
          {t('clinics.new')}
        </Link>
      </div>
      <div className="ClinicsPage-cards">
        <StateHandler
          loaded={loaded}
          error={error}
          fallback={<ClinicCardsPlaceholder cardsNum={perPage} />}
        >
          {!clinics?.length ? (
            <EmptyList title="empty-list.title" />
          ) : (
            clinics?.map(clinic => (
              <Card
                className="ClinicsPage-card"
                to={`./${clinic.id}`}
                key={clinic.id}
              >
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="position-relative">
                      {!clinic.logo && (
                        <Tooltip
                          title={t('clinics.alert-logo')}
                          placement="top"
                        >
                          <span className="position-absolute translate-middle-x start-100 p-1 bg-danger rounded-circle">
                            <span className="visually-hidden">
                              ${t('clinics.alert-logo')}
                            </span>
                          </span>
                        </Tooltip>
                      )}
                      <Image
                        className="ClinicsPage-logo"
                        src={clinic.logo ? clinic.logo : placeholderImage}
                        thumbnail
                        roundedCircle
                      />
                    </div>
                    <div className="ClinicsPage-body">
                      <Card.Title className="mb-0 d-block text-truncate">
                        {clinic.name}
                      </Card.Title>
                      <p>{clinic.address}</p>
                      <div>{clinic.phone}</div>
                      <div>{clinic.email}</div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))
          )}
        </StateHandler>
      </div>
      <Pagination
        loading={!loaded}
        currentPage={page}
        totalPages={lastPage}
        totalElements={total || 0}
        onChange={pageIndex => setPage(pageIndex)}
      />
    </Main>
  )
}

const ClinicCardsPlaceholder: FC<{ cardsNum: number }> = ({ cardsNum }) => {
  const cards = new Array(cardsNum).fill(undefined).map((_, k) => k)

  return (
    <>
      {cards.map(card => (
        <Card className="ClinicsPage-card" key={card}>
          <Card.Body>
            <div className="d-flex gap-3">
              <div>
                <Image
                  className="ClinicsPage-logo"
                  src={placeholderImage}
                  thumbnail
                  roundedCircle
                />
              </div>
              <div className="ClinicsPage-body">
                <Placeholder as={'div'} animation="glow">
                  <Placeholder xs={5} size="lg" />
                  <div className="mt-3 mb-2">
                    <Placeholder xs={10} size="xs" />
                    <Placeholder xs={5} size="xs" />{' '}
                    <Placeholder xs={3} size="xs" />
                    <Placeholder xs={4} size="xs" />
                  </div>
                </Placeholder>
              </div>
            </div>
          </Card.Body>
        </Card>
      ))}
    </>
  )
}
