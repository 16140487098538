import { Main, StateHandler } from '@app/components'
import { useClinicDetail } from '@app/hooks/clinics/useClinicDetail'
import { usePlaces } from '@app/hooks/places/usePlaces'

import { useNavigate, useParams } from 'react-router-dom'
import { ClinicForm } from '../shared/clinic-form/ClinicForm'
import { useCallback, useMemo } from 'react'
import { ClinicRequest, UpdateClinicRequest } from '@app/services/clinics/types'
import { useToast } from '@app/hooks'
import clinicsService from '@app/services/clinics/clinics.service'
import { useTranslation } from 'react-i18next'

export const ClinicEditPage = () => {
  const { clinicId } = useParams()
  const { toastError, toastSuccess } = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    state: { loaded: loadedClinic, error, clinic },
  } = useClinicDetail(clinicId!)

  const {
    countriesState: { countries, loaded: loadedCountries },
    provincesState: { provinces, loaded: loadedProvinces },
    citiesState: { cities, loaded: loadedCities },
    getProvinces,
    getCities,
  } = usePlaces(clinic?.city.province.country.id, clinic?.city.province.id)

  const loaded = useMemo(
    () => loadedClinic && loadedCountries && loadedProvinces && loadedCities,
    [loadedClinic, loadedCountries, loadedProvinces, loadedCities],
  )

  const handleSubmit = useCallback(
    async (values: ClinicRequest) => {
      const request: UpdateClinicRequest = {
        ...values,
        id: clinicId!,
      }
      try {
        await clinicsService.updateClinic(request)
        navigate(`../${clinicId}`)
        toastSuccess(t('clinics.success.update'))
      } catch (error) {
        toastError(t('clinics.errors.update'))
      }
    },
    [clinicId, navigate, t, toastError, toastSuccess],
  )

  return (
    <Main ariaLabelledby="clinic-edit-page" className="ClinicEditPage">
      <StateHandler loaded={loaded} error={error}>
        <ClinicForm
          clinic={clinic!}
          countries={countries!}
          provinces={provinces!}
          cities={cities!}
          getProvinces={getProvinces}
          getCities={getCities}
          onSubmit={handleSubmit}
        />
      </StateHandler>
    </Main>
  )
}
