import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { showUploadImageAction } from '@app/store/slices'
import Modal from 'react-bootstrap/esm/Modal'
import Button from 'react-bootstrap/esm/Button'
import { useDropzone } from 'react-dropzone'
import Image from 'react-bootstrap/esm/Image'
import clsx from 'clsx'
import { CiCircleRemove, CiFloppyDisk, CiSaveUp2 } from 'react-icons/ci'
import { useTranslation } from 'react-i18next'
import ReactCrop from 'react-image-crop'
import { useUploadImage } from '@app/hooks/profile/useUploadImage'
import { ButtonAsync } from '@app/components'
import { useCropImage } from '@app/hooks/profile/useCropImage'

import './UploadImage.scss'

export const UploadImage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { showUploadImage } = useAppSelector(state => state.ui)
  const { withFiles, files, filesAccept, sendFile, onDrop, sending } =
    useUploadImage()
  const { crop, configCrop, setCrop, onLoad, getCroppedImg } = useCropImage()
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: filesAccept,
    noClick: !!crop,
  })
  const handleCropData = async () => {
    const originalFile = files[0]
    const croppedBlob = await getCroppedImg()
    if (croppedBlob) {
      const formData = new FormData()
      formData.append('avatar', croppedBlob, originalFile.name)
      sendFile(formData)
    }
  }

  return (
    <Modal
      show={showUploadImage}
      onHide={() => dispatch(showUploadImageAction(false))}
      className="UploadImage modal-lg"
    >
      <Modal.Header>
        <Modal.Title>{t('profile.image.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          {...getRootProps()}
          className={clsx('UploadImage-dropzone', {
            inactive: withFiles,
          })}
        >
          <input {...getInputProps()} />
          {files.length === 0 ? (
            <>
              <span>
                <CiSaveUp2 />
              </span>
              <h3 className="h5">{t('profile.image.drag')}</h3>
              <p>{t('profile.image.size')}</p>
            </>
          ) : (
            <>
              {files.map(file => (
                <div key={file.preview}>
                  <div>
                    <ReactCrop
                      crop={crop}
                      onChange={c => setCrop(c)}
                      {...configCrop}
                    >
                      <Image
                        src={file.preview}
                        className="UploadImage-image"
                        onLoad={onLoad}
                      />
                    </ReactCrop>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          onClick={() => dispatch(showUploadImageAction(false))}
        >
          <CiCircleRemove />
          {t('common.cancel')}
        </Button>
        <ButtonAsync
          className="btn btn-primary"
          onClick={handleCropData}
          disabled={files.length === 0 || !crop}
          isSubmitting={sending}
        >
          <CiFloppyDisk />
          {t('common.save')}
        </ButtonAsync>
      </Modal.Footer>
    </Modal>
  )
}
