import { clsx } from 'clsx'
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'
import { useRef, useState } from 'react'
import { CiTrash } from 'react-icons/ci'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import './UploadControlFile.scss'

type UploadControlBaseProps<T> = {
  disabled?: boolean
  readonly?: boolean
  className?: string
  onChange?: (name: Path<T>, file?: File) => Promise<void>
}
type FileUploadControlProps<T extends FieldValues> = UploadControlBaseProps<T> &
  FormControlWrapperProps<T>

export const UploadControlFile = function <T extends FieldValues>({
  warning,
  name,
  required,
  hiddenLabel,
  id,
  label,
  slotLabel,
  disabled,
  readonly,
  onChange,
}: FileUploadControlProps<T>): JSX.Element {
  const [fileName, setFileName] = useState<string>('')
  const { t } = useTranslation()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { control, setValue } = useFormContext<T>()

  const prefixedId = id ?? `form-${name}`

  const handleChangeFile = () => {
    if (!inputFileRef.current) {
      return
    }
    const fileList: FileList = inputFileRef.current.files!
    const files = Array.from(fileList)
    const selectedFile = files[0]

    if (selectedFile) {
      setFileName(selectedFile.name)
      setValue(name, selectedFile as PathValue<T, Path<T>>)
      onChange && onChange(name, selectedFile)
    }
  }

  const handleClickUpload = () => {
    inputFileRef?.current?.click()
  }

  const handleRemoveFile = () => {
    setFileName('')
    setValue(name, '' as PathValue<T, Path<T>>)
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
    }
  }

  return (
    <div className="UploadControlFile">
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormControlWrapper
            error={fieldState.error}
            warning={warning}
            name={field.name}
            slotLabel={slotLabel}
            hiddenLabel={hiddenLabel}
            label={label}
            id={prefixedId}
          >
            <Button
              variant="secondary"
              onClick={handleClickUpload}
              disabled={disabled || readonly}
            >
              {t('common.upload-file')}
            </Button>
            <>
              <input
                className={clsx('form-control d-none', {
                  'is-invalid': fieldState.error,
                  'has-warning': warning,
                })}
                ref={inputFileRef}
                type={'file'}
                id={prefixedId}
                disabled={disabled}
                name={field.name}
                readOnly={readonly}
                required={required}
                onChange={handleChangeFile}
                onBlur={field.onBlur}
              />
              <div className="UploadControlFile-info border-bottom">
                <span className="UploadControlFile-text text-truncate">
                  {!fileName ? (
                    <span className="text-gray">{t('common.no-file')}</span>
                  ) : (
                    fileName
                  )}
                </span>
                {fileName && (
                  <Button
                    variant="link"
                    className="btn-sm"
                    onClick={handleRemoveFile}
                    disabled={disabled || readonly}
                  >
                    <CiTrash />
                  </Button>
                )}
              </div>
            </>
          </FormControlWrapper>
        )}
      />
    </div>
  )
}
