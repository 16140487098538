import { useAuth } from '@app/hooks'
import { MainLayout } from '@app/layouts'
import { useAppSelector } from '@app/store/hooks'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Spinner from '../components/spinner/Spinner'

export const PrivateRoutes: React.FC = () => {
  const { token, user } = useAppSelector(state => state.user)
  const { isLoggingIn, isSessionChecked } = useAppSelector(
    state => state.authLocalStorage,
  )
  const { checkSession } = useAuth()

  useEffect(() => {
    const handleAuth = async () => {
      if (isLoggingIn) {
        return
      }

      if (!isSessionChecked) {
        await checkSession()
      }
    }

    handleAuth()
  }, [checkSession, user, token, isSessionChecked, isLoggingIn])

  if (!isSessionChecked || isLoggingIn) {
    return <Spinner />
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  )
}

export default PrivateRoutes
