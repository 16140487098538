import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface caseState {
  reloadCase: boolean
}

const initialState: caseState = {
  reloadCase: false,
}

export const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    reloadCaseAction: (state, action: PayloadAction<boolean>) => {
      state.reloadCase = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { reloadCaseAction } = caseSlice.actions

export default caseSlice.reducer
