import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from '../locales'
import { LanguagesApp } from './types'

type LanguageTranslations = (typeof translations)[LanguagesApp]

const resources: Record<LanguagesApp, LanguageTranslations> = {
  [LanguagesApp.EN]: translations.en,
  [LanguagesApp.ES]: translations.es,
  [LanguagesApp.PT]: translations.pt,
  [LanguagesApp.FR]: translations.fr,
  [LanguagesApp.IT]: translations.it,
  [LanguagesApp.DE]: translations.de,
}

i18n.use(initReactI18next).init({
  fallbackLng: LanguagesApp.ES,
  resources,
  interpolation: {
    escapeValue: false,
  },
})
