import {
  PlanAcceptance,
  TreatmentPlan,
} from '@app/services/treatment-plans/types'

export const setTreatmentStatusToAccepted = (treatmentPlan: TreatmentPlan) => {
  const acceptedStatus = {
    acceptance: PlanAcceptance.Accepted,
    permissions: {
      acceptance: false,
    },
  }

  return {
    ...treatmentPlan,
    ...acceptedStatus,
  }
}
