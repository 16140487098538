import config, { IConfigService } from '../config/config.service'
import http from '@app/core/http/http-client'
import { IHttpClient } from '@app/core/http'
import { errorFactory } from '@app/core/api-errors/error-factory'
import { DocumentationBase } from './types'
import { ResponseData } from '@app/core/types'

const getBaseUrl = (config: IConfigService, caseId: string) =>
  `${config.baseUrl}/product-cases/${caseId}/documents`

const getDocumentationCase =
  (config: IConfigService, http: IHttpClient) =>
  async (productCaseId: string): Promise<DocumentationBase[]> => {
    try {
      const { data } = await http.get<ResponseData<DocumentationBase[]>>(
        `${getBaseUrl(config, productCaseId)}`,
      )
      return data
    } catch (e) {
      throw errorFactory(e)
    }
  }

const downloadDocCase =
  (config: IConfigService, http: IHttpClient) =>
  async (productCaseId: string, docId: string): Promise<void> => {
    try {
      const url = `${getBaseUrl(config, productCaseId)}/${docId}`
      await http.download(url, {
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
    } catch (e) {
      throw errorFactory(e)
    }
  }

const factory = (config: IConfigService, http: IHttpClient) => ({
  getDocumentationCase: getDocumentationCase(config, http),
  downloadDocCase: downloadDocCase(config, http),
})

export default factory(config, http)
