import { FC } from 'react'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import TooltipBootstrap, { TooltipProps } from 'react-bootstrap/esm/Tooltip'
import { Placement } from 'react-bootstrap/esm/types'

type TooltipCustomProps = {
  title: string
  children: React.ReactElement
  placement?: Placement
  id?: string
}

export const Tooltip: FC<TooltipCustomProps> = ({
  id = '',
  children,
  title,
  placement = 'left',
}) => {
  const renderTooltip = (props: TooltipProps) => (
    <TooltipBootstrap id={id} {...props}>
      {title}
    </TooltipBootstrap>
  )

  return (
    <OverlayTrigger placement={placement} overlay={renderTooltip}>
      {({ ref, ...triggerHandler }) => (
        <div ref={ref} {...triggerHandler}>
          {children}
        </div>
      )}
    </OverlayTrigger>
  )
}
