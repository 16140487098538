import { FC, PropsWithChildren, ReactNode } from 'react'

import './AlertMessage.scss'
import clsx from 'clsx'

export type AlertMessageProps = {
  title?: string
  actions?: ReactNode
  className?: string
} & PropsWithChildren

export const AlertMessage: FC<AlertMessageProps> = ({
  title,
  children,
  actions,
  className,
}) => {
  return (
    <div className={clsx('Alert Alert-message', className)} role="alert">
      {title && <h2 className="Alert-heading">{title}</h2>}

      <div className="Alert-content">
        <div className="Alert-body">{children}</div>
        <div className="Alert-actions">{actions}</div>
      </div>
    </div>
  )
}
