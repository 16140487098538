import { Case } from '@app/services/cases/types'
import { enumKeys, getFileById } from '@app/utils'
import { FilesFormFields, FilesPhotoFormFields } from '../types'
import { PhotoIdentifiers } from '@app/services/files/types'

export function filesCoerceValues(caseData: Case): FilesFormFields {
  const photographsKeys = enumKeys(PhotoIdentifiers)

  const photographs = photographsKeys.reduce((acc, key) => {
    const fileById = getFileById(caseData.files, PhotoIdentifiers[key])
    acc[PhotoIdentifiers[key] as PhotoIdentifiers] = {
      identifier: fileById?.identifier,
      originalName: fileById?.fileName ?? '',
      file: fileById?.url ?? '',
    }

    return acc
  }, {} as FilesPhotoFormFields)

  return {
    files: {
      photographs,
    },
  }
}
