import { object, string, AnyObjectSchema, mixed } from 'yup'
import { CaseStatus, DateFilterType } from '@app/services/cases/types'
import { oneOfEnum } from '@app/utils/forms'
import { TFunction } from 'i18next'
import { validateFromToDate } from '@app/utils'

export function buildValidationSchema(t: TFunction): AnyObjectSchema {
  const PeriodSchema = object()
    .shape({
      from: mixed().nullable(),
      to: mixed()
        .nullable()
        .test(
          'invalidDate',
          () =>
            t('validation.greater-than', {
              other: t('cases.from'),
            }),
          (toDate, global) => {
            return validateFromToDate(global.parent.from, toDate as string)
          },
        ),
    })
    .atLeastOneOf(['from', 'to'], t('cases.filters.validation-date'))

  return object().shape({
    status: oneOfEnum(CaseStatus).nullable(),
    clinic: string().optional().nullable(),
    billingAddress: string().optional().nullable(),
    dateType: oneOfEnum(DateFilterType).nullable(),
    createdAt: PeriodSchema.nullable(),
    updatedAt: PeriodSchema.nullable(),
    acceptedAt: PeriodSchema.nullable(),
  })
}
