import { State } from '@app/core/types'
import { useToast } from '@app/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import refinementsService from '@app/services/refinements/refinements.service'
import { RefinementsBase } from '@app/services/refinements/types'

type RefinementsState = State<RefinementsBase[], 'refinements'> & {
  error?: Error
}

const initialState: RefinementsState = {
  loaded: false,
  refinements: null,
  error: undefined,
}

type UseRefinementsType = {
  state: RefinementsState
  handleSend: (params: FormData) => void
}

export const useRefinements = (caseId: string): UseRefinementsType => {
  const [state, setState] = useState<RefinementsState>(initialState)
  const { toastError, toastSuccess } = useToast()
  const { t } = useTranslation()

  const loadListRefinements = useCallback(
    async (id: string) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))

      try {
        const refinementsData = await refinementsService.getListRefinements(id)
        setState(() => ({
          loaded: true,
          refinements: refinementsData,
          error: undefined,
        }))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          refinements: null,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [t, toastError],
  )

  useEffect(() => {
    if (!caseId) {
      return
    }
    loadListRefinements(caseId)
  }, [loadListRefinements, caseId])

  const handleSend = useCallback(
    async (params: FormData) => {
      setState(prevState => ({
        ...prevState,
        loaded: false,
      }))
      try {
        await refinementsService.sendRefinements(params, caseId)
        setState(() => ({
          loaded: true,
          error: undefined,
        }))
        toastSuccess(t('cases.actions.success-refine'))
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          error: error as Error,
        }))
        toastError(t('errors.load-data'))
      }
    },
    [caseId, t, toastError, toastSuccess],
  )

  return {
    state,
    handleSend,
  }
}
