import { clsx } from 'clsx'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import {
  FormControlWrapper,
  FormControlWrapperProps,
} from '../form-control-wrapper/FormControlWrapper'

type CheckControlProps<T extends FieldValues> = {
  readOnlyValue?: string
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  className?: string
} & FormControlWrapperProps<T>

export const CheckControl = function <T extends FieldValues>({
  warning,
  name,
  required,
  hiddenLabel,
  id,
  label,
  slotLabel,
  placeholder,
  disabled,
  readonly,
}: CheckControlProps<T>): JSX.Element {
  const methods = useFormContext<T>()

  const { control } = methods
  const prefixedId = id ?? `form-${name}`

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControlWrapper
          error={fieldState.error}
          warning={warning}
          name={field.name}
          slotLabel={slotLabel}
          hiddenLabel={true}
          label={label}
          id={prefixedId}
        >
          <div className="form-check">
            <input
              className={clsx('form-check-input', {
                'is-invalid': fieldState.error,
                'has-warning': warning,
              })}
              type="checkbox"
              placeholder={placeholder}
              id={prefixedId}
              disabled={disabled}
              checked={field.value ?? false}
              name={field.name}
              readOnly={readonly}
              required={required}
              onChange={() => field.onChange(!field.value)}
              onBlur={field.onBlur}
            />
            <label
              htmlFor={prefixedId}
              className={clsx('form-check-label', {
                'visually-hidden': hiddenLabel,
              })}
            >
              {label}
            </label>
          </div>
        </FormControlWrapper>
      )}
    />
  )
}
