import { StateHandler } from '@app/components'
import { useRefinements } from '@app/hooks/refinements/useRefinements'
import { RefinementsBase } from '@app/services/refinements/types'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AlertMessage } from '@app/components/alerts'

export const RequestedWidget: FC<{ className?: string }> = ({ className }) => {
  const { caseId } = useParams()

  const {
    state: { loaded, error, refinements },
  } = useRefinements(caseId!)
  return (
    <StateHandler loaded={loaded} error={error}>
      <RefinementsContent className={className} refinements={refinements!} />
    </StateHandler>
  )
}

type RefinementsContentProps = {
  refinements: RefinementsBase[]
  className?: string
}
const RefinementsContent: FC<RefinementsContentProps> = ({
  refinements,
  className,
}) => {
  const { t } = useTranslation()

  if (!refinements.length) {
    return
  }

  const lastRefinement = refinements[refinements.length - 1]
  return (
    <AlertMessage title={t('cases.refinements.title')} className={className}>
      <div key={lastRefinement.id}>
        <div>
          <Trans
            i18nKey={t('cases.refinements.reason')}
            values={{ text: lastRefinement.reason }}
            components={[<strong className="fw-semibold" key="" />]}
          />
        </div>
        <div>
          <Trans
            i18nKey={t('cases.refinements.details')}
            values={{ text: lastRefinement.details }}
            components={[<strong className="fw-semibold" key="" />]}
          />
        </div>
      </div>
    </AlertMessage>
  )
}
